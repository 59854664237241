import { configureStore } from '@reduxjs/toolkit'
// import counterReducer from './features/counter/counterSlice'
// import playingReducer from './components/VideoPlayer/playingSlice'
import authReducer from './features/loginSlice'
import chatReducer from './features/chatSlice'

export default configureStore({
  reducer: {
    // videoPlaying: playingReducer
    auth: authReducer,
    chatReducer,
  }
})