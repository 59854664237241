import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonMenu, IonMenuToggle, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { close, closeOutline } from 'ionicons/icons';
import { useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router';
import { notifiche } from '../../data/data';
import NotificationItem from './NotificationItem';
import './Notifications.css';

const Notifications: React.FC = () => {
  return (
    <IonPage >
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            {/* <IonMenuToggle> */}
            <IonBackButton text='' className='__back-button' />
            {/* </IonMenuToggle> */}
          </IonButtons>
          <IonTitle>Notifiche</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {notifiche.map(el => (
          <NotificationItem notifica={el} key={el.id} />
        ))}
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
