import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Section from "../../components/Section/Section";
import FavoriteShopsSection from "../../components/sections/FavoriteShopsSection";
import ShopsSection from "../../components/sections/ShopsSection";
import ShopsCarousel from "../../components/ShopsCarousel/ShopsCarousel";
import { useCart } from "../../hooks/useCart";
import { scrollToTop } from "../../utils/scrollToTop";
import './Negozi.css'
import { motion } from 'framer-motion'
import PageFadeIn from "../../components/common/Animation/PageFadeIn";
import Container from "../../components/common/Container";
import { useLocation, useHistory } from "react-router";

const Negozi: React.FC<any> = ({ modal, content }) => {
  const { getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)
  const vel = .15
  const alt = 100
  const [style, setStyle] = useState({
    y: alt,
    opacity: 0
  })
  useIonViewWillEnter(() => {
    setNumeroArticoli(getProducts().numeroArticoli)
    setStyle({
      y: 0,
      opacity: 1
    })
  })
  const locationHook = useLocation()
  const history = useHistory()
  const [backBtn, setBackBtn] = useState(false)
  const [checked, setChecked] = useState(false)
  useIonViewDidLeave(() => {
    setChecked(false)
    setBackBtn(false)
  })
  useEffect(() => {
    if (locationHook.search && !checked) {
      const queryParams = new URLSearchParams(locationHook.search)
      if (queryParams.has('back')) {
        setChecked(true)
        setBackBtn(true)
        queryParams.delete('back')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }

  }, [locationHook.pathname])
  useEffect(() => {
    setNumeroArticoli(getProducts().numeroArticoli)
  }, [locationHook])
  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          {backBtn &&
            <IonButtons slot='start'>
              <IonBackButton text='' className='__back-button' />
            </IonButtons>
          }
          <IonTitle>Negozi</IonTitle>
          <IonButtons slot='end' className="__notifications-badge">
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="__shops-page" ref={content}>
        <PageFadeIn>
          <Container>
            <div className="__py-1">
              <motion.div className="__shops-page-favourites"
                initial={{ y: alt, opacity: 0 }}
                transition={{ duration: vel }}
                animate={style}
              >
                <h4>I tuoi negozi preferiti</h4>
                <FavoriteShopsSection />
              </motion.div>
              <motion.div className="__shops-page-brands"
                initial={{ y: alt, opacity: 0 }}
                transition={{ duration: vel, delay: 0.05 }}
                animate={style}
              >
                <h4>Marchi in evidenza</h4>
                <ShopsCarousel />
              </motion.div>
              <motion.div
                initial={{ y: alt, opacity: 0 }}
                transition={{ duration: vel, delay: 0.08 }}
                animate={style}>
                <Section title="Nelle vicinanze">
                  <ShopsSection />
                </Section>
              </motion.div>
            </div>
          </Container>
        </PageFadeIn>
      </IonContent>
    </IonPage>
  );
}

export default Negozi;