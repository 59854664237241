import { IonAvatar, IonItem, IonLabel, IonList, IonText } from "@ionic/react";
import { negozi } from "../../data/data";
import { formatCost } from "../../utils/formatCost";
import Image from "../Image/Image";
import './ShopsList.css'

const ShopsList: React.FC<any> = ({ idProdotto, costo, limit }) => {
  const compare = (a: any, b: any) => {
    return a.sconto > b.sconto ? -1 : a.sconto < b.sconto ? 1 : 0
  }
  // @ts-ignore
  let arr = [], negoziOfferta = []
  negozi.forEach(shop => {
    shop.sconti.forEach(el => {
      if (el.idProdotto == idProdotto) {
        arr.push({
          ...shop,
          sconto: el.sconto
        })
      }
    })
  })
  // @ts-ignore
  // negoziOfferta.sort(compare)
  // @ts-ignore
  negoziOfferta = arr.sort(compare)

  if (limit) {
    // @ts-ignore
    negoziOfferta = arr.slice(0, 3)
  }
  return (
    <IonList className="__single-product-shops">
      <div className="__shops-list-title">
        <h3>Nelle vicinanze</h3>
      </div>
      {/* @ts-ignore */}
      {negoziOfferta.map(({ denominazioneCommerciale, id, sconto, avatar, indirizzo, citta }, i) => (
        <IonItem key={id} className={'__single-product-shop ' + (i == 0 ? '__best-offer' : '__normal-offer')} lines={(i == negoziOfferta.length - 1) ? 'none' : 'inset'} routerLink={`/tabs/prodotto/${idProdotto}/${id}`} detail={false}>
          <IonAvatar slot='start' className="__avatar">
            <Image src={avatar} />
          </IonAvatar>
          <IonLabel>
            <h3>{denominazioneCommerciale}</h3>
            <p>{indirizzo} - {citta}</p>
          </IonLabel>
          <IonText>€ {formatCost(costo, sconto)}</IonText>
        </IonItem>
      ))}
    </IonList>
  );
}

export default ShopsList;