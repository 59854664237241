import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRouterLink, IonText, IonThumbnail, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import CartItem from "../../components/Cart/CartItem";
import { useCart } from "../../hooks/useCart";
import { formatCost } from "../../utils/formatCost";
import './Carrello.css'

const Carrello: React.FC<any> = ({ modal }) => {
  const location = useLocation()
  const nav = useHistory()

  const { getProducts } = useCart()
  const [prodottiCarrello, setProdottiCarrello] = useState(getProducts().prodotti)
  const [total, setTotal] = useState(getProducts().costo)

  const closeModal = () => {
    modal.current.dismiss()
  }
  const closeModalButton = () => {
    closeModal()
    nav.go(-1)
  }
  
  let opened = false
  const updateCart = () => {
    if (!opened && !location.search.includes('modalOpened=true')) {
      opened = true
      nav.push(nav.location.pathname + '?modalOpened=true')
    }
    setTotal(getProducts().costo)
    setProdottiCarrello(getProducts().prodotti)
  }
  useEffect(() => {
    if (!location.search.includes('modalOpened=true')) {
      closeModal()
    }
  }, [location]);

  return (
    <IonModal ref={modal} trigger="open-cart" canDismiss onWillPresent={updateCart} initialBreakpoint={1} className='__carrello' >
      <IonHeader mode="ios">
        <IonToolbar className="__toolbar" mode='ios'>
          <IonButtons>
            <IonButton size='large' onClick={closeModalButton}>
              <IonIcon slot='icon-only' icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Lista della spesa</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {prodottiCarrello.length > 0 ?
          <div className="__pt-1">
            {/* @ts-ignore */}
            {prodottiCarrello.map(({ negozio, prodotti }) => (
              <IonList key={negozio.id}>
                <IonRouterLink routerLink={'/tabs/negozio/' + negozio.id} onClick={closeModal}>
                  <h3 className="__cart-list-title">
                    <IonText>
                      {negozio.denominazioneCommerciale}
                    </IonText>
                  </h3>
                </IonRouterLink>
                {/* @ts-ignore: Unreachable code error */}
                {prodotti.map((prodotto, i) => (
                  <CartItem key={i} prodotto={prodotto} negozio={negozio.id} updateCart={updateCart} closeModal={closeModal} />
                ))}
              </IonList>
            ))}
            <div className="__costo-carrello">
              <h5 className="__total">Totale: <strong>€ {total.totale.toFixed(2)}</strong></h5>
              <h5 className="__extotal">Invece di: <strong>€ {total.exTotale.toFixed(2)}</strong></h5>
              <h5 className="__total-discount">Stai risparmiando: <strong>€ {total.risparmio.toFixed(2)}</strong></h5>
              <h5 className="__total-points">Stai accumulando <strong>{total.punti} punti</strong></h5>
            </div>
          </div> :
          <div className="__no-data">
            <p>La tua lista della spesa è vuota.</p>
          </div>
        }
      </IonContent>
    </IonModal>
  );
}

export default Carrello;
