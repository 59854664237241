import { negozi, prodotti as tuttiProdotti } from "../data/data"

export const getProductsFromBrand = (idBrand: number) => {
  const products: any[] = []
  const negoziBrand = negozi.filter(el => el.idMarchio == idBrand)
  negoziBrand.forEach(shop => {
    shop.sconti.forEach(item => {
      if (!products.find(prod => prod.id == item.idProdotto)) {
        products.push({
          ...tuttiProdotti.find(el => el.id == item.idProdotto),
          sconto: item.sconto,
          idNegozio: shop.id
        })
      }
    })
  })
  return products
}