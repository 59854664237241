import { IonAccordion, IonAccordionGroup, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonRouterLink, IonText, useIonActionSheet } from "@ionic/react";
import type { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { arrowRedoOutline, bookmarkOutline, chatboxOutline, checkmarkCircleOutline, ellipsisVertical, heart, heartOutline, shareOutline, thumbsUp, thumbsUpOutline } from "ionicons/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { creators, marchi, negozi, prodotti as tuttiProdotti } from "../../data/data";
import Image from "../Image/Image";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import './SocialPost.css'

const SocialPost: React.FC<any> = ({ post, page }) => {
  const { utente, data, media, commenti, testo } = post
  const [like, setLike] = useState<boolean>(post.like)
  const [likes, setLikes] = useState<number>(post.likes)
  const miPiace = () => {
    if (like) {
      setLikes(likes => likes - 1)
    } else {
      setLikes(likes => likes + 1)
    }
    setLike(like => !like)
  }
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();
  const prodotti = tuttiProdotti.filter(el => post.prodotti?.includes(el.id))
  let negozio, brand, url, creator, img, nome, indirizzo
  if (utente.tipoUtente == 'negozio') {
    negozio = negozi.find(el => el.id == utente.idNegozio)
    url = '/tabs/negozio/' + negozio?.id
    img = negozio?.avatar
    nome = negozio?.denominazioneCommerciale
    indirizzo = negozio?.indirizzo
  }
  if (utente.tipoUtente == 'brand') {
    brand = marchi.find(el => el.id == utente.idBrand)
    url = '/tabs/brand/' + brand?.id
    img = brand?.avatar
    nome = brand?.nome
  }
  if (utente.tipoUtente == 'creator') {
    creator = creators.find(el => el.id == utente.idCreator)
    url = '/tabs/creator/' + creator?.id
    img = creator?.avatar
    nome = creator?.nome
    indirizzo = creator?.tipologia
  }

  return (
    <div className="__card __post-social __box-shadow-1">
      <div className="__post-settings">
        <IonButton fill="clear"
          onClick={() =>
            present({
              header: 'Impostazioni post',
              // subHeader: 'Example subheader',
              buttons: [
                {
                  text: 'Condividi',
                  data: {
                    action: 'share',
                  },
                },
                {
                  text: 'Salva',
                  data: {
                    action: 'save',
                  },
                },
                {
                  text: 'Nascondi',
                  data: {
                    action: 'hide',
                  },
                },
                {
                  text: 'Segnala',
                  role: 'destructive',
                  data: {
                    action: 'delete',
                  },
                },
                {
                  text: 'Cancella',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ],
              onDidDismiss: ({ detail }) => setResult(detail),
            })
          }>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </div>
      <div className="__post-user ion-padding">
        <div className="__post-user-avatar">
          <IonRouterLink routerLink={url}>
            <Image src={img || utente.img} />
          </IonRouterLink>
        </div>
        <div className="__post-user-info">
          <div className="__post-user-name">
            <IonRouterLink routerLink={url} color='dark'>{nome || utente.nome}</IonRouterLink>
            {utente.tipoUtente == 'brand' &&
              <IonIcon icon={checkmarkCircleOutline} />
            }
          </div>
          <div className="__post-user-date">
            <IonText>{indirizzo || utente.indirizzo}</IonText>
          </div>
          <div className="__post-user-date">
            <IonText>{data}</IonText>
          </div>
        </div>
      </div>
      {testo &&
        <div className="__post-text" dangerouslySetInnerHTML={{ __html: testo }} />
      }
      <div className="__post-media">
        {media.images?.length > 0 &&
          <img src={media.images[0].img} alt="" />
        }
        {media.videos?.length > 0 &&
          <VideoPlayer videoObj={media.videos[0]} page={page} />
        }
      </div>
      <div className="__divider"></div>
      <div className="__post-actions">
        <div className="__post-data">
          <div className="__post-likes">
            <div className="__likes-avatars">
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/1.jpeg" alt="" />
              </div>
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/2.jpeg" alt="" />
              </div>
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/3.jpeg" alt="" />
              </div>
            </div>
            {/* <IonIcon className="__icon" icon={like ? thumbsUp : thumbsUpOutline} /> */}
            <IonText className="__text">{likes} Mi piace</IonText>
          </div>
          {/* <div className="__post-comments"> */}
          {/* <IonIcon className="__icon" icon={chatboxOutline} /> */}
          {/* <IonText>{commenti} Commenti</IonText> */}
          {/* </div> */}
        </div>

        <div>
          <IonButton fill="clear" onClick={miPiace} >
            <IonIcon class="__icon" icon={like ? heart : heartOutline} color={like ? 'danger' : ''} />
            {/* <IonLabel>Mi piace</IonLabel> */}
          </IonButton>
          <IonButton fill="clear" >
            <IonIcon class="__icon" icon={bookmarkOutline} />
            {/* <IonLabel>Salva</IonLabel> */}
          </IonButton>
          <IonButton fill="clear" >
            <IonIcon class="__icon" icon={shareOutline} />
            {/* <IonLabel>Condividi</IonLabel> */}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default SocialPost;