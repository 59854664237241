import { getPlatforms, IonIcon, IonLabel, IonRange, IonSpinner } from '@ionic/react';
import { volumeHigh, volumeMute, pause as pauseIcon, play as playIcon, reload, reloadOutline } from 'ionicons/icons';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazy-load'
import { useIntersection, useIntersectionContent } from '../../hooks/useIntersection';
import './VideoPlayer.css'

const VideoPlayer: React.FC<{ videoObj: { video: string, preview: string }, page: string }> = ({ videoObj, page }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const controlsRef = useRef<HTMLDivElement>(null)

  const [ready, setReady] = useState(false)
  const [muted, setMuted] = useState(true)
  const [hide, setHide] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [video, setVideo] = useState<{ src: string, duration: number | undefined, current: number | undefined, preview: string }>({
    src: videoObj.video,
    duration: 0,
    current: 0,
    preview: videoObj.preview || './assets/images/placeholders/video.png'
  })
  const [currentTimeInterval, setCurrentTimeInterval] = useState<any | null>(null)
  const [hideTimeout, setHideTimeout] = useState<any | null>(null)
  const [ended, setEnded] = useState(false)

  const muteUnmute = (e?: SyntheticEvent) => {
    e?.stopPropagation()
    setMuted(el => !el)
  }
  const playPause = () => {
    if (playing) {
      setPlaying(false)
      if (hideTimeout) {
        clearTimeout(hideTimeout)
      }
    } else {
      setPlaying(true)
      setHideTimeout(setTimeout(() => {
        setHide(true)
      }, 4000))
    }
  }
  const showHide = () => {
    if (getPlatforms().includes('mobileweb')) {
      if (hide) {
        setHide(false)
        if (playing) {
          setHideTimeout(setTimeout(() => {
            setHide(true)
          }, 4000))
        }
      } else {
        setHide(true)
        clearTimeout(hideTimeout)
      }
    }
  }
  const showControls = () => {
    if (!getPlatforms().includes('mobileweb') && hide) {
      setHide(false)
    }
  }
  const hideControls = () => {
    if (!getPlatforms().includes('mobileweb') && !hide) {
      setHide(true)
    }
  }

  const getVideoData = () => {
    const duration = videoRef.current?.duration || 0
    const currentTime = videoRef.current?.currentTime || 0
    const current = 100 / duration * currentTime
    return {
      duration, current
    }
  }
  const progressBarHandlerStart = (value: any) => {
    setPlaying(false)
    if (videoRef.current && value >= 0) {
      videoRef.current.currentTime = (video.duration || 1) / 100 * value
    }
  }
  const progressBarHandlerEnd = (value: any) => {
    if (videoRef.current && value >= 0) {
      if (value != 100) {
        videoRef.current.currentTime = (video.duration || 1) / 100 * value
      }
      if (value == 100) {
        // @ts-ignore
        videoRef.current.currentTime = video.duration - 0.4
      }
      setPlaying(true)
    }
  }
  const getTime = (s: any) => {
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s
  }

  const inContentViewport = useIntersectionContent(videoRef, page)
  const inViewport = useIntersection(videoRef)

  useEffect(() => {
    // console.log(videoRef.current?.readyState, video.src);

  }, [inViewport, videoRef.current?.readyState])

  useEffect(() => {
    // if (ready) {
    setVideo(el => ({
      ...el,
      ...getVideoData()
    }))
    // }
  }, [ready])
  useEffect(() => {

    // if (videoRef.current?.readyState == 4) {
    if (inContentViewport) {
      setPlaying(true)
      if (!hide) {
        setHide(true)
      }
    } else {
      setPlaying(false)
    }
    // }
  }, [inContentViewport])
  useEffect(() => {
    // @ts-ignore
    if (videoRef.current?.readyState == 4) {
      console.log(videoRef.current?.readyState);

      setReady(true)
    }
  }, [videoRef.current?.readyState])
  useEffect(() => {
    if (playing) {
      setEnded(false)
      setVideo(el => ({
        ...el,
        ...getVideoData()
      }))
      videoRef.current?.play().then(() => {
        // console.log('prova');
      }).catch(() => {
        console.log('videoPlayer');
      })
      setCurrentTimeInterval(setInterval(() => {
        // console.log(videoRef.current?.readyState);
        if (!videoRef.current) {
          clearInterval(localStorage['videoInterval'])
          setCurrentTimeInterval(null)
        }
        if (getVideoData().current == 100) {
          clearInterval(localStorage['videoInterval'])
          setCurrentTimeInterval(null)
          setPlaying(false)
          clearTimeout(hideTimeout)
          setEnded(true)
        }
        setVideo(el => ({
          ...el,
          ...getVideoData()
        }))
      }, 500))
    } else {
      setVideo(el => ({
        ...el,
        ...getVideoData()
      }))
      clearInterval(currentTimeInterval)
      setCurrentTimeInterval(null)
      videoRef.current?.pause()
    }
  }, [playing])
  useEffect(() => {
    localStorage['videoInterval'] = currentTimeInterval
  }, [currentTimeInterval])
  // const waitForElement = (sel: any, cb: any) => {
  //   const el = sel;
  //   if (!el || !(el.getBoundingClientRect().height > 0)) {
  //     requestAnimationFrame(() => waitForElement(sel, cb));
  //   } else {
  //     console.log(el.childNodes);

  //     return cb(el);
  //   }
  // }
  // // function createThumb(video: any, w: any, h: any) {
  // const createThumb = (element: any) => {
  //   console.log(element, video.src);

  //   return waitForElement(element, () => {
  //     const w = element.getBoundingClientRect().width
  //     console.log(w);

  //     const h = element.getBoundingClientRect().height
  //     var c = document.createElement("canvas"),    // create a canvas
  //       ctx = c.getContext("2d");                // get context
  //     c.width = w;                                 // set size = thumb
  //     c.height = h;
  //     ctx!.drawImage(element, 0, 0, w, h);           // draw in frame
  //     // console.log(c);

  //     return c.toDataURL();                                    // return canvas
  //   })
  // }
  // const [thumbnail, setThumbnail] = useState('')
  // useEffect(() => {
  //   // if (videoRef.current) {
  //   // console.log(videoRef.current.childNodes[0]);
  //   // waitForElement(videoRef.current, () => {
  //     videoRef.current?.addEventListener('loadedmetadata', () => {

  //       const w = videoRef.current!.getBoundingClientRect().width

  //       const h = videoRef.current!.getBoundingClientRect().height


  //       setTimeout(() => {
  //         // console.log(e.timeStamp);
  //         // if (e.timeStamp < 3000) {

  //           let c = document.createElement("canvas"),    // create a canvas
  //             ctx = c.getContext("2d");                // get context
  //           c.width = w;                                 // set size = thumb
  //           c.height = h;
  //           // @ts-ignore
  //           ctx.drawImage(videoRef.current, 0, 0, w, h);           // draw in frame
  //           // console.log(c);
  //           console.log(c.toDataURL());


  //           // c.toDataURL(); 
  //           setThumbnail(c.toDataURL());
  //         // }


  //       }, 100)
  //     })
  //   // })
  //   // console.log(createThumb(videoRef.current))
  //   // }

  // }, [])
  useEffect(() => {
    return () => {
      clearInterval(localStorage['videoInterval'])
      setCurrentTimeInterval(null)
    }
  }, [])
  return (
    // <LazyLoad onContentVisible={() => setReady(true)}>
    <div className='__video-player' onClick={showHide} onMouseEnter={showControls} onMouseMove={showControls} onMouseLeave={hideControls}>
      {!ended &&
        <div className="__volume-button" onClick={muteUnmute}>
          <IonIcon color="light" icon={muted ? volumeMute : volumeHigh} />
        </div>
      }
      {/* {!ready &&
        <div className='__loader' style={{ backgroundImage: videoObj.preview }}>
          <IonSpinner name='bubbles' color='light' />
        </div>} */}
      <video preload='true' src={video.src} poster={video.preview} ref={videoRef} muted={muted} autoPlay controls={false} playsInline />
      {/* <video poster={'./assets/images/placeholders/video.png'} ref={videoRef} muted={muted} controls={false} autoPlay={false} playsInline> */}
      {/* <video src={video.src} poster={thumbnail} ref={videoRef} muted={muted} controls={false} autoPlay={false} playsInline /> */}
      {/* <source onLoad={() => console.log('ready')} src={video.src} type="video/mp4" />
        Il tuo dispositivo non supporta la riproduzione di questo video.
      </video> */}
      {ended &&
        <div className='__ended-video'>
          <div className="__replay-button" onClick={playPause}>
            <IonIcon color='light' size='large' icon={reload} />
            <IonLabel color={'light'}>Riproduci di nuovo</IonLabel>
          </div>
        </div>
      }
      {!ended &&
        <div className={"__controls " + (hide ? '__hide' : '')} ref={controlsRef} onClick={e => e.stopPropagation()}>
          <div className="__play-button" onClick={playPause}>
            <IonIcon size="large" color="light" icon={ended ? reloadOutline : playing ? pauseIcon : playIcon} />
          </div>
          <div className="__duration">
            {/* @ts-ignore */}
            <IonLabel color={'light'} className='__duration-label'>{video.current ? getTime(((video.duration || 1) / 100 * video.current).toFixed(0)) : '0:00'}</IonLabel>
            <IonRange value={video.current} onIonKnobMoveStart={({ detail }) => progressBarHandlerStart(detail.value)} onIonKnobMoveEnd={({ detail }) => progressBarHandlerEnd(detail.value)}></IonRange>
            <IonLabel color={'light'} className='__duration-label'>{getTime(video.duration?.toFixed(0))}</IonLabel>
          </div>
        </div>
      }
    </div >
  );
  {/* </LazyLoad> */ }
}

export default VideoPlayer;
