import { useState, useEffect } from 'react'

const waitForElement = (sel: string, cb: any) => {
  const el = document.querySelector(sel);
  if (!el || !(el.getBoundingClientRect().height > 0)) {
    requestAnimationFrame(() => waitForElement(sel, cb));
  } else {
    cb(el);
  }
}

export const useIntersection = (element: any) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    let observer: any
    // waitForElement('#' + rootContainer, (el: any) => {
    //   if (el) {
        // const root = document.querySelector('#' + rootContainer)
        
        // let marginTop: number = (root!.getBoundingClientRect().height - 600) / 2
        // const rootMargin = - Math.abs(marginTop) + 'px 0px'
        
        observer = new IntersectionObserver(
          ([entry]) => {
            setState(entry.isIntersecting);
          }, {
            // rootMargin,
            // root,
            // threshold: 0.9
          }
        );
    
        element.current && observer.observe(element.current);
        
    //   }
    // })

    return () => element.current && observer && observer.unobserve(element.current);
  }, [element.current]);

  return isVisible;
};
export const useIntersectionContent = (element: any, rootContainer: any) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    let observer: any
    waitForElement('#' + rootContainer, (el: any) => {
      if (el) {
        const root = document.querySelector('#' + rootContainer)
        
        let marginTop: number = (root!.getBoundingClientRect().height - 600) / 2
        const rootMargin = - Math.abs(marginTop) + 'px 0px'
        
        observer = new IntersectionObserver(
          ([entry]) => {
            setState(entry.isIntersecting);
          }, {
            rootMargin,
            root,
            threshold: 0.9
          }
        );
    
        element.current && observer.observe(element.current);
        
      }
    })

    return () => element.current && observer && observer.unobserve(element.current);
  }, [element.current]);

  return isVisible;
};