import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenuButton, IonModal, IonPage, IonText, IonTitle, IonToggle, IonToolbar, isPlatform } from '@ionic/react';
import { bugOutline, cartOutline, closeOutline, helpOutline, informationOutline, locationOutline, lockClosedOutline, logOutOutline, mailOutline, megaphoneOutline, notificationsOutline, personOutline, pinOutline, settingsOutline } from 'ionicons/icons';
import './Impostazioni.css';
import { utente } from '../../data/data';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

const Impostazioni: React.FC<any> = ({ modal }) => {
  const location = useLocation()
  const nav = useHistory()
  const closeModal = () => {
    modal.current?.dismiss()
  }
  const closeModalButton = () => {
    closeModal()
    nav.go(-1)
  }
  let opened = false
  const opening = () => {
    if (!opened && !location.search.includes('settingsOpened=true')) {
      opened = true
      nav.push(nav.location.pathname + '?settingsOpened=true')
    }
  }
  useEffect(() => {
    if (!location.search.includes('settingsOpened=true')) {
      closeModal()
    }
  }, [location]);

  return (
    <IonModal onWillPresent={opening} ref={modal} trigger='open-settings'>
      <IonHeader mode='ios'>
        <IonToolbar mode="ios">
          <IonButtons slot='start'>
            <IonButton size='large' onClick={closeModalButton}>
              <IonIcon slot='icon-only' icon={closeOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>Impostazioni</IonTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='__impostazioni'>
        <IonList>
          <IonListHeader>
            <IonLabel>Account</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonIcon icon={personOutline} slot="start" />
            <IonLabel>{utente.nome} {utente.cognome}</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={mailOutline} slot="start" />
            <IonLabel>{utente.email}</IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={locationOutline} slot="start" />
            <IonLabel>{utente.citta}</IonLabel>
          </IonItem>
          <IonItem detail={true}>
            <IonIcon icon={lockClosedOutline} slot="start" />
            <IonLabel>Modifica la password</IonLabel>
          </IonItem>
          <div className="__pt-1 __px-1">
            <IonButton expand='block' color={'danger'} fill='outline'>
              Elimina il tuo account
            </IonButton>
          </div>
          <IonListHeader>
            <IonLabel>Impostazioni App</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonIcon icon={notificationsOutline} slot="start" />
            <IonLabel>Notifiche via mail</IonLabel>
            <IonToggle slot='end'></IonToggle>
          </IonItem>
          <IonItem>
            <IonIcon icon={notificationsOutline} slot="start" />
            <IonLabel>Notifiche push</IonLabel>
            <IonToggle slot='end' checked={true}></IonToggle>
          </IonItem>
          <IonItem detail>
            <IonIcon icon={megaphoneOutline} slot="start" />
            <IonLabel>Gestisci la privacy</IonLabel>
          </IonItem>
          <IonItem detail>
            <IonIcon icon={bugOutline} slot="start" />
            <IonLabel>Segnala un problema</IonLabel>
          </IonItem>
          <IonItem detail>
            <IonIcon icon={helpOutline} slot="start" />
            <IonLabel>Aiuto e supporto</IonLabel>
          </IonItem>
          {/* <IonItem>
            <IonIcon icon={informationOutline} slot="start" />
            <IonLabel>Lorem, ipsum dolor.</IonLabel>
          </IonItem> */}
          <IonListHeader>
            <IonLabel>Informazioni</IonLabel>
          </IonListHeader>
          <IonItem detail lines='full'>
            {/* <IonIcon icon={informationOutline} slot="start" /> */}
            <IonLabel>Informazioni legali</IonLabel>
          </IonItem>
          <IonItem detail lines='full'>
            {/* <IonIcon icon={informationOutline} slot="start" /> */}
            <IonLabel>Termini & Condizioni</IonLabel>
          </IonItem>
          <IonItem detail lines='full'>
            {/* <IonIcon icon={informationOutline} slot="start" /> */}
            <IonLabel>Informativa sulla Privacy</IonLabel>
          </IonItem>
        </IonList>
        <div className="__app-version">
          Versione 0.1
        </div>
      </IonContent>
    </IonModal>
  );
};

export default Impostazioni;
