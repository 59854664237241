import React, { useState } from 'react';
import { IonSkeletonText } from '@ionic/react';
// import imageFallback from '../img/fall.jpeg';

const Image: React.FC<{ src: string, height?: any }> = ({ src, height }) => {
  const imageFallback = ''
  const [ready, setReady] = useState<any>(false);
  const [imgLoad, setImageLoad] = useState<any>(src || imageFallback);

  return (
    <>
      {!ready && <IonSkeletonText animated style={{ height: height ? height : '100%', width: '100%', margin: 0, padding: 0 }} />}
      <img onLoad={() => { setReady(true) }} onError={() => { setImageLoad(imageFallback) }} src={imgLoad} alt="" style={{ height: !ready ? '0px' : (height ? height : '100%'), display: 'block', opacity: !ready ? '0' : '1', transition: 'opacity 0.2s linear' }} />
    </>
  )
};

export default Image