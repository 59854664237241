import { IonAvatar, IonBackButton, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonMenuButton, IonMenuToggle, IonModal, IonPage, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, isPlatform, useIonViewWillEnter } from '@ionic/react';
import { close, help, helpOutline, notificationsOutline } from 'ionicons/icons';
import './Premi.css';
import { negozi, notifiche, premi, utente } from '../../data/data';
import PointsBanner from '../../components/PointsBanner/PointsBanner';
import usePoints from '../../hooks/usePoints';
import React, { useEffect, useRef, useState } from 'react';
import { scrollToTop } from '../../utils/scrollToTop';
import Container from '../../components/common/Container';
import Image from '../../components/Image/Image';

const Punti: React.FC<any> = ({ }) => {
  const { livello, punti } = usePoints()
  const modal = useRef<HTMLIonModalElement>(null)
  const content = useRef(null)
  const segments = useRef(null)
  const tabContent = useRef(null)
  const sezioniPremi = [
    {
      id: 1,
      nome: 'Premi App'
    },
    {
      id: 2,
      nome: 'Premi Negozi'
    },
  ]
  const [categoria, setCategoria] = useState<any>(sezioniPremi[0])
  // @ts-ignore
  const handleCategoria = (e) => {
    const nomeCategoria = e.detail.value
    let nuovaCategoria: { id: number } = { id: 0 }
    sezioniPremi.forEach(el => {
      if (el.nome == nomeCategoria) nuovaCategoria = el
    })
    setCategoria(nuovaCategoria)
  }
  useEffect(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
  }, [categoria])
  useIonViewWillEnter(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
    scrollToTop(content, 0)
    setCategoria(sezioniPremi[0])
  })
  return (
    <IonPage className='__premi'>
      <IonHeader mode='ios' className='__header'>
        <IonToolbar class="__toolbar" mode="ios">
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>Raccolta punti</IonTitle>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton id='open-info'>
              <IonIcon icon={helpOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={content}>
        <Container>
          <div className="__banner-premi">
            <img src={livello.img} />
            <h2>{punti} punti - {livello.nome}</h2>
          </div>
          <IonItemDivider sticky={true} className='__box-shadow-1' ref={segments}>
            <div className="__segments">
              <IonSegment value={categoria.nome} mode='md' onIonChange={handleCategoria}>
                {sezioniPremi.map(({ id, nome }) =>
                  <IonSegmentButton className='__segment-button' key={id} value={nome}>
                    <IonLabel className='__uppercase'>{nome}</IonLabel>
                  </IonSegmentButton>
                )}
              </IonSegment>
            </div>
          </IonItemDivider>
          <div className="__py-1" ref={tabContent}>
            {
              categoria.nome == 'Premi App' ?
                <PremiApp /> :
                categoria.nome == 'Premi Negozi' ?
                  <PremiNegozi /> :
                  ''
            }
          </div>
        </Container>
        <RegolamentoPunti modal={modal} />
      </IonContent>
    </IonPage>
  );
};

export default Punti;

const PremiApp: React.FC<any> = () => {
  const { premiDisponibili, premiNonDisponibili } = usePoints()
  return (
    <div>
      <PointsBanner />
      <div className="__premi-disponibili __px-1">
        <h4>Premi disponibili</h4>
        <div className="__lista-premi">
          {premiDisponibili.map(({ id, nome, img, punti }) => (
            <div className="__card __premio-card __box-shadow-1" key={id}>
              <div className="__img">
                <Image src={img} />
              </div>
              <div className="__premio-nome">
                <p>{nome}</p>
              </div>
              <div className="__premio-punti">
                <IonChip color='danger'>
                  <IonText className='__bold'>{punti} punti</IonText>
                </IonChip>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="__premi-sbloccare __px-1">
        <h4>Premi da sbloccare</h4>
        <div className="__lista-premi">
          {premiNonDisponibili.map(({ id, nome, img, punti }) => (
            <div className="__card __premio-card __box-shadow-1" key={id}>
              <div className="__img">
                <Image src={img} />
              </div>
              <div className="__premio-nome">
                <p>{nome}</p>
              </div>
              <div className="__premio-punti">
                <IonChip color='dark'>
                  <IonText className='__bold'>{punti} punti</IonText>
                </IonChip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
const PremiNegozi: React.FC<any> = () => {
  const { puntiNegozi, premiNegozio } = usePoints()
  return (
    <div className="__premi-disponibili __px-1">
      {/* @ts-ignore */}
      {puntiNegozi.map(({ id, punti }) => (
        <React.Fragment key={id}>
          <h4>{negozi.find(el => el.id == id)?.denominazioneCommerciale}</h4>
          <div className="__points-number">
            <img src="./assets/images/points/reward.png" />
            <h5>{punti} punti accumulati</h5>
          </div>
          <div className="__lista-premi">
            {premiNegozio(punti).map(({ id, nome, img, punti }) => (
              <div className="__card __premio-card __box-shadow-1" key={id}>
                <div className="__img">
                  <Image src={img} />
                </div>
                <div className="__premio-nome">
                  <p>{nome}</p>
                </div>
                <div className="__premio-punti">
                  <IonChip color='danger'>
                    <IonText className='__bold'>{punti} punti</IonText>
                  </IonChip>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}

const RegolamentoPunti: React.FC<any> = ({ modal }) => {
  return (
    <IonModal ref={modal} trigger='open-info'>
      <IonHeader>
        <IonToolbar>
          <IonButtons>
            <IonButton onClick={() => modal.current?.dismiss()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle>Regolamento punti</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima alias ab consectetur suscipit consequatur neque, aliquid asperiores doloribus! Esse autem ea eius animi non dignissimos, perferendis amet ducimus reprehenderit voluptas. Doloribus mollitia unde dicta veritatis temporibus dolore nihil vel! Mollitia aspernatur animi dolorem ut saepe optio magni ratione laudantium quod.</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima alias ab consectetur suscipit consequatur neque, aliquid asperiores doloribus! Esse autem ea eius animi non dignissimos, perferendis amet ducimus reprehenderit voluptas. Doloribus mollitia unde dicta veritatis temporibus dolore nihil vel! Mollitia aspernatur animi dolorem ut saepe optio magni ratione laudantium quod.</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima alias ab consectetur suscipit consequatur neque, aliquid asperiores doloribus! Esse autem ea eius animi non dignissimos, perferendis amet ducimus reprehenderit voluptas. Doloribus mollitia unde dicta veritatis temporibus dolore nihil vel! Mollitia aspernatur animi dolorem ut saepe optio magni ratione laudantium quod.</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minima alias ab consectetur suscipit consequatur neque, aliquid asperiores doloribus! Esse autem ea eius animi non dignissimos, perferendis amet ducimus reprehenderit voluptas. Doloribus mollitia unde dicta veritatis temporibus dolore nihil vel! Mollitia aspernatur animi dolorem ut saepe optio magni ratione laudantium quod.</p>
      </IonContent>
    </IonModal>
  )
}