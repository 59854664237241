import { IonSegmentCustomEvent, ScrollDetail } from '@ionic/core';
import { IonAccordion, IonAccordionGroup, IonAvatar, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonMenuToggle, IonPage, IonRange, IonRefresher, IonRefresherContent, IonRouterLink, IonSearchbar, IonSegment, IonSegmentButton, IonSplitPane, IonText, IonTitle, IonToolbar, isPlatform, SegmentChangeEventDetail, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { products } from '../../components/ProductCard/data';
import ProductsSection from '../../components/sections/ProductsSection';
import { categorie, creators, negozi, notifiche, offerte, postCreators, posts, prodotti, promo, utente } from '../../data/data';
import './Home.css';
import { prodotti as tuttiProdotti } from '../../data/data';
import { cardOutline, caretDownOutline, caretUpOutline, cart, cartOutline, notificationsOutline, searchOutline } from 'ionicons/icons';
import Card from '../../components/Card/Card';
import Section from '../../components/Section/Section';
import SocialPost from '../../components/SocialPost/SocialPost';
import SingleProductOffer from '../../components/SingleProductOffer/SingleProductOffer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
// import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ProductsCarousel from '../../components/ProductsCarousel/ProductsCarousel';
import { RefresherEventDetail } from '@ionic/core';
import Promozione from '../../components/Promozione/Promozione';
import Hero from '../../components/Hero/Hero';
import ShopsCarousel from '../../components/ShopsCarousel/ShopsCarousel';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PointsBanner from '../../components/PointsBanner/PointsBanner';
import { useCart } from '../../hooks/useCart';
import { scrollToTop } from '../../utils/scrollToTop';
import { motion } from 'framer-motion'
import PageFadeIn from '../../components/common/Animation/PageFadeIn';
import Container from '../../components/common/Container';
import PostCreator from '../../components/PostCreator/PostCreator';
import Image from '../../components/Image/Image';


const Home: React.FC<any> = ({ modal, changeTab, location, content }) => {

  const { getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)
  // const content = useRef<any>(null)
  const locationHook = useLocation()
  const history = useHistory()
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log('Begin async operation');

    setTimeout(() => {
      setNumeroArticoli(getProducts().numeroArticoli)
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }
  const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
  const toggleCard = () => {
    setSearching(false)
    if (accordionCaret == caretUpOutline) {
      setAccordionCaret(caretDownOutline)
    }
    if (!accordionGroup.current) {
      return;
    }
    const nativeEl = accordionGroup.current;

    if (nativeEl.value === 'card') {
      nativeEl.value = undefined;
    } else {
      nativeEl.value = 'card';
      // setAccordionCaret(caretDownOutline)
      // @ts-ignore
      if (locationRef.current?.classList.contains('hide')) {
        // @ts-ignore
        locationRef.current?.classList.remove('hide')
      }
    }
  }
  const handleSearch = (e: any) => {
    e.preventDefault()
  }

  const [results, setResults] = useState<[]>([]);
  const [searching, setSearching] = useState(false)

  const handleChange = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    // @ts-ignore
    const arr = []
    if (query) {
      prodotti.forEach(prod => { if (prod.nome.toLowerCase().includes(query)) arr.push(prod) })
      negozi.forEach(shop => { if (shop.nome.toLowerCase().includes(query) || shop.denominazioneCommerciale.toLowerCase().includes(query)) arr.push(shop) })
      creators.forEach(creator => { if (creator.nome.toLowerCase().includes(query)) arr.push(creator) })
      if (arr.length > 0) {
        // @ts-ignore
        setResults(arr);
        // @ts-ignore
      } else setResults(['Nessun risultato'])
    } else setResults([])
  }
  const searchInput = useRef(null)
  const toggleSearch = () => {
    if (accordionCaret == caretUpOutline) {
      setAccordionCaret(caretDownOutline)
    }
    if (!accordionGroup.current) {
      return;
    }
    const nativeEl = accordionGroup.current;

    if (nativeEl.value === 'search') {
      nativeEl.value = undefined;
      setResults([])
      setSearching(false)
      const queryParams = new URLSearchParams(locationHook.search)

      if (queryParams.has('searchOpened')) {
        queryParams.delete('searchOpened')
        history.replace({
          search: queryParams.toString(),
        })
        history.go(-1)
      }
    } else {
      nativeEl.value = 'search';
      if (!locationHook.search.includes('searchOpened=true')) {
        history.push(history.location.pathname + '?searchOpened=true')
      }
      setSearching(true)
      // setTimeout(() => {
      //   // @ts-ignore
      //   searchInput.current.getInputElement().then(el => el.focus())
      // }, 150)
      setAccordionCaret(caretDownOutline)
      // @ts-ignore
      if (locationRef.current?.classList.contains('hide')) {
        // @ts-ignore
        locationRef.current?.classList.remove('hide')
      }
    }
  }
  useEffect(() => {
    if (searching && !locationHook.search.includes('searchOpened=true')) {
      toggleSearch()
    }
    setNumeroArticoli(getProducts().numeroArticoli)
  }, [locationHook])
  const [accordionCaret, setAccordionCaret] = useState(caretDownOutline)
  const toggleLocation = () => {
    if (!accordionGroup.current) {
      return;
    }
    const nativeEl = accordionGroup.current;

    if (nativeEl.value === 'location') {
      nativeEl.value = undefined;
      setAccordionCaret(caretDownOutline)
    } else {
      nativeEl.value = 'location';
      setAccordionCaret(caretUpOutline)
    }
  }
  const [range, setRange] = useState(10)

  const locationRef = useRef(null)
  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (
      ev.detail.deltaY > 0 &&
      ev.detail.scrollTop > 150 &&
      accordionCaret == caretDownOutline
    ) {
      // @ts-ignore
      if (!locationRef.current?.classList.contains('hide')) {
        // @ts-ignore
        locationRef.current?.classList.add('hide')
      }
    }
    if (
      ev.detail.deltaY < 0 ||
      ev.detail.scrollTop < 150
    ) {
      // @ts-ignore
      if (locationRef.current?.classList.contains('hide') && accordionGroup.current?.value == undefined) {
        // @ts-ignore
        locationRef.current?.classList.remove('hide')
      }
    }
  }

  const notificheDaLeggere = notifiche.filter(el => !el.letta)

  const vel = .15
  const alt = 100
  const [style, setStyle] = useState({
    y: alt,
    opacity: 0
  })

  useIonViewWillEnter(() => {
    setStyle({
      y: 0,
      opacity: 1
    })
    // scrollToTop(content, 0)
    setNumeroArticoli(getProducts().numeroArticoli)
  })
  useIonViewWillLeave(() => {
    // setStyle({
    //   y: alt,
    //   opacity: 0
    // })
  })

  const pageId = 'home-page'
  return (
    <IonPage className='__home'>
      <IonHeader mode='ios' className='__header'>
        <IonToolbar class="__toolbar" mode="ios">
          <h1 onClick={() => scrollToTop(content, 500)} className='__cursive-title'>SuperApp</h1>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton onClick={toggleCard}>
              <IonIcon icon={cardOutline} />
            </IonButton>
            <IonButton onClick={toggleSearch}>
              <IonIcon icon={searchOutline} />
            </IonButton>
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
            <IonButton routerLink='/tabs/notifiche'>
              <IonIcon icon={notificationsOutline} />
              <IonBadge>{notificheDaLeggere.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {/* <IonToolbar> */}
        <IonAccordionGroup ref={accordionGroup} className="__accordion-group">
          <IonAccordion value='card'>
            <div slot='content' className='__card-accordion ion-padding'>
              <Card />
            </div>
          </IonAccordion>
          <IonAccordion value='search'>
            <div slot='content' className='__card-accordion'>
              <div className="__search-accordion">
                <form action='' onSubmit={handleSearch}>
                  <IonSearchbar color='light'
                    ref={searchInput}
                    // placeholder='Cerca'
                    animated={true}
                    placeholder="Cerca supermercati o offerte"
                    // inputMode='search'
                    mode='ios'
                    onIonChange={(ev) => handleChange(ev)} />
                </form>
              </div>
              {/* <h3>Cerca un prodotto, un marchio o un supermercato</h3> */}
            </div>
          </IonAccordion>
          <IonAccordion value='location'>
            <div slot='content' className='__card-accordion'>
              <div className="__change-location">
                <div className="__settings __px-1">
                  <IonText>Cambia posizione</IonText>
                  <IonSearchbar placeholder='Cerca' />
                  <IonText>Raggio: {range} km</IonText>
                  {/* <div> */}
                  {/* @ts-ignore */}
                  <IonRange onIonChange={({ detail }) => setRange(detail.value)} value={range} />
                  {/* </div> */}
                </div>
                <div className="__map">
                  <img src="./assets/images/other/1.jpeg" />
                </div>
              </div>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
        {/* </IonToolbar> */}
        <div className='__location' onClick={toggleLocation} ref={locationRef}>
          <IonText>{location.local_names ? (location.local_names.it + ' - ' + range + ' km') : location.name ? (location.name + ' - ' + range + ' km') : location}</IonText>
          <IonIcon icon={accordionCaret} />
        </div>
        {searching &&
          <div className="__search-results">

            <IonList>
              {/* @ts-ignore */}
              {results.map(({ nome, id, denominazioneCommerciale, tipologia, avatar, img, indirizzo }, i, el) => (
                <Fragment key={id + nome}>
                  {denominazioneCommerciale ?
                    <IonItem routerLink={'/tabs/negozio/' + id}>
                      <div className='__search-image __search-image-shop'>
                        <Image src={avatar} />
                      </div>
                      <IonLabel>{denominazioneCommerciale}, {indirizzo}</IonLabel>
                    </IonItem> : tipologia == 'Blogger' ?
                      <IonItem routerLink={'/tabs/creator/' + id}>
                        <div className='__search-image __search-image-shop'>
                          <Image src={avatar} />
                        </div>
                        <IonLabel>{nome}</IonLabel>
                      </IonItem> : nome ?
                        <IonItem routerLink={'/tabs/prodotto/' + id}>
                          <div className='__search-image'>
                            <Image src={img} />
                          </div>
                          <IonLabel>{nome}</IonLabel>
                        </IonItem> :
                        <p className='__px-1'>{el}</p>}
                </Fragment>
              ))}
            </IonList>
            <div className="__search-results-bg" onClick={toggleSearch}></div>
          </div>
        }
      </IonHeader>
      <IonContent
        id={pageId}
        scrollEvents={true}
        ref={content}
        onIonScroll={handleScroll}>
        <PageFadeIn>
          <Container>
            {/* <IonRefresher slot='fixed' onIonRefresh={doRefresh} pullMin={0} pullFactor={0.5} pullMax={50}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher> */}

            <motion.div
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              animate={{ opacity: 1 }}
              className="__home-heading">
              <h2>Bentornato {utente.nome}</h2>
            </motion.div>

            <motion.div
              initial={{ y: alt, opacity: 0 }}
              transition={{ duration: vel }}
              // layout
              animate={style}
            // whileInView={{ y: 0, opacity: 1 }}
            // viewport={{ once }}
            >
              <Hero changeTab={changeTab} />
            </motion.div>

            <motion.div
              initial={{ y: alt, opacity: 0 }}
              transition={{ duration: vel, delay: 0.1 }}
              // layout
              animate={style}
            // whileInView={{ y: 0, opacity: 1 }}
            // viewport={{ once }}
            >
              <IonRouterLink routerLink='/tabs/punti'>
                <PointsBanner />
              </IonRouterLink>
            </motion.div>

            <motion.div
              initial={{ y: alt, opacity: 0 }}
              transition={{ duration: vel, delay: 0.2 }}
              // layout
              animate={style}
              // whileInView={{ y: 0, opacity: 1 }}
              // viewport={{ once }}
              className="__card __home-shops __box-shadow-1">
              <h4>Marchi in evidenza</h4>
              <ShopsCarousel />
            </motion.div>

            <motion.div
              className='__mb-1'
              initial={{ y: alt, opacity: 0 }}
              transition={{ duration: vel, delay: 0.3 }}
              // layout
              animate={style}
            // whileInView={{ y: 0, opacity: 1 }}
            // viewport={{ once }}
            >
              <ProductsCarousel promo={promo[0]} />
            </motion.div>

            <motion.div
              className='__mb-1'
              initial={{ y: alt, opacity: 0 }}
              transition={{ duration: vel, delay: 0.4 }}
              // layout
              animate={style}>
              <Promozione promo={offerte[1]} />
            </motion.div>

            <SocialPost post={posts[1]} page={pageId} />
            <SocialPost post={posts[5]} page={pageId} />
            {/* <PostCreator post={postCreators[0]} page={pageId}  /> */}
            <SocialPost post={posts[3]} page={pageId} />
            <ProductsCarousel promo={promo[3]} />
            <SocialPost post={posts[2]} page={pageId} />
            <SingleProductOffer prodotto={prodotti[5]} />
            {/* <PostCreator post={postCreators[1]} page={pageId}  /> */}
            <SocialPost post={posts[0]} page={pageId} />
            <ProductsCarousel promo={promo[1]} />
            <SingleProductOffer prodotto={prodotti[6]} />
            <Promozione promo={offerte[0]} />
            <SocialPost post={posts[7]} page={pageId} />
            <SingleProductOffer prodotto={prodotti[7]} />
            <SocialPost post={posts[4]} page={pageId} />
            <Promozione promo={offerte[5]} />
            <ProductsCarousel promo={promo[2]} />
            <SocialPost post={posts[9]} page={pageId} />
            <SingleProductOffer prodotto={prodotti[20]} />

            {/* Per dare spazio */}
            <div />
            {/* Per dare spazio */}
          </Container>
        </PageFadeIn>
      </IonContent>
    </IonPage>
  );
};

export default Home;
