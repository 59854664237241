import { IonRouterLink } from "@ionic/react";
import { Link } from "react-router-dom";
import { negozi } from "../../data/data";
import Image from "../Image/Image";

const ShopsSection: React.FC = () => {
  return (
    <div className="__shops-section">
      {negozi.map(({ id, img, denominazioneCommerciale }) => (
        <IonRouterLink routerLink={'/tabs/negozio/' + id} className="__shop-card" key={id}>
          {/* <div> */}
          <div className="__shop-card-img">
            <Image src={img} />
          </div>
          <div className="__card-content">
            <h4 className="__card-title">{denominazioneCommerciale}</h4>
            <div className="__distance">
              <span>x.x km dalla tua posizione</span>
            </div>
          </div>
          {/* </div> */}
        </IonRouterLink>
      ))}
    </div>
  );
}

export default ShopsSection;