import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonRouterLink, useIonRouter } from "@ionic/react";
import { arrowForwardOutline, cartOutline } from "ionicons/icons";
import ProductCard from "../ProductCard/ProductCard";
import './ProductsSection.css'

const Products: React.FC<any> = ({ title, url, list, page, idNegozio }) => {

  return (
    <div className="__products">
      {url ?
        <IonRouterLink routerLink={url}>
          <div className="__products-title">
            <h2>{title}</h2>
            <IonButtons>
              <IonButton>
                <IonIcon icon={arrowForwardOutline} />
              </IonButton>
            </IonButtons>
          </div>
        </IonRouterLink> :
        title &&
        <div className="__products-title">
          <h2>{title}</h2>
        </div>
      }
      <div className="__contents">
        {list.map((product: object, i: number) => (
          <ProductCard key={i} product={product} page={page} idNegozio={idNegozio} />
        ))}
      </div>
    </div>
  );
}

export default Products;