import './SingleProductOffer.css'
import { IonRouterLink, IonText } from '@ionic/react';
import ShopsList from '../ShopsList/ShopsList';
import Image from '../Image/Image';

const SingleProductOffer: React.FC<any> = ({ prodotto }) => {
  const { img, nome, costo, id } = prodotto
  return (
    <div className='__card __single-product-offer-card __box-shadow-1'>
      <div className="__post-container-header">
        <div className="__badge-text">
          Consigliato
        </div>
      </div>
      <IonRouterLink routerLink={`/tabs/prodotto/${prodotto.id}`} className='__single-product-top'>
        <IonText><h3 className='__cursive-title'>{nome}</h3></IonText>
        <div className='__product-offer-img'>
          <Image src={img} />
        </div>
      </IonRouterLink>
      {/* <div className='ion-padding'> */}
      {/* @ts-ignore */}
      <ShopsList idProdotto={id} limit costo={costo} />
      {/* </div> */}
    </div>
  );
}

export default SingleProductOffer;