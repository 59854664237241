import { IonItem, IonLabel } from "@ionic/react";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import './Section.css'

const Section: React.FC<{
  title: string,
  url?: string,
  children: ReactElement,
  padding?: boolean
}> = ({ title, url, children, padding }) => {
  return (
    <section className="__section">
      <div className="__section-title">
        <h2>{title}</h2>
        {/* {url &&
          <IonButtons>
            <IonButton routerLink={url}>
              <IonIcon icon={arrowForwardOutline} />
            </IonButton>
          </IonButtons>
        } */}
      </div>
      <div className={padding ? "ion-padding" : ''}>
        {children}
      </div>
    </section>
  );
}

export default Section;