import Image from "../Image/Image";
import './CoverImage.css'

const CoverImage: React.FC<{ img: string }> = ({ img }) => {
  return (
    <div className="__cover-img">
      <Image src={img} />
    </div>
  );
}

export default CoverImage;