import { IonAccordion, IonAccordionGroup, IonAvatar, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonMenuButton, IonMenuToggle, IonPage, IonRouterLink, IonSearchbar, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, isPlatform, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { cartOutline, checkmarkDoneOutline, notificationsOutline, settingsOutline } from 'ionicons/icons';
import ExploreContainer from '../../components/ExploreContainer';
import './Utente.css';
import { creators, negozi, notifiche, prodotti, utente } from '../../data/data';
import ProductsSection from '../../components/sections/ProductsSection';
import { prodotti as tuttiProdotti } from "../../data/data";
import PointsBanner from '../../components/PointsBanner/PointsBanner';
import { useEffect, useRef, useState } from 'react';
import { formatCost } from '../../utils/formatCost';
import CartItem from '../../components/Cart/CartItem';
import ProductsList from '../../components/ProductsList/ProductsList';
import { useCart } from '../../hooks/useCart';
import { scrollToTop } from '../../utils/scrollToTop';
import { motion } from 'framer-motion'
import usePoints from '../../hooks/usePoints';
import PageFadeIn from '../../components/common/Animation/PageFadeIn';
import Container from '../../components/common/Container';
import { getConvenientProducts } from '../../utils/getConvenientProducts';
import Image from '../../components/Image/Image';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { chat, setChat } from '../../features/chatSlice';
const dayjs = require('dayjs')
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)

const Utente: React.FC<any> = ({ modal, settings, content }) => {
  const cartStorage: [] = JSON.parse(localStorage['cart'] || null) || []
  // @ts-ignore: Unreachable code error
  const prodotti = tuttiProdotti.filter(el => cartStorage.includes(el.id))

  const sezioniUtente = [
    {
      id: 1,
      nome: 'Messaggi'
    },
    {
      id: 2,
      nome: 'Lista della spesa'
    },
    {
      id: 3,
      nome: 'Prodotti preferiti'
    },
    {
      id: 4,
      nome: 'Negozi preferiti'
    },
    {
      id: 5,
      nome: 'Storico acquisti'
    }
  ]
  const [categoria, setCategoria] = useState<any>(sezioniUtente[0])
  // @ts-ignore
  const handleCategoria = (e) => {
    const nomeCategoria = e.detail.value
    let nuovaCategoria: { id: number } = { id: 0 }
    sezioniUtente.forEach(el => {
      if (el.nome == nomeCategoria) nuovaCategoria = el
    })
    setCategoria(nuovaCategoria)
  }
  const notificheDaLeggere = notifiche.filter(el => !el.letta)

  const { livello, punti } = usePoints()
  const segments = useRef(null)
  const tabContent = useRef(null)
  useEffect(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
  }, [categoria])
  useIonViewWillEnter(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'

    // setCategoria(sezioniUtente[0])
  })
  return (
    <IonPage className='__user-page'>
      <IonHeader mode='ios' className='__header'>
        {/* <motion.div
          initial={{ opacity: 0 }}
          animate={style}
          transition={{ duration: .15 }}
        > */}
        <IonToolbar class="__toolbar" mode="ios">
          <IonButtons>
            <IonButton onClick={() => settings.current?.present()}>
              <IonIcon icon={settingsOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>{utente.nome}</IonTitle>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton routerLink='/tabs/notifiche'>
              <IonIcon icon={notificationsOutline} />
              <IonBadge>{notificheDaLeggere.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {/* </motion.div> */}
      </IonHeader>
      <IonContent ref={content}>
        <PageFadeIn>
          <Container>
            <div className="__user-heading">
              <div className="__user">
                {/* <div className="__cover-image">
              <img src={utente.cover} alt="" />
            </div> */}
                <div className="__user-info ion-padding">
                  <div className='__avatar'>
                    <Image src={utente.avatar} />
                  </div>
                  <div className="__info">
                    <h3>{utente.nome} {utente.cognome}</h3>
                    <p>{utente.email}</p>
                  </div>
                </div>
                <IonRouterLink routerLink='/tabs/punti'>
                  <div className="__user-points __box-shadow-1 __card">
                    <div className="__user-points-icon">
                      <img src={livello.img} />
                    </div>
                    <div className="__user-points-text">
                      <p><span>{livello.nome}</span> - {punti} punti</p>
                      <p>Accedi alla sezione premi</p>
                    </div>
                  </div>
                </IonRouterLink>
                {/* <IonRouterLink routerLink='/tabs/punti'>
              <PointsBanner />
            </IonRouterLink> */}
              </div>
            </div>
            <IonItemDivider sticky={true} className='__box-shadow-1' ref={segments}>
              <div className="__segments">
                <IonSegment scrollable={true} value={categoria.nome} mode='md' onIonChange={handleCategoria}>
                  {sezioniUtente.map(({ id, nome }) =>
                    <IonSegmentButton className='__segment-button' key={id} value={nome}>
                      <IonLabel className='__uppercase'>{nome}</IonLabel>
                    </IonSegmentButton>
                  )}
                </IonSegment>
              </div>
            </IonItemDivider>
            <div ref={tabContent}>
              {
                categoria.nome == 'Messaggi' ?
                  <Messaggi /> :
                  categoria.nome == 'Lista della spesa' ?
                    <ListaSpesa /> :
                    categoria.nome == 'Prodotti preferiti' ?
                      <ProdottiPreferiti /> :
                      categoria.nome == 'Negozi preferiti' ?
                        <NegoziPreferiti /> :
                        categoria.nome == 'Storico acquisti' ?
                          <StoricoUtente /> :
                          ''
              }
            </div>
            {/* <ProductsSection title='Lista della spesa' modal={modal} list={prodotti.slice(0, 2)} /> */}
          </Container>
        </PageFadeIn>
      </IonContent>
    </IonPage>
  );
};

export default Utente;

export const Messaggi = () => {
  const messaggi = utente.messaggi.map(el => (
    {
      ...el,
      utente: el.utente.idNegozio
        ? negozi.find(shop => shop.id == el.utente.idNegozio)
        : el.utente.idCreator
          ? creators.find(creator => creator.id == el.utente.idCreator)
          : '',
      tipo: el.utente.idNegozio ? 'negozio' : el.utente.idCreator ? 'creator' : '',
      ultimoMessaggio: {
        ...el.giorni[el.giorni.length - 1].messaggi[el.giorni[el.giorni.length - 1].messaggi.length - 1],
        giorno: el.giorni[el.giorni.length - 1].giorno
      }
    }
  ))
  const compare = (a: any, b: any) => {
    const aDate = dayjs(a.ultimoMessaggio.giorno, 'DD/MM/YY')
    const bDate = dayjs(b.ultimoMessaggio.giorno, 'DD/MM/YY')
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
  }
  messaggi.sort(compare)

  const dispatch = useDispatch()

  const openChat = (type: string, id: number) => {
    dispatch(setChat({type, id}))
  }

  return (
    <div className="__user-messaggi">
      <IonSearchbar
        placeholder='Cerca'
        mode='ios'
      />
      <IonList mode="ios">
        {messaggi.map(({ utente, ultimoMessaggio, id, tipo }) =>
          // @ts-ignore
          // <IonMenuToggle key={id} menu='chat' autoHide={false} >
            <IonItem key={id} onClick={() => openChat(tipo, utente.id)}>
              {/* <IonItem key={id} routerLink={`/tabs/chat/${tipo}/${utente.id}`}> */}
              <div className="__chat-image">
                {/* @ts-ignore */}
                <Image src={utente.avatar} />
              </div>
              <IonLabel>
                {/* @ts-ignore */}
                <h2>{utente.denominazioneCommerciale ?? utente.nome}</h2>
                <p className='__last-message'>{ultimoMessaggio.mittente === 'self' && <IonIcon size='small' color={ultimoMessaggio.letto ? 'primary' : ''} icon={checkmarkDoneOutline} />} {ultimoMessaggio.messaggio}</p>
              </IonLabel>
              <IonLabel slot='end' style={{ textAlign: 'right' }}>
                <h3>{ultimoMessaggio.giorno}</h3>
                <p>{ultimoMessaggio.ora}</p>
              </IonLabel>

              {/* </IonMenuToggle> */}
            </IonItem>
        )}
      </IonList>
    </div>
  )
}

const ListaSpesa = () => {
  const { getProducts } = useCart()
  const [prodottiCarrello, setProdottiCarrello] = useState(getProducts().prodotti)
  const [total, setTotal] = useState(getProducts().costo)

  const updateCart = () => {
    setTotal(getProducts().costo)
    setProdottiCarrello(getProducts().prodotti)
  }

  useIonViewWillEnter(() => {
    updateCart()
  })

  if (prodottiCarrello.length > 0) {
    return (
      <div className="__lista-spesa">
        <div className="__pt-1">
          {/* @ts-ignore */}
          {prodottiCarrello.map(({ negozio, prodotti }) => (
            // @ts-ignore
            <IonList key={negozio.id}>
              {/* @ts-ignore */}
              <IonRouterLink routerLink={'/tabs/negozio/' + negozio.id}>
                <h3 className="__cart-list-title">
                  <IonText>
                    {/* @ts-ignore */}
                    {negozio.denominazioneCommerciale}
                  </IonText>
                </h3>
              </IonRouterLink>
              {/* @ts-ignore: Unreachable code error */}
              {/* @ts-ignore */}
              {prodotti.map((prodotto, i) => (
                // @ts-ignore
                <CartItem key={i} prodotto={prodotto} negozio={negozio.id} updateCart={updateCart} />
              ))}
            </IonList>
          ))}
          <div className="__costo-carrello">
            <h5 className="__total">Totale: <strong>€ {total.totale.toFixed(2)}</strong></h5>
            <h5 className="__extotal">Invece di: <strong>€ {total.exTotale.toFixed(2)}</strong></h5>
            <h5 className="__total-discount">Stai risparmiando: <strong>€ {total.risparmio.toFixed(2)}</strong></h5>
            <h5 className="__total-points">Stai accumulando <strong>{total.punti} punti</strong></h5>
          </div>
        </div>

      </div>
    )
  }
  return (
    <div className="__no-data">
      <p>La tua lista della spesa è vuota.</p>
    </div>
  )
}

const ProdottiPreferiti = () => {
  const prodottiPreferiti = getConvenientProducts(utente.preferiti.prodotti.map(prod => prodotti.find(el => el.id == prod)))
  return (
    <div className="__prodotti-preferiti __py-1">
      <ProductsList prodotti={prodottiPreferiti} />
    </div>
  )
}

const NegoziPreferiti = () => {
  const negoziPreferiti = negozi.filter(shop => shop.preferito)
  return (
    <div className="__prodotti-preferiti ion-padding">
      {/* @ts-ignore */}
      {negoziPreferiti.map(({ denominazioneCommerciale, id, img, indirizzo, citta }, i) => (
        // <IonItem routerLink={'/tabs/negozio/' + id} key={id}>
        //   <div className='__list-image __round'>
        //     <img src={img} alt="" />
        //   </div>
        //   <IonLabel>{denominazioneCommerciale}</IonLabel>
        // </IonItem>
        <motion.div
          key={id}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: i * 0.05, type: "spring" }}>
          <IonRouterLink routerLink={`/tabs/negozio/${id}`}>
            <div className="__card __box-shadow-1 __list-card __list-card-shop">
              <div className="__list-card-image __list-card-shop-image">
                <Image src={img} />
              </div>
              <div className="__list-card-text">
                <h3>{denominazioneCommerciale}</h3>
                <p>{indirizzo}</p>
                <p>{citta}</p>
              </div>
              {/* <div className="__list-card-text">{denominazioneCommerciale}</div> */}
            </div>
          </IonRouterLink>
        </motion.div>
      ))}
    </div>
  )
}

const StoricoUtente = () => {
  const tuttiProdotti = prodotti
  const compare = (a: any, b: any) => {
    const aDate = dayjs(a.data, 'DD/MM/YYYY')
    const bDate = dayjs(b.data, 'DD/MM/YYYY')
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0
  }
  const transazioni = utente.storico.sort(compare)
  return (
    <IonAccordionGroup value={transazioni[0].idTransazione}>
      {transazioni.map(({ idTransazione, idNegozio, prodotti, data }) => (
        <IonAccordion key={idTransazione} value={idTransazione}>
          <IonItem slot="header">
            <IonLabel>{negozi.find(el => el.id == idNegozio)?.denominazioneCommerciale} - {data}</IonLabel>
          </IonItem>
          <div className="__transazione" slot='content'>
            <ProductsList idNegozio={idNegozio} prodotti={prodotti.map(el => tuttiProdotti.find(prod => prod.id == el))} />
          </div>
        </IonAccordion>
      ))}
    </IonAccordionGroup>
  );
}