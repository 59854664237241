import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios'

export const authentication = createSlice({
  name: 'auth',
  initialState: {
    value: false
  },
  reducers: {
    login: (state) => {
      state.value = true
    }
  }
})

export const { login } = authentication.actions

export default authentication.reducer