import { livelli, utente } from "../../data/data";
import usePoints from "../../hooks/usePoints";
import './PointsBanner.css'

const PointsBanner: React.FC<any> = () => {
  const { punti, livello } = usePoints()
  let puntiProssimoLivello = livello?.puntiMax
  // @ts-ignore
  let puntiMancanti = puntiProssimoLivello - punti
  if (puntiProssimoLivello == 400 && puntiMancanti <=0) {
    puntiProssimoLivello = 0
    puntiMancanti = 0
  }
  // @ts-ignore
  const percentualePunti = puntiProssimoLivello ? 100 / puntiProssimoLivello * punti : 100

  const prossimoLivello = livelli.find(el => el.puntiMin == puntiProssimoLivello)
  const nomeProssimoLivello = prossimoLivello?.nome

  return (
    <div className="__points">
      {/* <h4>Hai raccolto <span>{punti}</span> punti in app</h4> */}
      {/* <h4>{livello.nome}</h4> */}
      <div className="__points-banner __card __box-shadow-1">
        {puntiMancanti && prossimoLivello ?
          <p>Ti mancano <strong>{puntiMancanti}</strong> punti per raggiungere il livello <strong>{nomeProssimoLivello}</strong>!</p> :
          puntiMancanti && !prossimoLivello ?
            <p>Ti mancano <strong>{puntiMancanti}</strong> punti per raggiungere il livello massimo!</p> :
            <p>Hai raggiunto il livello massimo!</p>
        }
        <div className="__points-bottom">
          <span className='__actual-points'>{punti}</span>
          <div className="__points-bar">
            {percentualePunti > 0 &&
              <div className="__bar-progression" style={{ width: percentualePunti + '%' }}></div>
            }
          </div>
          <span className='__next-points'>{puntiProssimoLivello || '∞'}</span>
        </div>
      </div>
    </div>
  );
}

export default PointsBanner;