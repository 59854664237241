import { IonButton, IonText } from '@ionic/react';
import { utente } from '../../data/data';
import './Card.css'

const Card: React.FC = () => {
  return (
    <>
      <div className="__shopping-card">
        <div className="__shopping-card-logo">
          <h2>Super App Card</h2>
        </div>
        <div className="__shopping-card-barcode">
          <img src="./assets/images/barcode/1.jpg" alt="" />
        </div>
        <div className="__user-info">
          <IonText>{utente.nome} {utente.cognome}</IonText><br />
          <IonText>{utente.codiceUtente}</IonText>
        </div>
      </div>
      <IonButton color={'dark'} fill='solid' expand='block' mode={'ios'} className='__wallet-button'>
        Aggiungi a Wallet
      </IonButton>
    </>
  );
}

export default Card;