import { useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { motion } from "framer-motion";
import { ReactElement, useState } from "react";
import { scrollToTop } from "../../../utils/scrollToTop";

const PageFadeIn: React.FC<{ children: ReactElement, content?: any }> = ({ children, content }) => {
  const [style, setStyle] = useState({ opacity: 0 })
  useIonViewWillEnter(() => {
    setStyle({ opacity: 1 })
    if (content) scrollToTop(content, 0)
  })
  useIonViewDidLeave(() => {
    setStyle({ opacity: 0 })
  })
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={style}
      transition={{ duration: .15 }}>
      {children}
    </motion.div>
  );
}

export default PageFadeIn;