import { IonIcon, IonLabel, IonRouterLink } from '@ionic/react';
import { addOutline, arrowForwardOutline } from 'ionicons/icons';
import './PromoCarousel.css'

const PromoCarousel: React.FC<any> = ({ promo, selected, handleOfferta }) => {

  return (
    <div className="__products-carousel-section __promo-carousel">
      <div className="__post-container-header">
        <h3 className='__cursive-title'>Promozioni in corso</h3>
      </div>
      <div className="__carousel-container">
        {/* @ts-ignore */}
        {promo.map(({ img, id, nomeOfferta, dataInizio, dataFine }) => (
          <div className={"__card __box-shadow-1 __promo-card " + (selected.id == id ? '__selected-promo  __box-shadow-2' : '')} key={id} onClick={() => handleOfferta(id)}>
            <div className="__promo-card-img">
              <img src={img} alt="" />
            </div>
            <h5>{nomeOfferta}</h5>
            <p>Dal {dataInizio} al {dataFine}.</p>
          </div>
          // <CardCarousel prodotto={prodotto} key={prodotto.id} />
        ))}
        {/* <div className="__card-carousel __card __box-shadow-2">
          <div className="__plus">
            <IonIcon icon={arrowForwardOutline} />
            <IonLabel>Vedi tutto</IonLabel>
          </div>
        </div> */}
        {/* <div className="__card-carousel __offset-right"></div> */}
      </div>
      {/* <div className="__gradient"></div> */}
    </div>
  );
}

export default PromoCarousel;