import { IonButton, IonIcon, IonItem, IonLabel, IonRouterLink, IonText, IonThumbnail } from "@ionic/react";
import { addOutline, removeOutline, trashOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import { useCart } from "../../hooks/useCart";
import { formatCost } from "../../utils/formatCost";
import Image from "../Image/Image";
import './CartItem.css'

const CartItem: React.FC<any> = ({ prodotto, closeModal, negozio, updateCart }) => {
  const { nome, id, costo, img, sconto, quantita } = prodotto
  const { addToCart, removeFromCart, deleteProductFromCart } = useCart()
  const piuUnoCarrello = () => {
    addToCart(prodotto.id, negozio)
    updateCart()
  }
  const menoUnoCarrello = () => {
    removeFromCart(prodotto.id, negozio)
    updateCart()
  }
  const rimuoviCarrello = () => {
    deleteProductFromCart(prodotto.id, negozio)
    updateCart()
  }
  return (
    <div className="__cart-card __card __box-shadow-1">
      <div className="__preview-space">
        <div className="__preview">
          <IonRouterLink routerLink={'/tabs/prodotto/' + id} onClick={closeModal} >
            <Image src={img} />
          </IonRouterLink>
        </div>
      </div>
      <div className="__content">
        <IonRouterLink routerLink={'/tabs/prodotto/' + id} onClick={closeModal}>
          {/* <a> */}
          <h3>{nome}</h3>
          {/* </a> */}
        </IonRouterLink>
        <div className="__price">
          <span className="__new-price __me-1">€ {formatCost(costo, sconto)}</span>
          <span className="__new-price __old-price __me-1">€ {costo}</span>
          {/* <span className="__discount __me-1">-{sconto}%</span> */}
          {/* <span className="__old-price">€ {costo}</span> */}
        </div>
        <div className="__quantity">
          <div className="__decrement __me-1">
            <IonButton size="small" color={'light'} onClick={menoUnoCarrello}>
              <IonIcon icon={removeOutline} />
            </IonButton>
          </div>
          <div className="__number __me-1">
            {quantita}
          </div>
          <div className="__increment">
            <IonButton size="small" color={'light'} onClick={piuUnoCarrello}>
              <IonIcon icon={addOutline} />
            </IonButton>

          </div>
          <div className="__remove">
            <IonButton fill="clear" onClick={rimuoviCarrello}>
              {/* <IonLabel className="__me-1">Rimuovi</IonLabel> */}
              <IonIcon icon={trashOutline} />
            </IonButton>
          </div>
        </div>
      </div>
      {/* <IonThumbnail>
      </IonThumbnail>
      <IonText>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta!</IonText>
      <span>x{2} € {costo}</span> */}
    </div>
  );
}

export default CartItem;