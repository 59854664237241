import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { bookOutline, cartOutline, shareOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Container from '../../components/common/Container';
import Header from '../../components/Header/Header';
import Products from '../../components/sections/ProductsSection';
import { offerte, prodotti as tuttiProdotti } from '../../data/data';
import { useCart } from '../../hooks/useCart';
import { getProductsFromShop } from '../../utils/getProductsFromShop';
import { scrollToTop } from '../../utils/scrollToTop';
import './Promozione.css'

interface PromoPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {
  modal: any
}

const Promozione: React.FC<PromoPageProps> = ({ match, modal }) => {
  const promo = offerte.filter(el => el.slug == match.params.slug)[0]

  const { nomeOfferta, img, dataInizio, dataFine, negozio, logo, descrizione, idNegozio } = promo
  const prodotti = getProductsFromShop(promo.prodotti, idNegozio)
  const { getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)
  const content = useRef(null)
  useIonViewWillEnter(() => {
    scrollToTop(content, 0)
    setNumeroArticoli(getProducts().numeroArticoli)
  })

  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{nomeOfferta + ' - ' + negozio}</IonTitle>
          <IonButtons slot='end' className="__notifications-badge">
            <IonButton>
              <IonIcon icon={shareOutline} />
            </IonButton>
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={content}>
        <Container>
          <div className="__promozione">
            <div className="__hero">
              <img src={img} alt="" />
            </div>
            <div className='ion-padding'>
              {descrizione ?
                <p className="__m-0 __text-center" dangerouslySetInnerHTML={{ __html: descrizione }} /> :
                <p className='__m-0 __text-center'>Offerte {negozio} dal {dataInizio} al {dataFine}.</p>
              }
            </div>
            <div className='__info-bottom ion-padding'>
              <img className='__logo-negozio' src={logo} alt="" />
              <IonButton>
                <IonLabel>Volantino</IonLabel>
                <IonIcon slot='end' icon={bookOutline}/>
              </IonButton>
            </div>
            <Products list={prodotti} idNegozio={idNegozio} />
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default Promozione;
