import { IonButton, IonLabel, IonRouterLink, IonSkeletonText, IonThumbnail } from '@ionic/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageSpecial from '../Image/imageSpecial';
import './Hero.css'

const Hero: React.FC<any> = ({ changeTab }) => {
  const cards = [
    {
      id: 1,
      name: 'offerte',
    },
    {
      id: 2,
      name: 'negozi',
    },
    {
      id: 3,
      name: 'social',
    },
  ]
  return (
    <div className='__hero'>
      <div className="__carousel-container">
        {cards.map(({ id, name }) => (
          <Card key={id} id={id} name={name} />
        ))}
      </div>
    </div>
  );
}

export default Hero;

const Card: React.FC<any> = ({ id, name }) => {
  const [ready, setReady] = useState(false)
  return (
    <>
      <IonRouterLink style={{ display: !ready ? 'none' : 'flex' }} className='__hero-card __box-shadow-1' routerLink={`/tabs/${name}?back=true`} >
        <img onLoad={() => setReady(true)} onError={() => setReady(false)} src={`./assets/images/icons/${id}.png`} alt="" />
        <p>{name}</p>
      </IonRouterLink>
      <div style={{ display: ready ? 'none' : 'flex', padding: '0' }} className='__hero-card __box-shadow-1'>
        <IonSkeletonText animated style={{margin: 0}}/>
      </div>
    </>
  )
}