import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonAvatar,
  IonBadge,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonMenu,
  IonMenuToggle,
  IonPage,
  IonRouterLink,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact,
  useIonRouter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, home, homeOutline, menuOutline, newspaper, newspaperOutline, peopleOutline, personOutline, search, searchOutline, settingsOutline, square, storefrontOutline, triangle } from 'ionicons/icons';
import Home from './pages/Home/Home';
import Offerte from './pages/Offerte/Offerte';
import Cerca from './pages/Cerca/Cerca';
import Menu from './components/Notifications/Notifications';
import Utente from './pages/Utente/Utente';
import axios from 'axios'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Custom css
import './styles/style.css'
import Prodotti from './pages/Prodotti/Prodotti';
import Promozione from './pages/Promozione/Promozione';
import Prodotto from './pages/Prodotto/Prodotto';
import { useEffect, useRef, useState } from 'react';
import Carrello from './pages/Carrello/Carrello';
import Impostazioni from './pages/Impostazioni/Impostazioni';
import Social from './pages/Social/Social';
import Negozio from './pages/Negozio/Negozio';
import Negozi from './pages/Negozi/Negozi';
import Notifications from './components/Notifications/Notifications';
import MainTabs from './pages/MainTabs/MainTabs';
import { useDispatch, useSelector } from 'react-redux';
import Login from './pages/Login/Login';
import { login } from './features/loginSlice';
import Chat from './pages/Chat/Chat';

setupIonicReact();

const App: React.FC = () => {
  // @ts-ignore
  const auth = useSelector(state => state.auth.value)
  const dispatch = useDispatch()
  useEffect(() => {
    // console.log('cambiato', auth);
    if (auth) {
      localStorage['loggedIn'] = true
    }

    (window.location.hostname == 'localhost' || window.location.hostname.includes('19') || window.location.hostname.includes('giovanni') || localStorage['loggedIn'] === 'true') && dispatch(login())
  }, [auth])
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId='main'>
          {/* {auth && <Notifications />} */}
          {auth && <Chat />}
          <IonRouterOutlet id='main'>
            {/* <Route>
              <Redirect to='/' />
            </Route> */}

            <Route path='/' render={() => auth ? <MainTabs /> : <Login />} />
            <Route exact path='/404' render={() => (
              <div style={{ position: 'absolute', width: '100%', height: '100vh', top: 0, left: 0, background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <h2>404 Page not found</h2>
                <a href="/">Home</a>
              </div>
            )} />

            {/* <Route exact path='/login' render={() => <Login />} /> */}
            {/* <Redirect to='/' /> */}
            {/* <Redirect to='/login' /> */}
            {/* <Route path='/tabs' render={() => <MainTabs />} /> */}
            {/* <Redirect exact path="/" to='/tabs/home' /> */}
            {/* <Redirect to={auth ? '/tabs' : '/login'} /> */}
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
