import { IonSegmentCustomEvent } from '@ionic/core';
import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonLabel, IonMenuToggle, IonPage, IonSegment, IonSegmentButton, IonTitle, IonToolbar, SegmentChangeEventDetail, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { products } from '../../components/ProductCard/data';
import ProductsSection from '../../components/sections/ProductsSection';
import { categorie, notifiche, offerte, postCreators, posts, prodotti } from '../../data/data';
import './Social.css';
import { prodotti as tuttiProdotti } from '../../data/data';
import { cardOutline, cart, cartOutline, notificationsOutline } from 'ionicons/icons';
import Card from '../../components/Card/Card';
import Section from '../../components/Section/Section';
import SocialPost from '../../components/SocialPost/SocialPost';
import SingleProductOffer from '../../components/SingleProductOffer/SingleProductOffer';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import { scrollToTop } from '../../utils/scrollToTop';
import { motion } from 'framer-motion'
import PageFadeIn from '../../components/common/Animation/PageFadeIn';
import Container from '../../components/common/Container';
import { useLocation, useHistory } from 'react-router';
import PostCreator from '../../components/PostCreator/PostCreator';


const Social: React.FC<any> = ({ modal, content }) => {
  const notificheDaLeggere = notifiche.filter(el => !el.letta)
  const locationHook = useLocation()
  const history = useHistory()
  const [backBtn, setBackBtn] = useState(false)
  const [checked, setChecked] = useState(false)
  useIonViewDidLeave(() => {
    setChecked(false)
    setBackBtn(false)
  })
  useEffect(() => {
    if (locationHook.search && !checked) {
      const queryParams = new URLSearchParams(locationHook.search)
      if (queryParams.has('back')) {
        setChecked(true)
        setBackBtn(true)
        queryParams.delete('back')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }

  }, [locationHook.pathname])
  return (
    <IonPage ref={content}>
      <IonHeader mode='ios' className='__header'>
        {/* <motion.div
          initial={{opacity: 0 }}
          animate={style}
          transition={{ duration: .15 }}
        > */}
        <IonToolbar class="__toolbar" mode="ios">
          {backBtn &&
            <IonButtons slot='start'>
              <IonBackButton text='' className='__back-button' />
            </IonButtons>
          }
          {/* <IonButtons>
            <IonButton onClick={scrollToCard}>
              <IonIcon icon={cardOutline} />
            </IonButton>
          </IonButtons> */}
          <IonTitle>Social</IonTitle>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton routerLink='/tabs/notifiche'>
              <IonIcon icon={notificationsOutline} />
              <IonBadge>{notificheDaLeggere.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {/* </motion.div> */}
      </IonHeader>
      <IonContent fullscreen scrollEvents={true} ref={content} id='social-page'>
        <PageFadeIn>
          <Container>
            {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Social</IonTitle>
          </IonToolbar>
        </IonHeader> */}
            {/* <div className="__card-space ion-padding" id='card'>
          <Card />
        </div> */}
            {/* <Section title='Le ultime notizie' url='/' padding={true}> */}
            <div className='__py-1'>
              {posts.map(post => (
                <SocialPost post={post} key={post.id} page={'social-page'} />
              ))}
              <SocialPost post={posts[4]} page={'social-page'} />
              <SocialPost post={posts[3]} page={'social-page'} />
              <SocialPost post={posts[2]} page={'social-page'} />
              <SocialPost post={posts[1]} page={'social-page'} />
              <SocialPost post={posts[10]} page={'social-page'} />
            </div>
            {/* </Section> */}
          </Container>
        </PageFadeIn>
      </IonContent>
    </IonPage>
  );
};

export default Social;
