import { IonSegmentCustomEvent, ScrollDetail } from '@ionic/core';
import { IonAvatar, IonBackButton, IonBadge, IonButton, IonButtons, IonCheckbox, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonRouterLink, IonSearchbar, IonSegment, IonSegmentButton, IonTitle, IonToolbar, SegmentChangeEventDetail, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import Banner from '../../components/Banner/Banner';
import { products } from '../../components/ProductCard/data';
import ProductsSection from '../../components/sections/ProductsSection';
import { categorie, creators, marchi, negozi } from '../../data/data';
import './Offerte.css';
import { prodotti as tuttiProdotti } from '../../data/data';
import { cart, cartOutline, filterOutline } from 'ionicons/icons';
import Promozione from '../../components/Promozione/Promozione';
import ProductsCarousel from '../../components/ProductsCarousel/ProductsCarousel';
import { useCart } from '../../hooks/useCart';
import { scrollToTop } from '../../utils/scrollToTop';
import { motion } from 'framer-motion'
import SingleProductOffer from '../../components/SingleProductOffer/SingleProductOffer';
import PageFadeIn from '../../components/common/Animation/PageFadeIn';
import Container from '../../components/common/Container';
import { useHistory, useLocation } from 'react-router';
import { getProductsFromBrand } from '../../utils/getProductsFromBrand';
import Image from '../../components/Image/Image';


const Offerte: React.FC<any> = ({ modal, content }) => {
const [filteredBrand, setFilteredBrand] = useState(marchi)

  const handleBrand = (idBrand: number) => {
    if (idBrand == 0 || filteredBrand.length == 1 && filteredBrand[0].id == idBrand) {
      setFilteredBrand(marchi)
    } else setFilteredBrand(marchi.filter(el => el.id == idBrand))
  }
  const { getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)
  const vel = .15
  const alt = 100
  const [style, setStyle] = useState({
    y: alt,
    opacity: 0
  })
  // @ts-ignore
  const [height, setHeight] = useState(0)
  // @ts-ignore
  const [searchHeight, setSearchHeight] = useState(0)
  useIonViewWillEnter(() => {
    // @ts-ignore
    setHeight(searchToolbar.current.getBoundingClientRect().height)
    // @ts-ignore
    setSearchHeight(content.current.getBoundingClientRect().height - searchToolbar.current.getBoundingClientRect().height)
    setNumeroArticoli(getProducts().numeroArticoli)
    setStyle({
      y: 0,
      opacity: 1
    })
  })
  const locationHook = useLocation()
  const history = useHistory()
  const [backBtn, setBackBtn] = useState(false)
  const [checked, setChecked] = useState(false)
  useIonViewDidLeave(() => {
    setChecked(false)
    setBackBtn(false)
    setSearching(false)
    // @ts-ignore
    searchInput.current.value = ''
    setResults([])
  })
  useEffect(() => {
    if (locationHook.search && !checked) {
      const queryParams = new URLSearchParams(locationHook.search)
      if (queryParams.has('back')) {
        setChecked(true)
        setBackBtn(true)
        queryParams.delete('back')
        history.replace({
          search: queryParams.toString(),
        })
      }
    }

  }, [locationHook.pathname])

  const handleSearch = (e: any) => {
    e.preventDefault()
  }

  const [results, setResults] = useState<[]>([]);
  const [searching, setSearching] = useState(false)
  const searchInput = useRef(null)

  const focusSearch = () => {
    if (!locationHook.search.includes('searchOpened=true')) {
      history.push(history.location.pathname + '?searchOpened=true')
    }
    setSearching(true)

  }
  const blurSearch = () => {
    setSearching(false)
    if (searchInput.current) {
      // @ts-ignore
      searchInput.current?.getInputElement().then(el => el?.blur())
    }
  }
  useEffect(() => {
    // if (!locationHook.search.includes('modalOpened=true')) {
    if (!locationHook.search.includes('searchOpened=true')) {
      // @ts-ignore
      // searchInput.current.value = ''
      // setResults([])
      blurSearch()
    } else focusSearch()

    // }
    setNumeroArticoli(getProducts().numeroArticoli)
  }, [locationHook])
  const handleChange = (ev: Event) => {
    // let query = "";
    // @ts-ignore
    let query = searchInput.current?.value
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    // console.log(target);

    // @ts-ignore
    const arr = []
    if (query) {
      // if (!searching) setSearching(true)
      tuttiProdotti.forEach(prod => { if (prod.nome.toLowerCase().includes(query)) arr.push(prod) })
      negozi.forEach(shop => { if (shop.nome.toLowerCase().includes(query) || shop.denominazioneCommerciale.toLowerCase().includes(query)) arr.push(shop) })
      creators.forEach(creator => { if (creator.nome.toLowerCase().includes(query)) arr.push(creator) })
      if (arr.length > 0) {
        // @ts-ignore
        setResults(arr);
        // @ts-ignore
      } else setResults(['Nessun risultato'])
      // @ts-ignore
      // console.log(arr);

    } else setResults([])
  }

  const searchToolbar = useRef(null)
  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (
      ev.detail.deltaY > 0 &&
      ev.detail.scrollTop > 150
    ) {
      // @ts-ignore
      if (!searchToolbar.current?.classList.contains('hide')) {
        // @ts-ignore
        searchToolbar.current?.classList.add('hide')
      }
    }
    if (
      ev.detail.deltaY < 0 ||
      ev.detail.scrollTop < 150
    ) {
      // @ts-ignore
      if (searchToolbar.current?.classList.contains('hide')) {
        // @ts-ignore
        searchToolbar.current?.classList.remove('hide')
      }
    }
  }

  const getRandom = (arr: []) => {
    return Math.floor(Math.random() * arr.length)
  }

  return (
    <IonPage className='__offerte'>
      <IonHeader mode='ios' className='__header'>
        <IonToolbar class="__toolbar-noborder" mode="ios" onClick={() => searching && history.go(-1)}>
          {backBtn &&
            <IonButtons slot='start'>
              <IonBackButton text='' className='__back-button' />
            </IonButtons>
          }
          <IonTitle>Offerte</IonTitle>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton onClick={() => !searching && modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <IonToolbar className='__toolbar' mode='ios' ref={searchToolbar}>
          <form action='' onSubmit={handleSearch}>
            <IonSearchbar color='light'
              onIonFocus={focusSearch}
              ref={searchInput}
              animated={true}
              placeholder="Cerca supermercati o offerte"
              mode='ios'
              onIonChange={(ev) => handleChange(ev)}
            />
          </form>
        </IonToolbar>
        {searching &&
          <div className="__search-results" style={{ top: height }}>
            <IonList style={{ maxHeight: searchHeight }}>
              {/* @ts-ignore */}
              {results.map(({ nome, id, denominazioneCommerciale, tipologia, avatar, img, indirizzo }, i, el) => (
                <Fragment key={id + nome}>
                  {denominazioneCommerciale ?
                    <IonItem routerLink={'/tabs/negozio/' + id}>
                      <div className='__search-image __search-image-shop'>
                        <Image src={avatar}  />
                      </div>
                      <IonLabel>{denominazioneCommerciale}, {indirizzo}</IonLabel>
                    </IonItem> : tipologia == 'Blogger' ?
                    <IonItem routerLink={'/tabs/creator/' + id}>
                      <div className='__search-image __search-image-shop'>
                        <Image src={avatar}  />
                      </div>
                      <IonLabel>{nome}</IonLabel>
                    </IonItem> : nome ?
                      <IonItem routerLink={'/tabs/prodotto/' + id}>
                        <div className='__search-image'>
                          <Image src={img}  />
                        </div>
                        <IonLabel>{nome}</IonLabel>
                      </IonItem> :
                      <p className='__px-1'>{el}</p>}
                </Fragment>
              ))}
            </IonList>
            <div className="__search-results-bg" style={{ height: searchHeight }} onClick={() => history.go(-1)}></div>
          </div>
        }
      </IonHeader>
      <IonContent
        fullscreen
        scrollEvents={true}
        ref={content}
        onIonScroll={handleScroll}>
        <PageFadeIn>
          <Container>
            <div className="__filters">
              <motion.div className="__categories" initial={{ opacity: 0 }} transition={{ duration: .6 }} animate={{ opacity: 1 }}>
                <h3>Marchi</h3>
                <div className="__chips">
                  <IonChip outline={filteredBrand != marchi} onClick={() => handleBrand(0)}>
                    <IonLabel>Tutti</IonLabel>
                  </IonChip>
                  {marchi.map(({ id, nome, avatar }) => (
                    <IonChip key={id} outline={!(filteredBrand.length == 1 && filteredBrand[0].id == id)} onClick={() => handleBrand(id)}>
                      <IonAvatar><Image src={avatar} /></IonAvatar>
                      <IonLabel>{nome}</IonLabel>
                    </IonChip>
                  ))}
                </div>
              </motion.div>
              <div className="__categories __pt-05">
                <motion.h3 initial={{ opacity: 0 }} transition={{ duration: .6 }} animate={{ opacity: 1 }}>Categorie</motion.h3>
                <div className="__cards">
                  {categorie.map(({ id, titolo, slug, img }, i) => (
                    <motion.div
                      key={id}
                      initial={{ y: alt, opacity: 0 }}
                      transition={{ duration: vel, delay: 0.05 * i }}
                      animate={style}
                      className="__category-card __card __box-shadow-05">
                      <IonRouterLink routerLink={'/tabs/prodotti/' + slug}>
                        <div className="__category-img">
                          <Image src={img} />
                        </div>
                        <div className="__category-name">
                          <p>{titolo}</p>
                        </div>
                      </IonRouterLink>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
            {filteredBrand.map(({ id, nome }) => (
              <ProductsSection key={id} title={'Prodotti in offerta ' + nome} url={`/tabs/prodotti/${nome}`} list={getProductsFromBrand(id).slice(0, 4)} />
            ))}
          </Container>
        </PageFadeIn>
      </IonContent>
    </IonPage >
  );
};

export default Offerte;
