import { IonRouterLink } from "@ionic/react";
import { formatCost } from "../../utils/formatCost";
import { motion } from 'framer-motion'
import { getProductsFromShop } from "../../utils/getProductsFromShop";
import Image from "../Image/Image";

const ProductsList: React.FC<any> = ({ prodotti, idNegozio }) => {
  const products = idNegozio ? getProductsFromShop(prodotti, idNegozio) : prodotti

  return (
    <div className="__px-1">
      {/* @ts-ignore */}
      {products.map(({ nome, img, costo, sconto, id }, i) => (
        <motion.div
          key={id}
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1, delay: i * 0.05, type: "spring" }}>
          <IonRouterLink routerLink={idNegozio ? `/tabs/prodotto/${id}/${idNegozio}` : `/tabs/prodotto/${id}`}>
            <div className="__card __box-shadow-1 __list-card">
              <div className="__list-card-image">
                <Image src={img} />
              </div>
              <div className="__list-card-text">{nome}</div>
              <div className="__list-card-price">€ {formatCost(costo, sconto)}</div>
            </div>
          </IonRouterLink>
        </motion.div>
      ))}
    </div>
  );
}

export default ProductsList;