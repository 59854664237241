import { IonAccordion, IonAccordionGroup, IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonRouterLink, IonText, useIonActionSheet } from "@ionic/react";
import type { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { addOutline, arrowForwardOutline, arrowRedoOutline, bookmarkOutline, chatboxOutline, ellipsisVertical, heart, heartOutline, shareOutline, thumbsUp, thumbsUpOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { negozi, prodotti as tuttiProdotti } from "../../data/data";
import { formatCost } from "../../utils/formatCost";
import { getProductsFromShop } from "../../utils/getProductsFromShop";
import CardCarousel from "../CardCarousel/CardCarousel";
import Image from "../Image/Image";
import './Promozione.css'

const Promozione: React.FC<any> = ({ promo }) => {
  // const { utente, data, media, commenti, testo } = promo
  const { utente, data, img, commenti, testo, slug, nomeOfferta, idNegozio, dataInizio, dataFine } = promo
  const [like, setLike] = useState<boolean>(promo.like)
  const [likes, setLikes] = useState<number>(promo.likes)
  const miPiace = () => {
    if (like) {
      setLikes(likes => likes - 1)
    } else {
      setLikes(likes => likes + 1)
    }
    setLike(like => !like)
  }
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();
  // const prodotti = tuttiProdotti.filter(el => promo.prodotti?.includes(el.id))
  // const prodotti = tuttiProdotti.filter(el => {
  //   let found = false
  //   // @ts-ignore
  //   promo.prodotti.forEach(item => {
  //     if (item.idProdotto == el.id) found = true
  //   })
  //   return found
  // })
  // @ts-ignore
  const prodotti = getProductsFromShop(promo.prodotti, idNegozio)
  const negozio = negozi.find(el => el.id == idNegozio)
  // @ts-ignore
  const { nome, avatar } = negozio

  const productsAccordion = useRef<null | HTMLIonAccordionGroupElement>(null);
  // useEffect(() => {
  //   if (!productsAccordion.current) {
  //     return;
  //   }

  //   productsAccordion.current.value = ['first'];
  // }, []);


  return (
    <div className="__card __post-social __box-shadow-1">
      <div className="__promo-top">
        <IonText>Promozione {nome}</IonText>
      </div>
      {/* <div className="__post-settings">
        <IonButton fill="clear"
          onClick={() =>
            present({
              header: 'Impostazioni post',
              // subHeader: 'Example subheader',
              buttons: [
                {
                  text: 'Condividi',
                  data: {
                    action: 'share',
                  },
                },
                {
                  text: 'Salva',
                  data: {
                    action: 'save',
                  },
                },
                {
                  text: 'Nascondi',
                  data: {
                    action: 'hide',
                  },
                },
                {
                  text: 'Segnala',
                  role: 'destructive',
                  data: {
                    action: 'delete',
                  },
                },
                {
                  text: 'Cancella',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ],
              onDidDismiss: ({ detail }) => setResult(detail),
            })
          }>
          <IonIcon icon={ellipsisVertical} />
        </IonButton>
      </div> */}
      <div className="__promo-header">
        <div className="__promo-avatar">
          <Image src={avatar} />
        </div>
        <div className="__post-container-header">
          {/* <div className="__badge-text">
            <em>Esclusiva {negozio}</em>
          </div> */}
          <IonRouterLink routerLink={'/tabs/promozione/' + slug}>
            <h3 className="__cursive-title">{nomeOfferta}</h3>
          </IonRouterLink>
          <div className="__promo-text">
            <IonText>Dal {dataInizio} al {dataFine}.</IonText>
          </div>
        </div>
      </div>
      <div className="__promozione-media">
        {/* <div className="__post-media-geometry"></div> */}
        <img src={img} alt="" />
      </div>
      {/* <div className="__divider"></div> */}
      {prodotti?.length > 0 &&
        <IonAccordionGroup className="__post-products" ref={productsAccordion} value={nomeOfferta}>
          <IonAccordion value={nomeOfferta}>
            <IonItem slot="header" lines="none">
              <IonLabel>Prodotti</IonLabel>
            </IonItem>
            <div slot="content" className="__post-products-list">

              <div className="__divider"></div>
              <div style={{ position: 'relative' }}>
                <div className="__carousel-container">
                  {/* @ts-ignore */}
                  {prodotti.map(prodotto => (
                    <CardCarousel prodotto={prodotto} key={prodotto.id} />
                  ))}
                  <IonRouterLink className="__card-carousel __card __card-plus __box-shadow-2" routerLink={'/tabs/promozione/' + slug}>
                    <div className="__plus">
                      <IonIcon icon={arrowForwardOutline} />
                      <IonLabel>Vedi tutto</IonLabel>
                    </div>
                  </IonRouterLink>
                </div>
                <div className="__gradient"></div>
              </div>
            </div>
          </IonAccordion>
        </IonAccordionGroup>
      }
      <div className="__divider"></div>
      <div className="__post-actions">
        <div className="__post-data">
          <div className="__post-likes">
            <div className="__likes-avatars">
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/1.jpeg" alt="" />
              </div>
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/2.jpeg" alt="" />
              </div>
              <div className="__likes-avatar">
                <img src="./assets/images/avatars/3.jpeg" alt="" />
              </div>
            </div>
            {/* <IonIcon className="__icon" icon={like ? thumbsUp : thumbsUpOutline} /> */}
            <IonText className="__text">{likes} Mi piace</IonText>
          </div>
          {/* <div className="__post-comments"> */}
          {/* <IonIcon className="__icon" icon={chatboxOutline} /> */}
          {/* <IonText>{commenti} Commenti</IonText> */}
          {/* </div> */}
        </div>

        <div>
          <IonButton fill="clear" onClick={miPiace} >
            <IonIcon class="__icon" icon={like ? heart : heartOutline} color={like ? 'danger' : ''} />
            {/* <IonLabel>Mi piace</IonLabel> */}
          </IonButton>
          <IonButton fill="clear" >
            <IonIcon class="__icon" icon={bookmarkOutline} />
            {/* <IonLabel>Salva</IonLabel> */}
          </IonButton>
          <IonButton fill="clear" >
            <IonIcon class="__icon" icon={shareOutline} />
            {/* <IonLabel>Condividi</IonLabel> */}
          </IonButton>
        </div>
      </div>
    </div>
  );
}

export default Promozione;