import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonRouterLink, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, useIonViewWillEnter } from "@ionic/react";
import { call, callOutline, cartOutline, globeOutline, heart, heartOutline, locateOutline, location, locationOutline, mailOutline, pinOutline, shareOutline, star, starOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import Header from "../../components/Header/Header";
import { marchi, negozi, posts, prodotti, promo as tuttePromo } from "../../data/data";
import ProductsSection from '../../components/sections/ProductsSection'
import { prodotti as tuttiProdotti } from "../../data/data";
import { offerte as tutteOfferte } from "../../data/data";
import './Brand.css'
import Section from "../../components/Section/Section";
import ShopProductsList from "../../components/ShopProductsList/ShopProductsList";
import { useRef, useState } from "react";
import SocialPost from "../../components/SocialPost/SocialPost";
import Promozione from "../../components/Promozione/Promozione";
import ProductsCarousel from "../../components/ProductsCarousel/ProductsCarousel";
import PromoCarousel from "../../components/PromoCarousel/PromoCarousel";
import { scrollToTop } from "../../utils/scrollToTop";
import Container from "../../components/common/Container";
import CoverImage from "../../components/CoverImage/CoverImage";
import Image from "../../components/Image/Image";

interface ShopPageProps
  extends RouteComponentProps<{
    id: string;
  }> {
  modal: any
}
const Brand: React.FC<ShopPageProps> = ({ match, modal }) => {
  const idBrand = parseInt(match.params.id)
  const brand = marchi.find(el => el.id == idBrand)!
  const { cover, nome, avatar, segui } = brand
  const [follow, setFollow] = useState(segui)
  const content = useRef(null)
  useIonViewWillEnter(() => {
    scrollToTop(content, 0)
  })

  // const [shopIcon, setShopIcon] = useState(preferito ? heart : heartOutline)
  // const saveShop = () => {
  //   shopIcon == heartOutline ? setShopIcon(heart) : setShopIcon(heartOutline)
  // }
  // const sezioniNegozio = [
  //   {
  //     id: 1,
  //     nome: 'Offerte'
  //   },
  //   {
  //     id: 2,
  //     nome: 'Social'
  //   },
  //   {
  //     id: 3,
  //     nome: 'Informazioni'
  //   },
  //   {
  //     id: 4,
  //     nome: 'Prodotti acquistati'
  //   }
  // ]
  // const [categoria, setCategoria] = useState<any>(sezioniNegozio[0])
  // // @ts-ignore
  // const handleCategoria = (e) => {
  //   const nomeCategoria = e.detail.value
  //   let nuovaCategoria: { id: number } = { id: 0 }
  //   sezioniNegozio.forEach(el => {
  //     if (el.nome == nomeCategoria) nuovaCategoria = el
  //   })
  //   setCategoria(nuovaCategoria)
  // }
  return (
    <IonPage className="__brand-page">
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{nome}</IonTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={shareOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='__brand-page-content' fullscreen ref={content}>
        <Container>
          <CoverImage img={cover} />
          <div className="__brand-heading __box-shadow-1">
            <div className="__brand-heading-top">
              <div className="__avatar"><Image src={avatar} /></div>
              <IonLabel className="ion-text-wrap __pe-1">
                <h1>{nome}</h1>
                {/* <p>{tipologia}</p> */}
              </IonLabel>
              <IonButton className={"__follow-btn " + (follow ? "__unfollow" : "__follow")} size="small" onClick={() => setFollow(el => !el)}>
                {follow ? 'Segui già' : 'Segui'}
              </IonButton>
            </div>
            {/* <div className="__segments">
            <IonSegment scrollable={true} value={categoria.nome} mode='md' onIonChange={handleCategoria}>
              {sezioniNegozio.map(({ id, nome }) =>
                <IonSegmentButton className='__segment-button' key={id} value={nome}>
                  <IonLabel className='__uppercase'>{nome}</IonLabel>
                </IonSegmentButton>
              )}
            </IonSegment>
          </div> */}
          </div>
          <div className="ion-padding">
            <h4>Negozi della catena</h4>
            {negozi.filter(shop => shop.idMarchio == idBrand).map(({ denominazioneCommerciale, img, indirizzo, id, citta }) => (
              <IonRouterLink routerLink={'/tabs/negozio/' + id} key={id}>
                <div className="__card __box-shadow-1 __brand-shop-card">
                  <div className="__brand-shop-image">
                    <Image src={img} />
                  </div>
                  <div className="__brand-shop-info">
                    <h5>{denominazioneCommerciale}</h5>
                    <p>{indirizzo},</p>
                    <p>{citta}</p>
                  </div>
                </div>
              </IonRouterLink>
            ))}
            {
              // categoria.nome == 'Informazioni' ?
              //   <InformazioniNegozio negozio={negozio} /> :
              //   categoria.nome == 'Social' ?
              //     <SocialNegozio negozio={negozio} /> :
              //     categoria.nome == 'Offerte' ?
              //       <OfferteNegozio negozio={negozio} /> :
              //       categoria.nome == 'Prodotti acquistati' ?
              //         <>
              //           <div className="__no-data">
              //             <p>Non hai ancora acquistato prodotti in questo punto vendita.</p>
              //           </div>
              //         </> :
              //         ''
            }
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
}

export default Brand;

const InformazioniNegozio: React.FC<any> = ({ negozio }) => {
  const { indirizzo, citta, cap, orari } = negozio
  return (
    <>
      <div className="__brand-info">
        <div className="__brand-info-heading">
          <h4>Dove si trova</h4>
        </div>
        <div className="__brand-info-element">
          <IonIcon className="__maps-link" color="primary" icon={locationOutline} />
          <IonRouterLink className="__maps-link">{indirizzo}, {citta} - {cap}</IonRouterLink>
        </div>
        <div className="__brand-info-map">
          <img src="./assets/images/other/1.jpeg" alt="" />
        </div>
      </div>
      <div className="__brand-info">
        <div className="__brand-info-heading">
          <h4>Contatti</h4>
        </div>
        <div className="__brand-info-element">
          <IonIcon icon={globeOutline} />
          <IonRouterLink>www.supermercato.it</IonRouterLink>
        </div>
        <div className="__brand-info-element">
          <IonIcon icon={callOutline} />
          <IonRouterLink>000 00 00 000</IonRouterLink>
        </div>
        <div className="__brand-info-element">
          <IonIcon icon={mailOutline} />
          <IonRouterLink>info@supermercato.it</IonRouterLink>
        </div>
      </div>
      <div className="__brand-info">
        <div className="__brand-info-heading">
          <h4>Orari di apertura</h4>
        </div>
        <div className="__brand-info-element __hours">
          <span>Lunedì</span>
          <span>{orari[0]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Martedì</span>
          <span>{orari[1]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Mercoledì</span>
          <span>{orari[2]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Giovedì</span>
          <span>{orari[3]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Venerdì</span>
          <span>{orari[4]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Sabato</span>
          <span>{orari[5]}</span>
        </div>
        <div className="__brand-info-element __hours">
          <span>Domenica</span>
          <span>{orari[6]}</span>
        </div>
      </div>
    </>
  );
}

const OfferteNegozio: React.FC<any> = ({ negozio }) => {
  // @ts-ignore
  const offerteNegozio = []
  negozio.sconti.forEach((el: any) => {
    offerteNegozio.push({
      ...prodotti.find(prod => prod.id == el.idProdotto),
      sconto: el.sconto
    })
  })
  const promo = tuttePromo.filter(el => el.idNegozio == negozio.id)
  const offerte = tutteOfferte.filter(el => el.idNegozio == negozio.id)

  return (
    <>
      {offerte.length > 0 &&
        <>
          <PromoCarousel promo={offerte} />
          <Promozione promo={offerte[0]} />
        </>
      }
      {promo.length > 0 &&
        < ProductsCarousel promo={promo[0]} />
      }
      {/* @ts-ignore */}
      <ProductsSection title='Offerte in evidenza' list={offerteNegozio.slice(0, 4)} page='negozio' />
      <div className="__all-shop-offers">
        <Section title="Tutte le offerte">
          {/* @ts-ignore */}
          <ShopProductsList prodotti={offerteNegozio.slice(4)} />
        </Section>
      </div>
    </>
  )
}

const SocialNegozio: React.FC<any> = ({ negozio }) => {
  const socialPosts = posts.filter(el => el.utente.idNegozio == negozio.id)
  return (
    <>
      {socialPosts.length > 0 ? socialPosts.map(post =>
        <SocialPost post={post} key={post.id} />
      ) :
        <div className="__no-data">
          <p>Questo utente non ha ancora pubblicato nessun post.</p>
        </div>
      }
    </>
  )
}