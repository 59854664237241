import { negozi, prodotti as tuttiProdotti } from "../data/data"

export const getProductsFromShop = (products: any[], idNegozio: number) => {
  const prodotti: any[] = []
  const sconti = negozi.find(el => el.id == idNegozio)?.sconti

  products.forEach((el: any) => {
    if (sconti?.find(item => item.idProdotto == (typeof(el) == 'number' ? el : el.id))) {
      prodotti.push({
        ...tuttiProdotti.find(prod => prod.id == (typeof(el) == 'number' ? el : el.id)),
        sconto: sconti?.find(item => item.idProdotto == (typeof(el) == 'number' ? el : el.id))?.sconto,
        idNegozio,
      })
    }
  })

  return prodotti
}