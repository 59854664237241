import { createSlice } from "@reduxjs/toolkit";
// import axios from 'axios'

export const chat = createSlice({
  name: 'auth',
  initialState: {
    type: '',
    id: null
  },
  reducers: {
    setChat: (state, action) => {
      state.type = action.payload.type
      state.id = action.payload.id
    }
  }
})

export const { setChat } = chat.actions

export default chat.reducer