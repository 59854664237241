import { getPlatforms, IonBadge, IonContent, IonIcon, IonLabel, IonPage, IonRouterLink, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform, useIonRouter } from "@ionic/react";
import { homeOutline, newspaperOutline, storefrontOutline, peopleOutline, personOutline, home, newspaper, storefront, people, person } from "ionicons/icons";
import { platform } from "os";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import Notifications from "../../components/Notifications/Notifications";
import { scrollToTop } from "../../utils/scrollToTop";
import Brand from "../Brand/Brand";
import Carrello from "../Carrello/Carrello";
import Chat from "../Chat/Chat";
import Creator from "../Creator/Creator";
import Home from "../Home/Home";
import Impostazioni from "../Impostazioni/Impostazioni";
import Negozi from "../Negozi/Negozi";
import Negozio from "../Negozio/Negozio";
import Offerte from "../Offerte/Offerte";
import Punti from "../Premi/Premi";
import Prodotti from "../Prodotti/Prodotti";
import Prodotto from "../Prodotto/Prodotto";
import Promozione from "../Promozione/Promozione";
import Social from "../Social/Social";
import SocialPostPage from "../SocialPost/SocialPost";
import Utente from "../Utente/Utente";

const MainTabs: React.FC<any> = ({ }) => {

  const [location, setLocation] = useState('Seleziona una città')
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (e) {
      const lat = e.coords.latitude
      const lon = e.coords.longitude
      fetch(`https://api.openweathermap.org/geo/1.0/reverse?lat=${lat}&lon=${lon}&appid=a69eda62c5eb25c803f142f0fe40dc59`)
        .then(res => res.json())
        .then(data => {
          setLocation(data[0])
        })
    }, function (e) {
      console.log(e.message);
    })
  }, [])
  const modal = useRef<HTMLIonModalElement>(null)
  const settings = useRef(null)
  const tabs = useRef(null)
  const [activeTab, setActiveTab] = useState<string>('home')

  const locationHook = useLocation()
  const history = useHistory()
  const changeTab = (tab: string) => {
    // @ts-ignore
    tabs.current?.tabBarRef.current.selectTab(tab)
  }
  useEffect(() => {
    // @ts-ignore
    setActiveTab(tabs.current?.ionTabContextState.activeTab)
    // console.log(locationHook.pathname);


    // console.log(locationHook.search);
    if (locationHook.search) {
      const queryParams = new URLSearchParams(locationHook.search)

      if (queryParams.has('modalOpened') ||
        queryParams.has('chatOpened') ||
        queryParams.has('searchOpened') ||
        queryParams.has('settingsOpened')) {
        // queryParams.delete('modalOpened')
        // queryParams.delete('searchOpened')
        // queryParams.delete('notificationsOpened')
        // queryParams.delete('settingsOpened')
        // history.replace({
        //   search: queryParams.toString(),
        // })
        history.go(-1)

      }

      // if (queryParams.has('notificationsOpened')) {
      //   queryParams.delete('notificationsOpened')
      //   history.replace({
      //     search: queryParams.toString(),
      //   })
      //   history.go(-1)
      // }
    }

  }, [locationHook.pathname])
  const homeContent = useRef<any>(null)
  const offersContent = useRef<any>(null)
  const shopsContent = useRef<any>(null)
  const socialContent = useRef<any>(null)
  const userContent = useRef<any>(null)

  const switchContent = () => {
    let el
    switch (locationHook.pathname) {
      case '/tabs/home':
        el = homeContent
        break;
      case '/tabs/offerte':
        el = offersContent
        break;
      case '/tabs/negozi':
        el = shopsContent
        break;
      case '/tabs/social':
        el = socialContent
        break;
      case '/tabs/utente':
        el = userContent
        break;
      default:
        el = homeContent
        break
    }
    return el
  }
  const [content, setContent] = useState<any>(switchContent)
  useEffect(() => {
    setContent(switchContent)
  }, [locationHook.pathname])
  const handleScroll = () => {
    content.current && scrollToTop(content, 500)
  }
  // @ts-ignore
  const auth = useSelector(state => state.auth.value)
  const router = useIonRouter()
  // useEffect(() => {
  //   console.log('prova');

  //   if (!auth) {
  //     router.push('/login')
  //   }
  // }, [])

  return (
    <>
      <IonTabs ref={tabs}>
        <IonRouterOutlet>

          <Redirect exact path='/' to='/tabs/home' />
          <Route>
            <Reload />
          </Route>
          <Route exact path='/tabs/home' render={() => <Home changeTab={changeTab} location={location} modal={modal} content={homeContent} />} />
          <Route exact path='/tabs/offerte' render={() => <Offerte modal={modal} content={offersContent} />} />
          <Route exact path='/tabs/social' render={() => <Social content={socialContent} />} />
          <Route exact path='/tabs/negozi' render={() => <Negozi modal={modal} content={shopsContent} />} />
          <Route exact path='/tabs/utente' render={() => <Utente modal={modal} settings={settings} content={userContent} />} />
          <Route exact path='/tabs/punti' component={Punti} />
          <Route exact path='/tabs/notifiche' component={Notifications} />

          {/* <Route exact path='/tabs/home/offerte' render={(props) => <Offerte {...props} changeTab={changeTab} />} />
        <Route exact path='/tabs/home/negozi' render={(props) => <Negozi {...props} changeTab={changeTab} />} />
        <Route path="/tabs/home/prodotto/:id" render={(props) => <Prodotto {...props} modal={modal} />} />
        <Route path="/tabs/home/prodotti/:slug" render={(props) => <Prodotti {...props} modal={modal} />} />
        <Route path="/tabs/home/promozione/:slug" render={(props) => <Promozione {...props} modal={modal} />} />
        <Route path="/tabs/home/negozio/:id" render={(props) => <Negozio {...props} modal={modal} />} /> */}

          <Route exact path="/tabs/prodotto/:id" render={(props) => <Prodotto {...props} modal={modal} />} />
          <Route exact path="/tabs/prodotto/:id/:negozio" render={(props) => <Prodotto {...props} modal={modal} />} />
          <Route exact path="/tabs/prodotti/:slug" render={(props) => <Prodotti {...props} modal={modal} />} />
          <Route exact path="/tabs/promozione/:slug" render={(props) => <Promozione {...props} modal={modal} />} />
          <Route exact path="/tabs/negozio/:id" render={(props) => <Negozio {...props} modal={modal} />} />
          <Route exact path="/tabs/creator/:id" render={(props) => <Creator {...props} modal={modal} />} />
          <Route exact path="/tabs/brand/:id" render={(props) => <Brand {...props} modal={modal} />} />
          <Route exact path="/tabs/social/:id" render={(props) => <SocialPostPage {...props} />} />
          {/* <Route exact path="/tabs/chat/:tipo/:id" render={(props) => <Chat {...props} />} /> */}

          {/* <Route render={() => (
                <div style={{position: 'absolute', width: '100%', height: '100vh', top: 0, left: 0, background: 'white', zIndex: 9999}}>
                <h2>404 Page not found</h2>
            <IonRouterLink routerLink="/tabs/home">Home</IonRouterLink>
                </div>
          )}/> */}
        </IonRouterOutlet>
        <IonTabBar slot="bottom" className={isPlatform('desktop') ? "__tab-bar-pb " : ''}>
          <IonTabButton tab="home" href="/tabs/home" onClick={handleScroll} className="__no-ripple">
            <IonIcon icon={activeTab == 'home' ? home : homeOutline} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="offerte" href="/tabs/offerte" onClick={handleScroll} className="__no-ripple">
            <IonIcon icon={activeTab == 'offerte' ? newspaper : newspaperOutline} />
            <IonLabel>Offerte</IonLabel>
          </IonTabButton>
          <IonTabButton tab="negozi" href="/tabs/negozi/" onClick={handleScroll} className="__no-ripple">
            <IonIcon icon={activeTab == 'negozi' ? storefront : storefrontOutline} />
            <IonLabel>Negozi</IonLabel>
          </IonTabButton>
          <IonTabButton tab="social" href="/tabs/social" onClick={handleScroll} className="__no-ripple">
            <IonIcon icon={activeTab == 'social' ? people : peopleOutline} />
            <IonLabel>Social</IonLabel>
          </IonTabButton>
          <IonTabButton tab="utente" href="/tabs/utente" onClick={handleScroll} className="__no-ripple">
            {/* <IonBadge color='danger'>5</IonBadge> */}
            <IonIcon icon={activeTab == 'utente' ? person : personOutline} />
            <IonLabel>Utente</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs >
      <Carrello modal={modal} />
      <Impostazioni modal={settings} />
    </>
  );
}

export default MainTabs;

const Reload = () => {
  useEffect(() => {
    window.location.href = '404'
  }, [])
  return <div></div>
}