import { IonIcon, IonLabel } from '@ionic/react';
import { addOutline, arrowForwardOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { negozi, utente } from '../../data/data';
import { formatCost } from '../../utils/formatCost';
import CardCarousel from '../CardCarousel/CardCarousel';
import './ProductsCarousel.css'
import { prodotti as tuttiProdotti } from '../../data/data';
import { getConvenientProducts } from '../../utils/getConvenientProducts';
import { getProductsFromShop } from '../../utils/getProductsFromShop';

const ProductsCarousel: React.FC<any> = ({ promo, preferiti }) => {
  let titolo, idNegozio: number | null
  promo ? { titolo, idNegozio } = promo : idNegozio = null
  // @ts-ignore
  let prodotti = []
  if (idNegozio) {
    prodotti = getProductsFromShop(promo.prodotti, idNegozio)
  } else if (!preferiti) {
    prodotti = getConvenientProducts(promo.prodotti)
  } else {
    prodotti = getConvenientProducts(utente.preferiti.prodotti)
  }

  return (
    <div className="__products-carousel-section __card __box-shadow-1">
      <div className="__post-container-header">
        <div className="__badge-text">
          Promo per te
        </div>
        <h3 className='__cursive-title'>{titolo || 'I tuoi prodotti preferiti'}</h3>
      </div>
      <div className="__carousel-container">
        {/* @ts-ignore */}
        {prodotti.map(prodotto => (
          <CardCarousel prodotto={prodotto} key={prodotto.id} />
        ))}
        {/* {!preferiti &&
          <div className="__card-carousel __card __box-shadow-2">
            <div className="__plus">
              <IonIcon icon={arrowForwardOutline} />
              <IonLabel>Vedi tutto</IonLabel>
            </div>
          </div>
        } */}
        <div className="__card-carousel __offset-right"></div>
      </div>
      <div className="__gradient"></div>
    </div>
  );
}

export default ProductsCarousel;