import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonMenuToggle, IonPage, IonRouterLink, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { call, callOutline, cartOutline, chatbubbleEllipsesOutline, globeOutline, heart, heartOutline, locateOutline, location, locationOutline, mailOutline, pinOutline, star, starOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import Header from "../../components/Header/Header";
import { categorie, creators, negozi, postCreators, posts, prodotti, promo as tuttePromo, utente } from "../../data/data";
import ProductsSection from '../../components/sections/ProductsSection'
import { prodotti as tuttiProdotti } from "../../data/data";
import { offerte as tutteOfferte } from "../../data/data";
import './Creator.css'
import Section from "../../components/Section/Section";
import ShopProductsList from "../../components/ShopProductsList/ShopProductsList";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SocialPost from "../../components/SocialPost/SocialPost";
import Promozione from "../../components/Promozione/Promozione";
import ProductsCarousel from "../../components/ProductsCarousel/ProductsCarousel";
import PromoCarousel from "../../components/PromoCarousel/PromoCarousel";
import ProductsList from "../../components/ProductsList/ProductsList";
import { scrollToTop } from "../../utils/scrollToTop";
import { Link } from "react-router-dom";
import { formatCost } from "../../utils/formatCost";
import Container from "../../components/common/Container";
import { getProductsFromShop } from "../../utils/getProductsFromShop";
import Products from "../../components/sections/ProductsSection";
import { getConvenientProducts } from "../../utils/getConvenientProducts";
import PostCreator from "../../components/PostCreator/PostCreator";
import CoverImage from "../../components/CoverImage/CoverImage";
import Image from "../../components/Image/Image";
import { useDispatch } from "react-redux";
import { setChat } from "../../features/chatSlice";

interface ShopPageProps
  extends RouteComponentProps<{
    id: string;
  }> {
  modal: any
}
const Creator: React.FC<ShopPageProps> = ({ match, modal }) => {
  const idCreator = parseInt(match.params.id)
  const creator = creators.find(el => el.id == idCreator)!
  const { img, tipologia, nome, avatar, segui } = creator
  const [follow, setFollow] = useState(segui)

  // const [shopIcon, setShopIcon] = useState(preferito ? heart : heartOutline)
  // const saveShop = () => {
  //   shopIcon == heartOutline ? setShopIcon(heart) : setShopIcon(heartOutline)
  // }
  const sezioniNegozio = [
    {
      id: 2,
      nome: 'Social'
    },
    {
      id: 3,
      nome: 'Informazioni'
    },
    {
      id: 4,
      nome: 'Prodotti consigliati'
    }
  ]
  const [categoria, setCategoria] = useState<any>(sezioniNegozio[0])
  // @ts-ignore
  const handleCategoria = (e) => {
    const nomeCategoria = e.detail.value
    let nuovaCategoria: { id: number } = { id: 0 }
    sezioniNegozio.forEach(el => {
      if (el.nome == nomeCategoria) nuovaCategoria = el
    })
    setCategoria(nuovaCategoria)
  }
  const content = useRef(null)
  const segments = useRef(null)
  const tabContent = useRef(null)
  useEffect(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
  }, [categoria])
  useIonViewWillEnter(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
    scrollToTop(content, 0)
    // setCategoria(sezioniNegozio[0])
  })
  
  const dispatch = useDispatch()

  const openChat = (type: string, id: number) => {
    dispatch(setChat({ type, id }))
  }
  return (
    <IonPage className="__shop-page">
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{nome}</IonTitle>
          <IonButtons slot='end'>
            {/* <IonButton onClick={saveShop}>
              <IonIcon icon={shopIcon} />
            </IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='__shop-page-content' id='creator-page' ref={content}>
        <Container>
          <CoverImage img={img} />
          <div className="__shop-heading">
            <div className="__shop-heading-top">
              <div className="__avatar"><Image src={avatar} /></div>
              <IonLabel className="ion-text-wrap __pe-1">
                <h1>{nome}</h1>
                <p>{tipologia}</p>
              </IonLabel>
              <div className="__action-buttons">
                <IonMenuToggle menu='chat' autoHide={false} onClick={() => openChat('creator', idCreator)}>
                  <IonButton size="small" className="__chat-btn">
                    <IonIcon slot="icon-only" size="small" icon={chatbubbleEllipsesOutline} />
                  </IonButton>
                </IonMenuToggle>
                <IonButton className={"__follow-btn " + (follow ? "__unfollow" : "__follow")} size="small" onClick={() => setFollow(el => !el)}>
                  {follow ? 'Segui già' : 'Segui'}
                </IonButton>
              </div>
            </div>
          </div>
          <IonItemDivider sticky={true} className='__box-shadow-1' ref={segments}>
            <div className="__segments">
              <IonSegment scrollable={true} value={categoria.nome} mode='md' onIonChange={handleCategoria}>
                {sezioniNegozio.map(({ id, nome }) =>
                  <IonSegmentButton className='__segment-button' key={id} value={nome}>
                    <IonLabel className='__uppercase'>{nome}</IonLabel>
                  </IonSegmentButton>
                )}
              </IonSegment>
            </div>
          </IonItemDivider>
          <div className="__pb-1" ref={tabContent}>
            {
              categoria.nome == 'Informazioni' ?
                <InformazioniCreator creator={creator} /> :
                categoria.nome == 'Social' ?
                  <SocialCreator creator={creator} /> :
                    categoria.nome == 'Prodotti consigliati' ?
                      <ProdottiConsigliati creator={creator} /> :
                      ''
            }
          </div>
          {/* <div className="prova" style={{width: '100%', height, position: 'absolute', background: 'white', zIndex: '100', top, border: '1px solid black'}}></div> */}
        </Container>
      </IonContent>
    </IonPage>
  );
}

export default Creator;

const InformazioniCreator: React.FC<any> = ({ creator }) => {
  const { nome, website, instagram, youtube } = creator

  return (
    <div className="__pt-1">
      <div className="__shop-info">
        <div className="__shop-info-heading">
          <h4>Contatti</h4>
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={globeOutline} />
          {website ?
            <a target="_blank" href={website}>Sito Web</a> :
            <span>Nessun sito web</span>
          }
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={globeOutline} />
          {instagram ?
            <a target="_blank" href={instagram}>Instagram</a> :
            <span>Nessun account Instagram</span>
          }
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={globeOutline} />
          {youtube ?
            <a target="_blank" href={youtube}>YouTube</a> :
            <span>Nessun account YouTube</span>
          }
        </div>
      </div>
    </div>
  );
}

const SocialCreator: React.FC<any> = ({ creator }) => {
  const socialPosts = posts.filter(el => el.utente.idCreator == creator.id)
  return (
    <div className="__pt-1">
      {socialPosts.length > 0 ? socialPosts.map(post =>
        <SocialPost post={post} key={post.id} page={'creator-page'} />
      ) :
        <div className="__no-data">
          <p>Questo utente non ha ancora pubblicato nessun post.</p>
        </div>
      }
    </div>
  )
}

const ProdottiConsigliati: React.FC<any> = ({ creator }) => {
  const prodotti = getConvenientProducts(creator.prodotti)
  if (prodotti.length > 0) {
    return (
      <div className="__pt-1">
        <ProductsList prodotti={prodotti} />
      </div>
    )
  }
  return (
    <div className="__pt-1">
      <div className="__no-data">
        <p>Non hai ancora acquistato prodotti in questo punto vendita.</p>
      </div >
    </div>
  )
}