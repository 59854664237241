import { getPlatforms, IonBackButton, IonButton, IonButtons, IonChip, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonMenu, IonPage, IonTitle, IonToolbar, isPlatform, useIonViewWillEnter } from "@ionic/react";
import { arrowBack, checkmarkDoneOutline, close, sendOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import Image from "../../components/Image/Image";
import { creators, negozi, utente } from "../../data/data";
import { scrollToTop } from "../../utils/scrollToTop";
import { Messaggi } from "../Utente/Utente";
import './Chat.css'
import { setChat as setChatNull } from "../../features/chatSlice";

const Chat: React.FC<any> = () => {
  // const userType = match.params.tipo
  // @ts-ignore
  const userType = useSelector(state => state.chatReducer.type)
  // const id = parseInt(match.params.id)
  // @ts-ignore
  const id = useSelector(state => state.chatReducer.id)
  // const [userType, setUserType] = useState('')
  // const [id, setId] = useState(null)
  console.log(userType, id);

  const [user, setUser] = useState({})
  const [chat, setChat] = useState({})

  useEffect(() => {

    if (userType == 'negozio') {
      console.log(negozi.find(el => el.id == id));

      // @ts-ignore
      setUser(negozi.find(el => el.id == id))
      // @ts-ignore
      setChat(utente.messaggi.find(el => el.utente.idNegozio == id) ?? {})
    }
    if (userType == 'creator') {
      // @ts-ignore
      setUser(creators.find(el => el.id == id))
      // @ts-ignore
      setChat(utente.messaggi.find(el => el.utente.idCreator == id) ?? {})
    }
  }, [userType, id])
  // const idChat = parseInt(match.params.id)
  // const chat = utente.messaggi.find(el => el.id == idChat)
  // @ts-ignore
  // const utenteChat = chat.utente.idNegozio
  //   // @ts-ignore
  //   ? negozi.find(shop => shop.id == chat.utente.idNegozio)
  //   // @ts-ignore
  //   : chat.utente.idCreator
  //     // @ts-ignore
  //     ? creators.find(creator => creator.id == chat.utente.idCreator)
  //     : ''
  console.log(chat);

  const [keyboardOffset, setKeyboardOffset] = useState(0)

  const content = useRef(null)
  const footer = useRef(null)

  const [contentHeight, setContentHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)


  const waitForElement = (sel: any, cb: any) => {
    const el = sel;
    if (!el || !(el.getBoundingClientRect().height > 0)) {
      requestAnimationFrame(() => waitForElement(sel, cb));
    } else {
      return cb(el);
    }
  }

  const setKeyboardOpen = (keyboardHeight: number) => {
    setKeyboardOffset(keyboardHeight)

  }

  const setKeyboardClosed = () => {
    console.log(keyboardOffset);

  }

  const [keyboardState, setKeyboardState] = useState(
    {
      state: false,
      offset: 0
    }
  )

  useEffect(() => {
    console.log('prova');

    waitForElement(content.current, (el: any) => {
      const pageElHeight = el.getBoundingClientRect().height
      setPageHeight(pageElHeight)
      // console.log(pageElHeight);
      waitForElement(footer.current, (el: any) => {
        const footerElHeight = el.getBoundingClientRect().height
        setFooterHeight(footerElHeight)
        // setContentHeight(pageElHeight - footerElHeight)
      })
    })

    window.addEventListener('ionKeyboardDidShow', e => {
      // @ts-ignore
      const { keyboardHeight } = e.detail
      // @ts-ignore
      // console.log(keyboardHeight - keyboardOffset);
      // @ts-ignore
      // console.log(content.current.getBoundingClientRect().height);
      // setContentHeight(el => el - keyboardHeight)
      setKeyboardState(
        {
          state: true,
          offset: keyboardHeight
        }
      )


    })

    window.addEventListener('ionKeyboardDidHide', () => {
      console.log('closed');
      // setKeyboardClosed()
      setKeyboardState(
        {
          state: false,
          offset: 0
        }
      )
      // setKeyboardOffset(0)
      // console.log(keyboardOffset, footerHeight);

      // setContentHeight(el => el + keyboardOffset)
    });

    console.log(getPlatforms())
  }, [])

  useEffect(() => {
    if (keyboardState.state && isPlatform('ios') && isPlatform('mobile')) {
      console.log('aperta');
      console.log(keyboardState.offset);

      const offset = keyboardState.offset - 24
      console.log(offset - keyboardOffset)

      if (isPlatform('ios') && isPlatform('mobile')) {
        setKeyboardOffset(offset)
      }
      // @ts-ignore
      console.log(getComputedStyle(content.current).getPropertyValue('--keyboard-offset'))
      // @ts-ignore
      content.current.style.setProperty('--keyboard-offset', `${offset}px`)
    }
    if (!keyboardState.state) {
      console.log('chiusa');

      setKeyboardOffset(0)
      // @ts-ignore
      content.current.style.setProperty('--keyboard-offset', '0px')

    }
  }, [keyboardState.state, keyboardState.offset])

  // useIonViewWillEnter(() => {
  //   waitForElement(footer.current, (el: any) => {
  //     if (el) {
  //       // @ts-ignore
  //       content.current.scrollToBottom(0)
  //     }
  //   })
  // })

  useEffect(() => {
    if (footerHeight > 0) {
      // @ts-ignore
      content.current.scrollToBottom(0)
    }
  }, [footerHeight, id])

  useEffect(() => {
    waitForElement(footer.current, (el: any) => {
      // @ts-ignore
      content.current.scrollToBottom(0)
      const footerElHeight = el.getBoundingClientRect().height
      setFooterHeight(footerElHeight)

    })

  }, [chat])

  useEffect(() => {
    // @ts-ignore
    if (user.id) {
      openChat()
    }
  }, [user])

  const dispatch = useDispatch()


  const location = useLocation()
  const nav = useHistory()
  const menu = useRef(null)
  const backButton = () => {
    dispatch(setChatNull({ type: '', id: null }))
  }
  const closeChatButton = () => {
    if (!id) {
      closeChat()
      nav.go(-1)
    }
  }
  const closeChat = () => {
    // @ts-ignore
    menu.current.close()
  }
  let opened = false
  const opening = () => {
    if (!opened && !location.search.includes('chatOpened=true')) {
      opened = true
      nav.push(nav.location.pathname + '?chatOpened=true')
    }
  }
  useEffect(() => {
    if (!location.search.includes('chatOpened=true')) {
      closeChat()
    }
  }, [location]);

  const openChat = () => {
    // @ts-ignore
    menu.current.open()
  }

  


  return (
    <IonMenu swipeGesture={false} onIonWillOpen={opening} onIonDidClose={() => dispatch(setChatNull({ type: '', id: null }))} menuId='chat' contentId='main' side="end" type="overlay" maxEdgeStart={0} ref={menu} className="__chat-page" style={{ touchAction: 'none' }}>
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            {id &&
              <IonButton onClick={() => backButton()}>
                <IonIcon icon={arrowBack} />
              </IonButton>
            }
            {!id && window.innerWidth <= 991 &&
              <IonButton onClick={() => closeChatButton()}>
                <IonIcon icon={close} />
              </IonButton>
            }
          </IonButtons>
          {/* @ts-ignore */}
          <IonTitle>{id ? user.denominazioneCommerciale ?? user.nome : 'Messaggi'}</IonTitle>
          <IonButtons slot='end' className="__notifications-badge">
            <IonButton>
              <IonIcon icon='' />
            </IonButton>
            <IonButton>
              <IonIcon icon='' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={content}>
        {id ?
          <>
            <div className="__chat">
              {/* @ts-ignore */}
              {chat?.giorni?.length ?
                // @ts-ignore
                chat.giorni.map(({ giorno, messaggi }) =>
                  <div key={giorno} className="__day-chat ion-padding">
                    <IonChip color={'dark'} disabled>{giorno}</IonChip>
                    {/* @ts-ignore */}
                    {messaggi.map(({ mittente, ora, messaggio, letto }, i) =>
                      <div key={i} className={"__message " + mittente}>
                        {mittente == 'user' &&
                          <div className="__avatar">
                            {/* @ts-ignore */}
                            <Image src={user.avatar} />
                          </div>
                        }
                        <div className="__bubble">
                          <IonLabel>
                            <span>{messaggio}</span>
                            <p>{ora} {mittente == 'self' && <IonIcon color={letto ? 'primary' : ''} icon={checkmarkDoneOutline} />}</p>
                          </IonLabel>
                        </div>
                      </div>
                    )}
                  </div>
                )
                :
                <div className="__no-data" style={{ width: '100%', color: 'grey' }}>Ancora nessun messaggio.</div>
              }
            </div>
            <div style={{ height: `calc(${footerHeight}px + ${isPlatform('mobile') && isPlatform('ios') && isPlatform('pwa') ? '1.5rem' : '0px'})` }} />
            {/* <div className="__chat-content" > */}

            {/* <IonFooter > */}
            {/* </div> */}
            <IonToolbar color={'primary'} style={{ transform: `translateY(-${keyboardOffset}px)`, bottom: isPlatform('ios') && isPlatform('pwa') && isPlatform('mobile') ? '1.5rem' : 0 }} slot='fixed' ref={footer} onTouchStart={(e) => e.preventDefault()}>
              <IonItem color={'primary'} lines="none">
                <IonInput disabled color={'light'} placeholder="Scrivi un messaggio..." />
                <IonButton fill="clear" slot="end">
                  <IonIcon color="light" slot="icon-only" icon={sendOutline} />
                </IonButton>
              </IonItem>
            </IonToolbar>
            {/* </IonFooter> */}
            {isPlatform('mobile') && isPlatform('ios') && isPlatform('pwa') &&
              <div style={{ position: 'fixed', bottom: 0, height: '1.5rem', background: 'var(--ion-color-primary)', width: '100%' }} />
            }
          </> :
          <Messaggi />
        }
      </IonContent>

    </IonMenu>
  );
}

export default Chat;