// mockaroo ruby formula
// products = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]
// arr = []
// products.each do |n| arr.push(n) unless random(0, 1) == 1 end
// this = arr



export const offerte = [
  {
    id: 1,
    nomeOfferta: 'Scegli tu grandi marche',
    descrizione: '',
    negozio: 'Coop',
    catena: 'Coop',
    idNegozio: 1,
    idBrand: 1,
    slug: 'scegli-tu-grandi-marche',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '01/11/2022',
    dataFine: '19/11/2022',
    logo: './assets/images/logos/coop.png',
    categorie: [1, 2, 3, 4],
    prodotti: [5, 6, 7, 8, 9, 2, 3, 1]
  },
  {
    id: 2,
    nomeOfferta: 'Dolcetto o Scherzetto?',
    descrizione: 'Non è uno scherzetto: fino al 26 ottobre per te tante offerte da paura.<br/>Dolci, zucche e travestimenti da brividi... Halloween è alle porte!<br/>Lasciati ispirare dalle nostre idee per organizzare una festa o una cena mostruosa.<br/>Ti aspettiamo nei negozi Famila.',
    negozio: 'Famila',
    catena: 'Famila',
    idNegozio: 6,
    idBrand: 6,
    slug: 'dolcetto-o-scherzetto',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '20/10/2022',
    dataFine: '26/10/2022',
    logo: './assets/images/logos/famila.png',
    avatar: './assets/images/social/logo-7.png',
    categorie: [1],
    likes: 53,
    like: false,
    prodotti: [1, 2, 3, 4]
  },
  {
    id: 3,
    nomeOfferta: 'Le offerte di autunno',
    descrizione: '',
    negozio: 'Coop',
    catena: 'Coop',
    idNegozio: 1,
    idBrand: 1,
    slug: 'offerte-del-mese',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '10/11/2022',
    dataFine: '25/11/2022',
    logo: './assets/images/logos/coop.png',
    categorie: [1],
    prodotti: [1, 2, 3, 4]
  },
  {
    id: 4,
    nomeOfferta: 'Dolcetto o Scherzetto?',
    descrizione: 'Non è uno scherzetto: fino al 26 ottobre per te tante offerte da paura.<br/>Dolci, zucche e travestimenti da brividi... Halloween è alle porte!<br/>Lasciati ispirare dalle nostre idee per organizzare una festa o una cena mostruosa.<br/>Ti aspettiamo nei negozi Famila.',
    negozio: 'Famila',
    catena: 'Famila',
    idNegozio: 7,
    idBrand: 6,
    slug: 'dolcetto-o-scherzetto',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '20/10/2022',
    dataFine: '26/10/2022',
    logo: './assets/images/logos/famila.png',
    avatar: './assets/images/social/logo-7.png',
    categorie: [1],
    likes: 53,
    like: false,
    prodotti: [1, 2, 3, 4]
  },
  {
    id: 5,
    nomeOfferta: 'Lorem ipsum dolor',
    descrizione: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias accusantium laboriosam et inventore delectus quod nulla. Delectus, odit. Quisquam debitis adipisci explicabo doloremque quas quasi consequuntur soluta dolores alias ipsa, neque nihil veritatis nobis delectus placeat provident? Corporis, atque suscipit sequi rem voluptas repellat vitae? Asperiores, odit mollitia. Magnam optio enim sit doloremque.',
    negozio: 'Dok',
    catena: 'Dok',
    idNegozio: 8,
    idBrand: 7,
    slug: 'lorem-ipsum-dolor',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '20/11/2022',
    dataFine: '26/11/2022',
    logo: './assets/images/logos/dok.png',
    avatar: './assets/images/social/logo-9.png',
    categorie: [1],
    likes: 53,
    like: false,
    prodotti: [1, 2, 3, 4]
  },
  {
    id: 6,
    nomeOfferta: 'Scontissimi Eurospin',
    descrizione: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias accusantium laboriosam et inventore delectus quod nulla. Delectus, odit. Quisquam debitis adipisci explicabo doloremque quas quasi consequuntur soluta dolores alias ipsa, neque nihil veritatis nobis delectus placeat provident? Corporis, atque suscipit sequi rem voluptas repellat vitae? Asperiores, odit mollitia. Magnam optio enim sit doloremque.',
    negozio: 'Eurospin',
    catena: 'Eurospin',
    idNegozio: 4,
    idBrand: 4,
    slug: 'scontissimi-eurospin',
    img: './assets/images/placeholders/promo.png',
    dataInizio: '01/12/2022',
    dataFine: '11/12/2022',
    logo: './assets/images/logos/eurospin.png',
    avatar: './assets/images/social/logo-5.png',
    // categorie: [1],
    likes: 37,
    like: false,
    prodotti: [26, 27, 23, 18, 24, 25]
  },
]

export const promo = [
  {
    id: 1,
    titolo: 'Selezionati per te',
    prodotti: [10, 9, 8, 5, 4, 23, 30, 16],
    idNegozio: null,
  },
  {
    id: 2,
    titolo: 'Prezzi pazzi Coop',
    prodotti: [3, 4, 7, 9, 10],
    idNegozio: 1,
  },
  {
    id: 3,
    titolo: 'Prodotti freschi',
    prodotti: [6, 7, 8, 5, 4],
    idNegozio: null,
  },
  {
    id: 4,
    titolo: 'Da bere al Famila',
    prodotti: [31, 32, 33, 30, 6],
    idNegozio: 7,
  },
]

export const prodotti = [
  {
    id: 1,
    nome: 'Crackers Salati Granpavesi 560g',
    marca: 'Pavesi',
    costo: 1.99,
    score: 'd',
    img: "./assets/images/products/5.png",
    descrizione: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    categorie: [1],
    sottoCategorie: [11],
  },
  {
    id: 2,
    nome: 'Gocciole Al Cioccolato Pacco Famiglia Pavesi 1Kg',
    marca: 'Pavesi',
    costo: 4.49,
    score: 'd',
    img: "./assets/images/products/6.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [7],
  },
  {
    id: 3,
    nome: 'Spinacine Di Pollo E Tacchino Con Spinaci Aia 220g',
    marca: 'Aia',
    costo: 2.39,
    score: 'd',
    img: "./assets/images/products/7.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [2],
  },
  {
    id: 4,
    nome: 'Prosciutto Cotto Beretta 120g',
    marca: 'Beretta',
    costo: 3.99,
    score: 'd',
    img: "./assets/images/products/8.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [5],
  },
  {
    id: 5,
    nome: 'Mezze Penne Rigate Barilla 500g N.70',
    marca: 'Barilla',
    costo: 1.19,
    score: 'a',
    img: "./assets/images/products/1.png",
    descrizione: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    categorie: [1],
    sottoCategorie: [9],
  },
  {
    id: 6,
    nome: 'Acqua Sant\'Anna naturale 1.5Lt.x6',
    marca: 'Sant\'Anna',
    costo: 2.94,
    score: 'a',
    img: "./assets/images/products/2.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [1],
  },
  {
    id: 7,
    nome: 'Sugo al Basilico Barilla 400g',
    marca: 'Barilla',
    costo: 1.79,
    score: 'c',
    img: "./assets/images/products/3.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [6],
  },
  {
    id: 8,
    nome: 'Shampoo Antiforfora Menthol Fresh Head & Shoulders 250ml',
    marca: 'Head & Shoulders',
    costo: 3.49,
    img: "./assets/images/products/4.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [1],
  },
  {
    id: 9,
    nome: 'Scottex Quanto Basta 2 pz',
    marca: 'Scottex',
    costo: 3.99,
    img: "./assets/images/products/9.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [6],
  },
  {
    id: 10,
    nome: 'Nutella Ferrero 450g',
    marca: 'Ferrero',
    costo: 3.55,
    score: 'e',
    img: "./assets/images/products/10.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [7],
  },
  {
    id: 11,
    nome: 'Carrefour Bio Drink Avena 1 L',
    marca: 'Carrefour',
    costo: 1.69,
    score: 'b',
    img: "./assets/images/products/11.png",
    descrizione: 'Il Drink Avena Carrefour Bio è una gustosa alternativa vegetale per tutti coloro che assumono solo alimenti vegetali, che sono intolleranti al lattosio e alle proteine del latte. Si può gustare caldo o freddo, in ogni momento della giornata. È ottimo per la prima colazione, con i cereali oppure aggiunto al caffè o al tè. In cucina è eccellente per preparare piatti a base vegetale, gustosi dessert o deliziosi frullati. Per la produzione di questa e delle altre bevande Carrefour Bio viene utilizzata acqua di sorgente.',
    categorie: [2],
    sottoCategorie: [2],
  },
  {
    id: 12,
    nome: 'Carrefour Bio Biscotti al farro con gocce di cioccolato 300 g',
    marca: 'Carrefour',
    costo: 2.50,
    score: 'c',
    img: "./assets/images/products/12.png",
    descrizione: 'L\'Agricoltura biologica impiega solo tecniche di coltivazione e allevamento compatibili con l\'ambiente, che escludono l\'utilizzo di sostanze chimiche di sintesi, pesticidi, diserbanti e organismi geneticamente modificati. Da qui il nostro impegno nel realizzare Carrefour Bio, una linea di prodotti biologici ampia e di qualità, per la salvaguardia dell\'ambiente e la tutela del consumatore.',
    categorie: [1],
    sottoCategorie: [7],
  },
  {
    id: 13,
    nome: 'Selex Crostatine con Crema al Cacao 6x40 g',
    marca: 'Selex',
    costo: 1.33,
    score: 'e',
    img: "./assets/images/products/13.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [7],
  },
  {
    id: 14,
    nome: 'Banane Dole 900 g',
    marca: 'Dole',
    costo: 1.79,
    score: 'a',
    img: "./assets/images/products/14.png",
    descrizione: 'Prodotto dolciario da forno - Crostatina con crema al cacao',
    categorie: [1],
    sottoCategorie: [1],
  },
  {
    id: 15,
    nome: 'Ariete Planetaria Multifunzione 7 L',
    marca: 'Ariete',
    costo: 129.99,
    img: "./assets/images/products/15.png",
    descrizione: 'Planetaria multifunzione: impasta, amalgama, crea. la ciotola ha capacità 7 litri. Questa planetaria permette di preparare impasti di ogni tipo grazie agli accessori in dotazione, ad esempio, sarà facilissimo montare uova o panna, oppure impastare le preparazioni da panificare, come pane e pizza con il gancio per impasti più duri. La planetaria è dotata anche di un comodo frullatore da 1,5 litri di capacità che si posiziona sopra il braccio dell’impastatrice e che è in grado di frullare frutta e verdura, per portare in tavola frullati di frutta, smoothie, ma anche vellutate e bevande energizzanti. Ha 6 velocità e la funzione Pulse per dare un colpo a massima velocità al frullatore e tritare anche gli ingredienti più duri come il ghiaccio per i tuoi cocktail. Potenza 1200 W.',
    categorie: [6],
    sottoCategorie: [1],
  },
  {
    id: 16,
    nome: 'Salame Milano 150 g',
    marca: 'La Bottega del Gusto',
    costo: 1.59,
    score: 'd',
    img: "./assets/images/products/16.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [5],
  },
  {
    id: 17,
    nome: 'Fiocchi di Latte 200 g',
    marca: 'Land',
    costo: 0.99,
    score: 'c',
    img: "./assets/images/products/17.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [1],
    sottoCategorie: [4],
  },
  {
    id: 18,
    nome: 'Liquido lavatrice marsiglia e felce, 50 lavaggi 4 l',
    marca: 'Dexal',
    costo: 4.39,
    img: "./assets/images/products/18.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [1],
  },
  {
    id: 19,
    nome: 'SMART TV LED HD 32" MAJESTIC ST32VD',
    marca: 'Majestic',
    costo: 179.99,
    img: "./assets/images/products/19.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [6],
    sottoCategorie: [2],
  },
  {
    id: 20,
    nome: 'Cola zero',
    marca: 'Blues',
    costo: 0.59,
    score: 'b',
    img: "./assets/images/products/20.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [2],
  },
  {
    id: 21,
    nome: 'Pinot Chardonnay Spumante Brut',
    marca: 'Corte viola',
    costo: 2.39,
    score: 'c',
    img: "./assets/images/products/21.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [6],
  },
  {
    id: 22,
    nome: 'Merlot Veneto IGT',
    marca: 'Corte viola',
    costo: 1.89,
    score: 'b',
    img: "./assets/images/products/22.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [3],
  },
  {
    id: 23,
    nome: 'Acqua profumata per il corpo',
    marca: 'Fior di Magnolia',
    costo: 3.69,
    img: "./assets/images/products/23.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [3],
  },
  {
    id: 24,
    nome: 'Assorbente ultra ali lungo, 24 pz',
    marca: 'Fior di Magnolia',
    costo: 1.75,
    img: "./assets/images/products/24.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [6],
  },
  {
    id: 25,
    nome: 'Salviette struccanti 100% cotone, 25 pz',
    marca: 'Fior di Magnolia',
    costo: 1.39,
    img: "./assets/images/products/25.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [2],
  },
  {
    id: 26,
    nome: 'Detergente spray vetri e specchi 1 l',
    marca: 'Dexal',
    costo: 1.49,
    img: "./assets/images/products/26.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [2],
  },
  {
    id: 27,
    nome: 'Tabs per lavastoviglie profumo limone, 40 pz',
    marca: 'Dexal',
    costo: 3.49,
    img: "./assets/images/products/27.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [3],
  },
  {
    id: 28,
    nome: 'Medion Lavatrice 7kg 1200 giri MD37435',
    marca: 'Medion',
    costo: 239.99,
    img: "./assets/images/products/28.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [6],
    sottoCategorie: [3],
  },
  {
    id: 29,
    nome: 'HP Stampante deskjet 2710 all-in-one printer',
    marca: 'HP',
    costo: 59.99,
    img: "./assets/images/products/29.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [6],
    sottoCategorie: [4],
  },
  {
    id: 30,
    nome: 'Peroni Cruda Non Pastorizzata 50 cl',
    marca: 'Peroni',
    costo: 1.43,
    score: 'b',
    img: "./assets/images/products/30.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [4],
  },
  {
    id: 31,
    nome: 'Peroni Gran Riserva doppio malto Bock 50 cl',
    marca: 'Peroni',
    costo: 1.55,
    score: 'b',
    img: "./assets/images/products/31.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [4],
  },
  {
    id: 32,
    nome: 'Selex Succo e polpa di mela e banana 1 l',
    marca: 'Selex',
    costo: 1.20,
    score: 'b',
    img: "./assets/images/products/32.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [2],
  },
  {
    id: 33,
    nome: 'Acqua Ferrarelle 1,5 l',
    marca: 'Ferrarelle',
    costo: 0.44,
    score: 'a',
    img: "./assets/images/products/33.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [2],
    sottoCategorie: [1],
  },
  {
    id: 34,
    nome: 'L\'Angelica Olea Naturae Bath&Shower Gel Olio di Macadamia Bio Nutriente 450 ml',
    marca: 'L\'Angelica',
    costo: 1.79,
    img: "./assets/images/products/34.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [3],
  },
  {
    id: 35,
    nome: 'Selex Spugna da Bagno Serena',
    marca: 'Selex',
    costo: 0.50,
    img: "./assets/images/products/35.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [9],
  },
  {
    id: 36,
    nome: 'Dove Men+Care Clean Comfort Anti-Perspirant 150 ml',
    marca: 'Dove',
    costo: 2.80,
    img: "./assets/images/products/36.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [3],
  },
  {
    id: 37,
    nome: 'Scottonelle Maxi Carta Igienica 4 pz',
    marca: 'Scottex',
    costo: 3.29,
    img: "./assets/images/products/37.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [3],
    sottoCategorie: [8],
  },
  {
    id: 38,
    nome: 'Selex Pattumiera a Pedale Panna 16 L',
    marca: 'Selex',
    costo: 9.50,
    img: "./assets/images/products/38.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [4],
  },
  {
    id: 39,
    nome: 'Ricarica Dry Swiffer x32',
    marca: 'Swiffer',
    costo: 6.99,
    img: "./assets/images/products/39.png",
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    categorie: [4],
    sottoCategorie: [4],
  },
]

export const categorie = [
  {
    id: 1,
    nome: 'alimentari',
    titolo: 'Alimentari',
    slug: 'alimentari',
    img: './assets/images/categories/cibo.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Frutta e verdura' },
      { id: 2, nome: 'Carne' },
      { id: 3, nome: 'Pesce e salmone affumicato' },
      { id: 4, nome: 'Latticini' },
      { id: 5, nome: 'Salumi e formaggi' },
      { id: 6, nome: 'Piatti pronti, sughi e pasta fresca' },
      { id: 7, nome: 'Colazione e dolci' },
      { id: 8, nome: 'Caffè, tea e zucchero' },
      { id: 9, nome: 'Pasta, riso e farine' },
      { id: 10, nome: 'Conserve' },
      { id: 11, nome: 'Pane, sostitutivi e snack salati' },
      { id: 12, nome: 'Surgelati e gelati' }
    ]
  },
  {
    id: 2,
    nome: 'bevande',
    titolo: 'Bevande',
    slug: 'bevande',
    img: './assets/images/categories/bevande.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Acqua' },
      { id: 2, nome: 'Bibite analcoliche' },
      { id: 3, nome: 'Vino' },
      { id: 4, nome: 'Birre' },
      { id: 5, nome: 'Liquori' },
      { id: 6, nome: 'Spumanti e champagne' }
    ]
  },
  {
    id: 3,
    nome: 'cura del corpo',
    titolo: 'Cura del corpo',
    slug: 'cura-del-corpo',
    img: './assets/images/categories/corpo.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Capelli' },
      { id: 2, nome: 'Viso' },
      { id: 3, nome: 'Corpo' },
      { id: 4, nome: 'Igiene Orale' },
      { id: 5, nome: 'Rasatura e depilazione' },
      { id: 6, nome: 'Igiene intima e assorbenza' },
      { id: 7, nome: 'Cotone e medicazione' },
      { id: 8, nome: 'Carta igienica e fazzoletti' },
      { id: 9, nome: 'Accessori igiene personale' },
    ]
    // img: 'https://via.placeholder.com/300x240?text=Category'
  },
  {
    id: 4,
    nome: 'per la casa',
    titolo: 'Per la casa',
    slug: 'per-la-casa',
    img: './assets/images/categories/casa.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Bucato' },
      { id: 2, nome: 'Detergenza' },
      { id: 3, nome: 'Detersivi per stoviglie' },
      { id: 4, nome: 'Accessori per la casa' },
      { id: 5, nome: 'Alluminio e pellicole' },
      { id: 6, nome: 'Carta e monouso' },
      { id: 7, nome: 'Deodoranti ambiente' },
    ]
  },
  {
    id: 5,
    nome: 'pets',
    titolo: 'Pets',
    slug: 'pets',
    img: './assets/images/categories/animali.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Cane' },
      { id: 2, nome: 'Gatto' },
      { id: 3, nome: 'Lettiere' }
    ]
  },
  {
    id: 6,
    nome: 'elettronica',
    titolo: 'Elettronica',
    slug: 'elettronica',
    img: './assets/images/categories/elettronica.jpg',
    sottoCategorie: [
      { id: 1, nome: 'Cucina' },
      { id: 2, nome: 'Intrattenimento' },
      { id: 3, nome: 'Elettrodomestici' },
      { id: 4, nome: 'Ufficio' },
      // { id: 5, nome: 'Amet' }
    ]
  },
]

export const livelli = [
  {
    id: 1,
    nome: 'Novizio del Risparmio',
    puntiMin: 0,
    puntiMax: 50,
    img: './assets/images/points/1.png'
  },
  {
    id: 2,
    nome: 'Apprendista risparmiatore',
    puntiMin: 50,
    puntiMax: 100,
    img: './assets/images/points/2.png'
  },
  {
    id: 3,
    nome: 'Novizio del Risparmio',
    puntiMin: 100,
    puntiMax: 200,
    img: './assets/images/points/3.png'
  },
  {
    id: 4,
    nome: 'Apprendista risparmiatore',
    puntiMin: 200,
    puntiMax: 300,
    img: './assets/images/points/4.png'
  },
  {
    id: 5,
    nome: 'Asso del Risparmio',
    puntiMin: 300,
    puntiMax: 400,
    img: './assets/images/points/5.png'
  },
]

export const premi = [
  {
    id: 1,
    nome: 'Innoliving INN-791 fornetto con tostapane 25 L 1500 W',
    marca: 'Innoliving',
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    punti: 99,
    img: './assets/images/premi/1.png',
    categorie: [],
    negozi: [2, 3]
  },
  {
    id: 2,
    nome: 'LG 49UN7100 124,5 cm (49") 4K Ultra HD Smart TV Wi-Fi',
    marca: 'LG',
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    punti: 499,
    img: './assets/images/premi/2.png',
    categorie: [],
    negozi: [5, 4]
  },
  {
    id: 3,
    nome: 'BaByliss Asciugacapelli Titanium Gold Dryer 5513TE',
    marca: 'BaByliss',
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    punti: 19,
    img: './assets/images/premi/3.png',
    categorie: [],
    negozi: [1, 3, 4]
  },
  {
    id: 4,
    nome: 'Philips 3000 series Rifinitore per barba con impostazioni di precisione da 1mm',
    marca: 'Philips',
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    punti: 10,
    img: './assets/images/premi/4.png',
    categorie: [],
    negozi: [4]
  },
  {
    id: 5,
    nome: 'Ariete Planetaria Multifunzione 7 L',
    marca: 'Ariete',
    descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
    punti: 130,
    img: './assets/images/products/15.png',
    categorie: [],
    negozi: [4]
  },
  // {
  //   id: 5,
  //   nome: '',
  //   marca: '',
  //   descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
  //   punti: 376,
  //   img: './assets/images/premi/1.png',
  //   categorie: [],
  //   negozi: []
  // },
  // {
  //   id: 6,
  //   nome: '',
  //   marca: '',
  //   descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
  //   punti: 12,
  //   img: './assets/images/premi/1.png',
  //   categorie: [],
  //   negozi: []
  // },
  // {
  //   id: 7,
  //   nome: '',
  //   marca: '',
  //   descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
  //   punti: 1200,
  //   img: './assets/images/premi/1.png',
  //   categorie: [],
  //   negozi: []
  // },
  // {
  //   id: 8,
  //   nome: '',
  //   marca: '',
  //   descrizione: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, nostrum.',
  //   punti: 78,
  //   img: './assets/images/premi/1.png',
  //   categorie: [],
  //   negozi: []
  // },
]

export const utente = {
  id: 1,
  codiceUtente: '9865 34567 8000',
  nome: 'Mario',
  cognome: 'Rossi',
  avatar: './assets/images/avatars/1.jpeg',
  cover: './assets/images/other/2.jpg',
  eta: 34,
  email: 'mario.rossi@gmail.com',
  tel: '3273435367',
  citta: 'Milano',
  carrello: [],
  preferiti: {
    prodotti: [3, 6, 2, 5],
    negozi: [7, 8, 1, 6, 3, 4, 5]
  },
  punti: {
    negozi: [
      {
        idNegozio: 1,
        punti: 10
      },
    ]
  },
  storico: [
    {
      "idTransazione": "LHpF4qkVd9",
      "data": "06/10/2022",
      "idNegozio": 7,
      "prodotti": [
        1,
        2,
        3,
        4,
        10,
        13,
      ],
      "risparmio": 14
    }, {
      "idTransazione": "PTQeDM9CpC",
      "data": "26/09/2022",
      "idNegozio": 4,
      "prodotti": [
        1,
        2,
        5,
        9,
      ],
      "risparmio": 38
    }, {
      "idTransazione": "nc8iGXTSJE",
      "data": "09/10/2022",
      "idNegozio": 7,
      "prodotti": [
        1,
        3,
      ],
      "risparmio": 17
    }, {
      "idTransazione": "OQuqgjUjIs",
      "data": "08/09/2022",
      "idNegozio": 3,
      "prodotti": [
        1,
        2,
        3,
        5,
        6,
        8,
        9,
      ],
      "risparmio": 34
    }, {
      "idTransazione": "Sn2O2UXdHB",
      "data": "09/10/2022",
      "idNegozio": 7,
      "prodotti": [
        1,
        3,
        13
      ],
      "risparmio": 8
    }, {
      "idTransazione": "Rz3znovsQJ",
      "data": "02/09/2022",
      "idNegozio": 6,
      "prodotti": [
        2,
        3,
      ],
      "risparmio": 23
    }, {
      "idTransazione": "EgYURZzamD",
      "data": "09/11/2022",
      "idNegozio": 4,
      "prodotti": [
        4,
        5,
        8,
        9,
      ],
      "risparmio": 11
    }, {
      "idTransazione": "9X83fJSdbU",
      "data": "04/09/2022",
      "idNegozio": 7,
      "prodotti": [
        10,
      ],
      "risparmio": 17
    }, {
      "idTransazione": "UJzm9JmYit",
      "data": "08/09/2022",
      "idNegozio": 3,
      "prodotti": [
        2,
        5,
        6,
        7,
        8,
        10,
      ],
      "risparmio": 30
    }, {
      "idTransazione": "YmZ9gunPiB",
      "data": "02/09/2022",
      "idNegozio": 1,
      "prodotti": [
        2,
        7,
        8,
      ],
      "risparmio": 38
    }, {
      "idTransazione": "nXmNwopxDR",
      "data": "18/10/2022",
      "idNegozio": 6,
      "prodotti": [
        1,
        2,
        4,
        13
      ],
      "risparmio": 20
    }, {
      "idTransazione": "XWcRquBgYO",
      "data": "09/10/2022",
      "idNegozio": 5,
      "prodotti": [
        3,
        4,
        5,
        6,
        7,
        8,
        11,
        12,
      ],
      "risparmio": 7
    }, {
      "idTransazione": "LDRC9vKmUu",
      "data": "29/09/2022",
      "idNegozio": 4,
      "prodotti": [
        1,
        2,
      ],
      "risparmio": 36
    }, {
      "idTransazione": "pU7ET7KRpu",
      "data": "25/09/2022",
      "idNegozio": 1,
      "prodotti": [
        1,
        4,
        5,
        6,
        7,
        9,
      ],
      "risparmio": 19
    }, {
      "idTransazione": "9wVSZ8cnk4",
      "data": "30/10/2022",
      "idNegozio": 5,
      "prodotti": [
        4,
        5,
        6,
        7,
        8,
        11,
        12
      ],
      "risparmio": 12
    }, {
      "idTransazione": "Eqgej5CBOE",
      "data": "01/11/2022",
      "idNegozio": 7,
      "prodotti": [
        1,
        10,
        13
      ],
      "risparmio": 37
    }, {
      "idTransazione": "DkmEJo3OCq",
      "data": "24/10/2022",
      "idNegozio": 4,
      "prodotti": [
        1,
        3,
        4,
        7,
        8,
      ],
      "risparmio": 27
    }, {
      "idTransazione": "pBrsMCTXay",
      "data": "08/09/2022",
      "idNegozio": 4,
      "prodotti": [
        1,
        2,
        3,
        8,
        9,
      ],
      "risparmio": 31
    }, {
      "idTransazione": "1vxypGCdry",
      "data": "18/10/2022",
      "idNegozio": 1,
      "prodotti": [
        1,
        4,
        5,
        6,
        8,
      ],
      "risparmio": 25
    }, {
      "idTransazione": "QqK5dfNffX",
      "data": "14/09/2022",
      "idNegozio": 3,
      "prodotti": [
        4,
        6,
        7,
        8,
        10,
      ],
      "risparmio": 21
    }
  ],
  messaggi: [
    {
      id: 1,
      utente: {
        idCreator: 1
      },
      giorni: [
        {
          giorno: "05/12/22",
          messaggi: [
            {
              mittente: 'self',
              ora: '17:28',
              messaggio: 'Ciao Benedetta, grazie per la fantastica ricetta dei dolci al cioccolato, sono stati buonissimi!😄',
              letto: true
            },
            {
              mittente: 'user',
              ora: '17:32',
              messaggio: 'Ciao, sono contenta ti siano piaciuti, hanno avuto un successone!😊'
            },
            {
              mittente: 'self',
              ora: '17:37',
              messaggio: 'Immagino! Non vedo l\'ora di provare altre tue ricette!',
              letto: true
            },
            {
              mittente: 'user',
              ora: '17:39',
              messaggio: 'A breve arriva un nuovo video😉'
            },
          ]
        },
        {
          giorno: "08/12/22",
          messaggi: [
            {
              mittente: 'self',
              ora: '17:28',
              messaggio: 'Ciao Benedetta, grazie per la fantastica ricetta dei dolci al cioccolato, sono stati buonissimi!😄',
              letto: true
            },
            {
              mittente: 'user',
              ora: '17:32',
              messaggio: 'Ciao, sono contenta ti siano piaciuti, hanno avuto un successone!😊'
            },
            {
              mittente: 'self',
              ora: '17:37',
              messaggio: 'Immagino! Non vedo l\'ora di provare altre tue ricette!',
              letto: true
            },
            {
              mittente: 'user',
              ora: '17:39',
              messaggio: 'A breve arriva un nuovo video😉'
            },
          ]
        },
      ]
    },
    {
      id: 2,
      utente: {
        idNegozio: 3
      },
      giorni: [
        {
          giorno: "25/11/22",
          messaggi: [
            {
              mittente: 'self',
              ora: '16:24',
              messaggio: 'Buon pomeriggio, vorrei chiedere un\'informazione in merito ad una vostra offerta',
              letto: true
            },
            {
              mittente: 'user',
              ora: '16:26',
              messaggio: 'Salve, siamo a sua disposizione'
            },
            {
              mittente: 'self',
              ora: '16:29',
              messaggio: 'È ancora in offerta il pacco di pasta Barilla a 0.56€?',
              letto: true
            },
            {
              mittente: 'user',
              ora: '16:32',
              messaggio: 'Sì, abbiamo ancora disponibilità del prodotto'
            },
            {
              mittente: 'self',
              ora: '16.33',
              messaggio: 'Grazie mille, passo dallo store',
              letto: true
            }
          ]
        }
      ]
    },
    {
      id: 3,
      utente: {
        idNegozio: 1
      },
      giorni: [
        {
          giorno: "21/11/22",
          messaggi: [
            {
              mittente: 'self',
              ora: '12:14',
              messaggio: 'Buon pomeriggio, vorrei chiedere un\'informazione in merito ad una vostra offerta',
              letto: true
            },
            {
              mittente: 'user',
              ora: '12:16',
              messaggio: 'Salve, siamo a sua disposizione'
            },
            {
              mittente: 'self',
              ora: '12:19',
              messaggio: 'È ancora in offerta il pacco di pasta Barilla a 0.56€?',
              letto: true
            },
            {
              mittente: 'user',
              ora: '12:22',
              messaggio: 'Sì, abbiamo ancora disponibilità del prodotto'
            },
          ]
        }
      ]
    },
    {
      id: 4,
      utente: {
        idNegozio: 5
      },
      giorni: [
        {
          giorno: "16/11/22",
          messaggi: [
            {
              mittente: 'self',
              ora: '18:28',
              messaggio: 'Buon pomeriggio, vorrei chiedere un\'informazione in merito ad una vostra offerta',
              letto: true
            },
            {
              mittente: 'user',
              ora: '18:32',
              messaggio: 'Salve, siamo a sua disposizione'
            },
            {
              mittente: 'self',
              ora: '18:37',
              messaggio: 'È ancora in offerta il pacco di pasta Barilla a 0.56€?',
              letto: true
            },
            {
              mittente: 'user',
              ora: '18:39',
              messaggio: 'Sì, abbiamo ancora disponibilità del prodotto'
            },
            {
              mittente: 'self',
              ora: '18.42',
              messaggio: 'Grazie mille, passo dallo store',
              letto: false
            }
          ]
        }
      ]
    },
  ]
}

export const postCreators = [
  {
    id: 2,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '1 dicembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/2.mp4'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "Petto di pollo cipolle e marsala, un secondo last minute facilissimo e gustoso",
    prodotti: []
  },
  {
    id: 1,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '30 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/1.mp4'
        }
      ]
    },
    likes: 123,
    commenti: 5,
    like: true,
    testo: "Mix per cioccolata calda, una coccola cioccolatosa perfetta come pensiero natalizio",
    prodotti: []
  },
]

export const posts = [
  {
    id: 7,
    utente: {
      idNegozio: 8,
      tipoUtente: 'negozio',
    },
    data: '26 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-negozio.png'
        }
      ],
    },
    likes: 64,
    commenti: 5,
    like: true,
    testo: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ea quisquam, explicabo debitis quidem quis veritatis voluptas voluptatem perferendis. Iusto voluptatum quaerat quas.",
  },
  {
    id: 8,
    utente: {
      idNegozio: 7,
      tipoUtente: 'negozio',
    },
    data: '25 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-negozio.png'
        }
      ],
    },
    likes: 32,
    commenti: 5,
    like: false,
    testo: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ea quisquam, explicabo debitis quidem quis veritatis voluptas voluptatem perferendis. Iusto voluptatum quaerat quas.",
  },
  {
    id: 10,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '30 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/1.mp4',
          preview: './assets/videos/social/1.jpg'
        }
      ]
    },
    likes: 123,
    commenti: 5,
    like: true,
    testo: "Mix per cioccolata calda, una coccola cioccolatosa perfetta come pensiero natalizio",
    prodotti: []
  },
  {
    id: 9,
    utente: {
      idNegozio: 6,
      tipoUtente: 'negozio',
    },
    data: '24 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-negozio.png'
        }
      ],
    },
    likes: 64,
    commenti: 5,
    like: false,
    testo: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ea quisquam, explicabo debitis quidem quis veritatis voluptas voluptatem perferendis. Iusto voluptatum quaerat quas.",
  },
  {
    id: 12,
    utente: {
      idCreator: 2,
      tipoUtente: 'creator',
    },
    data: '5 dicembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/3.mp4',
          preview: './assets/videos/social/3.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "😎 Ricetta infallibile 😎<br/> Facile, veloce, economica e molto saporita. La pasta tonno e cipolle fa sempre centro!<br/><a href='https://www.casapappagallo.it/ricette/spaghetti-aglio-olio-e-peperoncino-con-la-ricotta' target='_blank'>https://www.casapappagallo.it/ricette/spaghetti-aglio-olio-e-peperoncino-con-la-ricotta</a>",
    prodotti: []
  },
  {
    id: 1,
    utente: {
      tipoUtente: 'brand',
      idBrand: 2
    },
    data: '27 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-brand.png'
        }
      ],
    },
    likes: 43,
    commenti: 5,
    like: true,
    testo: "Sfida i tuoi amici a Fifa 23 su diverse piattaforme, grazie all'introduzione del cross-play su piattaforme della stessa generazione.",
    prodotti: [9, 10],
  },
  {
    id: 11,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '1 dicembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/2.mp4',
          preview: './assets/videos/social/2.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "Petto di pollo cipolle e marsala, un secondo last minute facilissimo e gustoso",
    prodotti: []
  },
  {
    id: 2,
    utente: {
      img: './assets/images/social/logo-2.png',
      nome: 'Iliad'
    },
    data: 'Sponsorizzato',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-sponsorizzato.png'
        }
      ],
    },
    likes: 57,
    commenti: 7,
    like: false,
    testo: '',
    prodotti: []
  },
  {
    id: 13,
    utente: {
      idCreator: 2,
      tipoUtente: 'creator',
    },
    data: '28 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/4.mp4',
          preview: './assets/videos/social/3.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "🚀 Ricetta infallibile 🚀<br/>Tra un po' iniziaranno gli aperitivi tra amici, e le festicciole.<br/>Segnatevi allora questa ricetta e provatela appena possibile perché la mia brioche rustica è favolosa!<br/><a href='https://www.casapappagallo.it/ricette/brioche-salata' target='_blank'>https://www.casapappagallo.it/ricette/brioche-salata</a>",
    prodotti: []
  },
  {
    id: 14,
    utente: {
      idCreator: 2,
      tipoUtente: 'creator',
    },
    data: '26 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/5.mp4',
          preview: './assets/videos/social/5.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "🚀 Spaghetti velocissimi 🚀<br/>Amici a cena all'improvviso? Fate con me questi spaghetti velocissimi (e troppo saporiti) con olio, aglio, peperoncino e ricotta!<br/><a href='https://www.casapappagallo.it/ricette/spaghetti-aglio-olio-e-peperoncino-con-la-ricotta' target='_blank'>https://www.casapappagallo.it/ricette/spaghetti-aglio-olio-e-peperoncino-con-la-ricotta</a>",
    prodotti: []
  },
  {
    id: 3,
    utente: {
      idNegozio: 3,
      tipoUtente: 'negozio',
    },
    data: '25 ottobre',
    media: {
      images: [],
    },
    likes: 67,
    commenti: 5,
    like: true,
    testo: "Sostenendo le piccole e grandi imprese del nostro territorio, Conad offre ai suoi Clienti prodotti garantiti e di qualità. È proprio con questa strategia dal valore economico, sociale e ambientale che Conad promuove un consumo responsabile e sostiene il Paese e il suo futuro.",
    prodotti: [],
  },
  {
    id: 15,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '24 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/6.mp4',
          preview: './assets/videos/social/6.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "Brutti ma buoni 😊<br/>Tradizionali biscotti croccanti alle nocciole, semplicissimi, con solo tre ingredienti. Senza latte, senza burro e senza lievito 😘",
    prodotti: []
  },
  {
    id: 4,
    utente: {
      tipoUtente: 'brand',
      idBrand: 5
    },
    data: '19 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-brand.png'
        }
      ],
    },
    likes: 52,
    commenti: 5,
    like: true,
    testo: "Per una pausa sana e nutriente scopri le nostre barrette Carrefour BIO! Le trovi nei nostri punti vendita e online: <a href='https://bit.ly/BarretteCarrefourBIO'>https://bit.ly/BarretteCarrefourBIO</a>",
    prodotti: [11, 12],
  },
  {
    id: 5,
    utente: {
      idNegozio: 3,
      tipoUtente: 'negozio',
    },
    data: '13 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-negozio.png'
        }
      ],
    },
    likes: 75,
    commenti: 5,
    like: false,
    testo: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ea quisquam, explicabo debitis quidem quis veritatis voluptas voluptatem perferendis. Iusto voluptatum quaerat quas.",
    prodotti: [2, 4, 3, 6, 8],
  },
  {
    id: 16,
    utente: {
      idCreator: 1,
      tipoUtente: 'creator',
    },
    data: '23 novembre',
    media: {
      images: [],
      videos: [
        {
          video: './assets/videos/social/7.mp4',
          preview: './assets/videos/social/7.jpg'
        }
      ]
    },
    likes: 92,
    commenti: 5,
    like: true,
    testo: "Mix per biscotti fiocchi d'avena e cioccolato.<br/>Un barattolo invitante ed originale, ideale per un regalino goloso ❤️🎄",
    prodotti: []
  },
  {
    id: 6,
    utente: {
      idNegozio: 1,
      tipoUtente: 'negozio',
    },
    data: '22 ottobre',
    media: {
      images: [
        {
          img: './assets/images/placeholders/post-negozio.png'
        }
      ],
    },
    likes: 64,
    commenti: 5,
    like: false,
    testo: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere ea quisquam, explicabo debitis quidem quis veritatis voluptas voluptatem perferendis. Iusto voluptatum quaerat quas.",
    prodotti: [2, 4, 3, 6, 8],
  },
]

export const creators = [
  {
    id: 1,
    nome: 'Benedetta Rossi',
    avatar: './assets/images/creators/1.jpeg',
    img: './assets/images/creators/b-1.jpeg',
    prodotti: [5, 7, 23, 17, 33],
    segui: true,
    tipologia: 'Blogger',
    website: 'https://www.fattoincasadabenedetta.it',
    instagram: 'https://www.instagram.com/fattoincasadabenedetta/',
    youtube: 'https://www.youtube.com/benedetta1972'
  },
  {
    id: 2,
    nome: 'Casa Pappagallo',
    avatar: './assets/images/creators/2.jpg',
    img: './assets/images/creators/b-2.png',
    prodotti: [6, 2, 31, 23, 9],
    segui: true,
    tipologia: 'Creator digitale',
    website: 'https://www.casapappagallo.it',
    instagram: 'https://www.instagram.com/casapappagallo/',
    youtube: 'https://www.youtube.com/CasaPappagallo'
  },
]

export const negozi = [
  {
    id: 6,
    nome: 'Famila',
    denominazioneCommerciale: 'Famila Brindisi',
    marchio: 'Famila',
    idMarchio: 6,
    indirizzo: 'Via Torretta 34',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-8.png',
    img: './assets/images/negozi/6.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 30, sconto: 13 },
      { idProdotto: 34, sconto: 23 },
      { idProdotto: 10, sconto: 25 },
      { idProdotto: 13, sconto: 23 },
      { idProdotto: 3, sconto: 42 },
      { idProdotto: 31, sconto: 13 },
      { idProdotto: 38, sconto: 10 },
      { idProdotto: 32, sconto: 8 },
      { idProdotto: 37, sconto: 5 },
      { idProdotto: 33, sconto: 7 },
      { idProdotto: 35, sconto: 23 },
      { idProdotto: 36, sconto: 20 },
      { idProdotto: 6, sconto: 26 },
      { idProdotto: 1, sconto: 41 },
      { idProdotto: 2, sconto: 44 },
      { idProdotto: 4, sconto: 42 },
      { idProdotto: 14, sconto: 6 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:30 - 13:30',
    ],
    tel: '0831 555595',
    website: 'https://www.famila.it/punti-vendita/puglia/famila-brindisi'
  },
  {
    id: 8,
    nome: 'Dok',
    denominazioneCommerciale: 'Supermercato dok',
    marchio: 'Dok',
    idMarchio: 7,
    indirizzo: 'Viale Belgio 1',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-9.png',
    img: './assets/images/negozi/8.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 32, sconto: 8 },
      { idProdotto: 7, sconto: 39 },
      { idProdotto: 9, sconto: 22 },
      { idProdotto: 35, sconto: 23 },
      { idProdotto: 14, sconto: 6 },
      { idProdotto: 2, sconto: 45 },
      { idProdotto: 34, sconto: 23 },
      { idProdotto: 38, sconto: 10 },
      { idProdotto: 37, sconto: 5 },
      { idProdotto: 36, sconto: 20 },
      { idProdotto: 30, sconto: 13 },
      { idProdotto: 33, sconto: 7 },
      { idProdotto: 6, sconto: 26 },
      { idProdotto: 31, sconto: 13 },
      { idProdotto: 3, sconto: 39 },
      { idProdotto: 1, sconto: 40 },
      { idProdotto: 4, sconto: 40 },
      { idProdotto: 13, sconto: 23 },
      { idProdotto: 10, sconto: 35 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:30 - 13:00',
    ],
    tel: '0831 574808',
    website: 'https://www.supermercatidok.it/index.php?p=promozioni&idpdv=175&pdv=BRINDISI-VIA-BELGIO,%201'
  },
  {
    id: 7,
    nome: 'Famila',
    denominazioneCommerciale: 'Famila San Vito',
    marchio: 'Famila',
    idMarchio: 6,
    indirizzo: 'Via G. Oberdan',
    citta: 'San Vito dei Normanni',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72019',
    avatar: './assets/images/social/logo-8.png',
    img: './assets/images/negozi/7.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 3, sconto: 42 },
      { idProdotto: 1, sconto: 41 },
      { idProdotto: 2, sconto: 44 },
      { idProdotto: 4, sconto: 42 },
      { idProdotto: 30, sconto: 13 },
      { idProdotto: 32, sconto: 8 },
      { idProdotto: 31, sconto: 13 },
      { idProdotto: 6, sconto: 26 },
      { idProdotto: 37, sconto: 5 },
      { idProdotto: 38, sconto: 10 },
      { idProdotto: 36, sconto: 20 },
      { idProdotto: 35, sconto: 23 },
      { idProdotto: 33, sconto: 7 },
      { idProdotto: 34, sconto: 23 },
      { idProdotto: 10, sconto: 40 },
      { idProdotto: 13, sconto: 22 },
      { idProdotto: 14, sconto: 6 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:00 - 20:30',
      '08:30 - 13:30',
    ],
    tel: '0831 952675',
    website: 'https://www.famila.it/punti-vendita/puglia/famila-san-vito-dei-normanni'
  },
  {
    id: 1,
    nome: 'Coop',
    denominazioneCommerciale: 'Ipercoop Le Colonne',
    marchio: 'Coop',
    idMarchio: 1,
    indirizzo: 'Strada Statale 7 712',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-3.png',
    img: './assets/images/negozi/1.jpg',
    tipologia: 'Ipermercato',
    sconti: [
      { idProdotto: 8, sconto: 25 },
      { idProdotto: 6, sconto: 24 },
      { idProdotto: 5, sconto: 23 },
      { idProdotto: 36, sconto: 26 },
      { idProdotto: 10, sconto: 6 },
      { idProdotto: 9, sconto: 5 },
      { idProdotto: 30, sconto: 16 },
      { idProdotto: 33, sconto: 8 },
      { idProdotto: 37, sconto: 7 },
      { idProdotto: 31, sconto: 16 },
      { idProdotto: 7, sconto: 22 },
      { idProdotto: 14, sconto: 5 },
      { idProdotto: 1, sconto: 40 },
      { idProdotto: 2, sconto: 40 },
      { idProdotto: 3, sconto: 40 },
      { idProdotto: 4, sconto: 40 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '09:00 - 21:00',
    ]
  },
  {
    id: 2,
    nome: 'Esselunga',
    denominazioneCommerciale: 'Esselunga Corso Roma',
    marchio: 'Esselunga',
    idMarchio: 2,
    indirizzo: 'Corso Roma 5',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-1.png',
    img: './assets/images/negozi/3.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 4, sconto: 20 },
      { idProdotto: 2, sconto: 14 },
      { idProdotto: 6, sconto: 14 },
      { idProdotto: 14, sconto: 3 },
      { idProdotto: 1, sconto: 23 },
      { idProdotto: 3, sconto: 19 },
      { idProdotto: 36, sconto: 19 },
      { idProdotto: 33, sconto: 5 },
      { idProdotto: 5, sconto: 14 },
      { idProdotto: 37, sconto: 4 },
      { idProdotto: 7, sconto: 12 },
      { idProdotto: 8, sconto: 14 },
      { idProdotto: 10, sconto: 22 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '08:00 - 20:00',
      '09:00 - 13:00',
      'Chiuso',
    ]
  },
  {
    id: 3,
    nome: 'Conad',
    denominazioneCommerciale: 'Conad Rossi - Brindisi',
    marchio: 'Conad',
    idMarchio: 3,
    indirizzo: 'Via Dalmazia, 46',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-4.png',
    img: './assets/images/negozi/2.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 33, sconto: 9 },
      { idProdotto: 1, sconto: 17 },
      { idProdotto: 2, sconto: 23 },
      { idProdotto: 3, sconto: 26 },
      { idProdotto: 4, sconto: 13 },
      { idProdotto: 30, sconto: 12 },
      { idProdotto: 31, sconto: 12 },
      { idProdotto: 5, sconto: 36 },
      { idProdotto: 6, sconto: 40 },
      { idProdotto: 37, sconto: 3 },
      { idProdotto: 36, sconto: 23 },
      { idProdotto: 7, sconto: 14 },
      { idProdotto: 8, sconto: 40 },
      { idProdotto: 9, sconto: 14 },
      { idProdotto: 10, sconto: 16 },
    ],
    preferito: false,
    segui: false,
    orari: [
      '07:30 - 20:30',
      '07:30 - 20:30',
      '07:30 - 20:30',
      '07:30 - 20:30',
      '07:30 - 20:30',
      '07:30 - 20:30',
      '08:30 - 13:00',
    ],
    tel: '0831 607885',
    website: 'https://www.conad.it/ricerca-negozi/conad-via-dalmazia-angolo-via-moro-46-72100--brindisi--009599'
  },
  {
    id: 4,
    nome: 'Eurospin',
    denominazioneCommerciale: 'Eurospin',
    marchio: 'Eurospin',
    idMarchio: 4,
    indirizzo: 'Piazza del Popolo',
    citta: 'Brindisi',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72100',
    avatar: './assets/images/social/logo-5.png',
    img: './assets/images/negozi/4.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 16, sconto: 7 },
      { idProdotto: 20, sconto: 10 },
      { idProdotto: 17, sconto: 20 },
      { idProdotto: 18, sconto: 16 },
      { idProdotto: 15, sconto: 5 },
      { idProdotto: 19, sconto: 5 },
      { idProdotto: 25, sconto: 13 },
      { idProdotto: 21, sconto: 20 },
      { idProdotto: 26, sconto: 17 },
      { idProdotto: 27, sconto: 23 },
      { idProdotto: 28, sconto: 10 },
      { idProdotto: 29, sconto: 15 },
      { idProdotto: 3, sconto: 36 },
      { idProdotto: 4, sconto: 8 },
      { idProdotto: 23, sconto: 8 },
      { idProdotto: 24, sconto: 21 },
      { idProdotto: 6, sconto: 32 },
      { idProdotto: 22, sconto: 15 },
      { idProdotto: 8, sconto: 23 },
      { idProdotto: 9, sconto: 21 },
    ],
    preferito: true,
    segui: true,
    orari: [
      '09:00 - 13:30 / 15:30 - 19:00',
      '09:00 - 13:30 / 15:30 - 19:00',
      '09:00 - 13:30 / 15:30 - 19:00',
      '09:00 - 13:30 / 15:30 - 19:00',
      '09:00 - 13:30 / 15:30 - 19:00',
      '09:00 - 13:30 / 15:30 - 19:00',
      'Chiuso',
    ],
    tel: '0831 568770',
    website: 'https://www.eurospin.it/punti-vendita/brindisi-603449/'
  },
  {
    id: 5,
    nome: 'Carrefour',
    denominazioneCommerciale: 'Carrefour Express',
    marchio: 'Carrefour',
    idMarchio: 5,
    indirizzo: 'Corso Umberto I 221',
    citta: 'Carovigno',
    provincia: 'Brindisi',
    regione: 'Puglia',
    cap: '72012',
    avatar: './assets/images/social/logo-6.png',
    img: './assets/images/negozi/5.jpg',
    tipologia: 'Supermercato',
    sconti: [
      { idProdotto: 37, sconto: 2 },
      { idProdotto: 1, sconto: 18 },
      { idProdotto: 2, sconto: 26 },
      { idProdotto: 3, sconto: 36 },
      { idProdotto: 4, sconto: 8 },
      { idProdotto: 5, sconto: 37 },
      { idProdotto: 33, sconto: 4 },
      { idProdotto: 6, sconto: 31 },
      { idProdotto: 36, sconto: 15 },
      { idProdotto: 30, sconto: 15 },
      { idProdotto: 31, sconto: 15 },
      { idProdotto: 7, sconto: 17 },
      { idProdotto: 8, sconto: 23 },
      { idProdotto: 11, sconto: 5 },
      { idProdotto: 12, sconto: 5 },
    ],
    preferito: false,
    segui: false,
    orari: [
      '08:00 - 21:00',
      '08:00 - 21:00',
      '08:00 - 21:00',
      '08:00 - 21:00',
      '08:00 - 21:00',
      '08:00 - 21:00',
      '08:00 - 13:00',
    ]
  },
]

export const marchi = [
  {
    id: 6,
    nome: 'Famila',
    avatar: './assets/images/social/logo-7.jpg',
    cover: './assets/images/brand/6.png',
    segui: true
  },
  {
    id: 7,
    nome: 'Dok',
    avatar: './assets/images/social/logo-9.png',
    cover: './assets/images/brand/7.png',
    segui: true
  },
  {
    id: 1,
    nome: 'Coop',
    avatar: './assets/images/social/logo-3.png',
    cover: './assets/images/brand/1.webp',
    segui: true
  },
  {
    id: 3,
    nome: 'Conad',
    avatar: './assets/images/social/logo-4.png',
    cover: './assets/images/brand/3.jpeg',
    segui: true
  },
  {
    id: 4,
    nome: 'Eurospin',
    avatar: './assets/images/social/logo-5.png',
    cover: './assets/images/brand/4.jpeg',
    segui: false
  },
  {
    id: 5,
    nome: 'Carrefour',
    avatar: './assets/images/social/logo-6.png',
    cover: './assets/images/brand/5.jpeg',
    segui: true
  },
  {
    id: 2,
    nome: 'Esselunga',
    avatar: './assets/images/social/logo-1.png',
    cover: './assets/images/brand/2.jpg',
    segui: false
  },
]

export const notifiche = [
  {
    id: 1,
    data: '27 ottobre',
    idPost: null,
    idPromo: 1,
    idProdotto: null,
    tipo: 'promozione',
    letta: false
  },
  {
    id: 2,
    data: '25 ottobre',
    idPost: 3,
    idProdotto: null,
    tipo: 'post',
    letta: false
  },
  {
    id: 3,
    data: '19 ottobre',
    idPost: null,
    idPromo: 2,
    idProdotto: null,
    tipo: 'promozione',
    letta: false
  },
  {
    id: 4,
    data: '17 ottobre',
    idPost: null,
    prodotto: {
      idProdotto: 6,
      idNegozio: 2,
      sconto: 24
    },
    tipo: 'sconto-prodotto',
    letta: true
  },
  {
    id: 5,
    data: '15 ottobre',
    idPost: null,
    prodotto: {
      idProdotto: 11,
      idNegozio: 5,
      sconto: 32
    },
    tipo: 'sconto-prodotto',
    letta: true
  },
]