import { useEffect, useState } from "react"
import { negozi as tuttiNegozi, prodotti as tuttiProdotti } from "../data/data"
import { formatCost } from "../utils/formatCost"

export const useCart = () => {
  // @ts-ignore
  const addToCart = (product, shop) => {
    const storage = localStorage['cart'] ? JSON.parse(localStorage['cart']) : []
    // @ts-ignore
    storage.push([product, shop])
    localStorage.cart = JSON.stringify(storage)
  }
  // @ts-ignore
  const removeFromCart = (product, shop) => {
    let found = false
    const storage = localStorage['cart'] ? JSON.parse(localStorage['cart']) : []
    let i = 0
    // @ts-ignore
    while (!found && i < storage.length) {
      // @ts-ignore
      if (storage[i][0] == product && storage[i][1] == shop) {
        found = true
        storage.splice(i, 1)
      } else i++
    }
    localStorage.cart = JSON.stringify(storage)
  }
  // @ts-ignore
  const deleteProductFromCart = (product, shop) => {
    const storage = localStorage['cart'] ? JSON.parse(localStorage['cart']) : []
    // @ts-ignore
    const newCart = []
    // @ts-ignore
    storage.forEach(el => {
      if ((el[0] != product) || (el[1] != shop)) {
        newCart.push(el)
      }
    })
    // @ts-ignore
    localStorage.cart = JSON.stringify(newCart)
  }

  const getProducts = () => {
    const storage = localStorage['cart'] ? JSON.parse(localStorage['cart']) : []
    // @ts-ignore
    const prodotti = []
    let numeroArticoli = 0
    const negozi = []
    let punti = 0
    const costo = {
      totale: 0,
      exTotale: 0,
      risparmio: 0,
      punti: 0
    }
    if (storage) {
      // @ts-ignore
      storage.forEach(stor => {
        if (!stor[0] || !stor[1]) {
          localStorage.removeItem('cart')
        }
        const product = tuttiProdotti.find(prod => prod.id == stor[0])
        const shop = tuttiNegozi.find(shop => shop.id == stor[1])
        // @ts-ignore
        if (shop.sconti.find(el => el.idProdotto == product.id)) {
          const prodotto = {
            ...product,
            // @ts-ignore
            sconto: shop.sconti.find(el => el.idProdotto == product.id).sconto,
            quantita: 1
          }
          // @ts-ignore
          if (prodotti.find(el => el.negozio == shop)) {
            // @ts-ignore
            const i = prodotti.map(e => e.negozio).indexOf(shop);
            // @ts-ignore
            if (prodotti[i].prodotti.find(item => item.id == prodotto.id)) {
              // @ts-ignore
              const j = prodotti[i].prodotti.map(item => item.id).indexOf(prodotto.id)
              // @ts-ignore
              prodotti[i].prodotti[j].quantita += 1
            } else
              // @ts-ignore
              prodotti[i].prodotti.push(prodotto)
          } else prodotti.push({
            negozio: shop,
            prodotti: [prodotto]
          })
          numeroArticoli++
        }
      })
      // @ts-ignore
      prodotti.forEach(el => {
        // @ts-ignore
        el.prodotti.forEach(prod => {
          costo.totale += (parseFloat(formatCost(prod.costo, prod.sconto)) * prod.quantita)
          costo.exTotale += (prod.costo * prod.quantita)
          costo.risparmio += ((prod.costo * prod.quantita) - (parseFloat(formatCost(prod.costo, prod.sconto)) * prod.quantita))
        })
      })
      // @ts-ignore
      costo.punti = Math.floor(costo.totale)
    }
    return {
      // @ts-ignore
      prodotti,
      numeroArticoli,
      costo
    }
  }

  return { addToCart, removeFromCart, getProducts, deleteProductFromCart }
}