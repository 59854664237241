import { IonLabel, IonSkeletonText } from "@ionic/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { negozi, marchi } from "../../data/data";
import Image from "../Image/Image";
import './ShopsCarousel.css'

const ShopsCarousel: React.FC = () => {
  return (
    <div className='__shops-carousel'>
      {marchi.map(({ avatar, id, nome }) => (
        <Link to={`/tabs/brand/${id}`} className='__shop-carousel-link' key={id}>
          <div className="__shop-carousel-avatar __box-shadow-1">
            <Image src={avatar} />
          </div>
          <p>{nome}</p>
        </Link>
      ))}
      <div className="__gradient"></div>
    </div>
  );
}

export default ShopsCarousel;