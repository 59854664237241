import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonPage, useIonRouter } from "@ionic/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../features/loginSlice";
import './Login.css'

const Login: React.FC<any> = () => {
  const [username, setUsername] = useState<any>('')
  const [psw, setPsw] = useState<any>('')
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const router = useIonRouter()

  const handleLogin = () => {
    axios({
      method: 'POST',
      url: 'https://super-app.it/api/signin',
      data: {
        username,
        psw
      },
      headers: {
        'benedettarossi': 'ehfjlskhjfjks',
      }
    }).then(res => {
      console.log(res.status);
      if (res.status == 201) {
        dispatch(login())
        // router.push('/')
      }
    }).catch(() => {
      setError(true)

    })
  }
  return (
    <IonPage className="__login-page">
      <IonContent>
        <div>
          <img src="./assets/icon/icon.png" alt="" />
          <h1 className="__cursive-title">SuperApp</h1>
          <form className="__login-module" autoComplete="off">
            <div className="__input">
              {/* <IonItem lines="none"> */}
              <IonInput autofocus autocomplete="off" type="text" placeholder="Inserisci il nome utente" onIonChange={e => setUsername(e.detail.value)}></IonInput>
              {/* </IonItem> */}
            </div>
            <div className="__input">
              {/* <IonItem lines="none"> */}
              <IonInput autocomplete="off" type="password" placeholder="Inserisci la password" onIonChange={e => setPsw(e.detail.value)}></IonInput>
              {/* </IonItem> */}
            </div>
            {error &&
              <div className="__error">
                <IonLabel>Username o password errati.</IonLabel>
              </div>
            }
            <IonButton color={'light'} onClick={handleLogin}>Login</IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Login;