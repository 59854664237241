import { IonRouterLink } from "@ionic/react";
import { marchi, negozi, offerte, posts, prodotti, promo } from "../../data/data";
import Image from "../Image/Image";

const NotificationItem: React.FC<any> = ({ notifica, closeNotifications }) => {
  let post, negozio, prodotto, promo
  if (notifica.idPost) {
    post = posts.find(el => el.id == notifica.idPost)
  }
  if (notifica.prodotto) {
    negozio = negozi.find(el => el.id == notifica.prodotto.idNegozio)
    prodotto = prodotti.find(el => el.id == notifica.prodotto.idProdotto)
  }
  if (notifica.idPromo) {
    promo = offerte.find(el => el.id == notifica.idPromo)
  }
  return (
    <div className={"__notification-wrapper " + (!notifica.letta ? '__to-read' : '')}>
      {notifica.tipo == 'post' ?
        <NotificaPost data={notifica.data} post={post} closeNotifications={closeNotifications} /> :
        notifica.tipo == 'promozione' ?
          <NotificaPromozione data={notifica.data} promozione={promo} closeNotifications={closeNotifications} /> :
          notifica.tipo == 'sconto-prodotto' ?
            <NotificaScontoProdotto notifica={notifica} prodotto={prodotto} negozio={negozio} closeNotifications={closeNotifications} /> :
            ''}
    </div>
  )
}

export default NotificationItem;

const NotificaPost: React.FC<any> = ({ data, post, closeNotifications }) => {
  return (
    <>
      <div className="__notification-avatar">
        <IonRouterLink routerLink={'/tabs/negozio/' + post.utente.idNegozio} onClick={closeNotifications}>
          <Image src={negozi.find(el => el.id == post.utente.idNegozio)!.avatar} />
        </IonRouterLink>
      </div>
      <div className="__notification-text">
        <p><IonRouterLink routerLink={'/tabs/negozio/' + post.utente.idNegozio} onClick={closeNotifications}>{negozi.find(el => el.id == post.utente.idNegozio)?.denominazioneCommerciale}</IonRouterLink> ha pubblicato un nuovo <IonRouterLink routerLink={'/tabs/social/' + post.id}  onClick={closeNotifications}>post</IonRouterLink>.</p>
        <p className="__notification-date">{data}</p>
      </div>
    </>
  )
}
const NotificaPromozione: React.FC<any> = ({ data, promozione, closeNotifications }) => {
  const marchio = marchi.find(el => el.id == promozione.idBrand)
  return (
    <>
      <div className="__notification-avatar">
        {/* @ts-ignore */}
        <IonRouterLink routerLink={'/tabs/brand/' + marchio.id} onClick={closeNotifications}>
          {/* @ts-ignore */}
          <Image src={marchio.avatar} />
        </IonRouterLink>
      </div>
      <div className="__notification-text">
        {/* @ts-ignore */}
        <p><IonRouterLink routerLink={'/tabs/brand/' + marchio.id} onClick={closeNotifications}>{promozione.catena}</IonRouterLink> ha pubblicato una nuova <IonRouterLink routerLink={'/tabs/promozione/' + promozione.slug} onClick={closeNotifications}>promozione</IonRouterLink>.</p>
        <p className="__notification-date">{data}</p>
      </div>
    </>
  )
}
const NotificaScontoProdotto: React.FC<any> = ({ notifica, prodotto, negozio, closeNotifications }) => {
  return (
    <>
      <div className="__notification-avatar">
        <IonRouterLink routerLink={'/tabs/prodotto/' + prodotto.id + '/' + negozio.id} onClick={closeNotifications}>
          <Image src={prodotto.img} />
        </IonRouterLink>
      </div>
      <div className="__notification-text">
        <p><IonRouterLink routerLink={'/tabs/prodotto/' + prodotto.id + '/' + negozio.id} onClick={closeNotifications}>{prodotto?.nome}</IonRouterLink> adesso lo puoi trovare con il {notifica.prodotto?.sconto}% di sconto da <IonRouterLink routerLink={'/tabs/negozio/' + negozio.id} onClick={closeNotifications}>{negozio?.denominazioneCommerciale}</IonRouterLink>.</p>
        <p className="__notification-date">{notifica.data}</p>
      </div>
    </>
  )
}