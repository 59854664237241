import { getPlatforms, IonBackButton, IonBadge, IonButton, IonButtons, IonChip, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonRouterLink, IonText, IonTitle, IonToolbar, useIonRouter, useIonToast, useIonViewWillEnter } from "@ionic/react";
import { arrowForward, cart, cartOutline, chevronBack, chevronForward, heart, heartOutline, locationOutline, shareOutline, storefrontOutline } from "ionicons/icons";
import { RouteComponentProps, useLocation } from "react-router";
import Header from "../../components/Header/Header";
import ShopsList from "../../components/ShopsList/ShopsList";
import { categorie, negozi, offerte, utente } from "../../data/data";
import { useCart } from "../../hooks/useCart";
import { negozi as tuttiNegozi } from "../../data/data";
import './Prodotto.css'
import { formatCost } from "../../utils/formatCost";
import { useEffect, useRef, useState } from "react";
import { scrollToTop } from "../../utils/scrollToTop";
import Container from "../../components/common/Container";
import { getConvenientProducts } from "../../utils/getConvenientProducts";
import { getBestOffer } from "../../utils/getBestOffer";
import Image from "../../components/Image/Image";

interface ProductPageProps
  extends RouteComponentProps<{
    id: string
    negozio?: string
  }> {
  modal: any
}

const Prodotto: React.FC<ProductPageProps> = ({ match, modal }) => {
  const idProdotto = parseInt(match.params.id)
  // @ts-ignore
  const idNegozio = parseInt(match.params.negozio)
  // @ts-ignore
  let negozio, sconto
  if (idNegozio) {
    negozio = tuttiNegozi.find(el => el.id == idNegozio)
    sconto = negozio?.sconti.find(el => el.idProdotto == idProdotto)?.sconto
  }

  const prodotto = getBestOffer(idProdotto)

  const { nome, img, costo, descrizione, marca, score } = prodotto
  const { addToCart, getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)
  const [present] = useIonToast();
  const presentToast = () => {
    present({
      message: 'Aggiunto al carrello!',
      duration: 3000,
      position: 'bottom'
    })
  }
  // @ts-ignore
  const negoziOfferta = []
  tuttiNegozi.forEach(shop => {
    shop.sconti.forEach(el => {
      if (el.idProdotto == idProdotto) {
        negoziOfferta.push({
          ...shop,
          sconto: el.sconto
        })
      }
    })
  })
  const compare = (a: any, b: any) => {
    return a.sconto > b.sconto ? -1 : a.sconto < b.sconto ? 1 : 0
  }
  // @ts-ignore
  negoziOfferta.sort(compare)
  if (!idNegozio) {
    // @ts-ignore
    sconto = negoziOfferta[0].sconto
    // @ts-ignore
    negozio = negoziOfferta[0]
  }
  const categorieProdotto = prodotto.categorie.map((el: number) => {
    return categorie.find(cat => cat.id == el)
  })
  const aggiungiAlCarrello = () => {
    // @ts-ignore
    addToCart(prodotto.id, idNegozio || negozio.id)
    setNumeroArticoli(getProducts().numeroArticoli)
    presentToast()
  }
  document.onreadystatechange = () => {
    // @ts-ignore
    // console.log(modal);
    modal.current?.onDidDismiss(() => {
      console.log('chiuso');
    })
  }
  const content = useRef(null)
  useIonViewWillEnter(() => {
    scrollToTop(content, 0)
  })

  const [loveIcon, setLoveIcon] = useState(utente.preferiti.prodotti.includes(idProdotto) ? heart : heartOutline)
  const saveProduct = () => {
    loveIcon == heartOutline ? setLoveIcon(heart) : setLoveIcon(heartOutline)
  }
  let scoreQuality
  switch (score) {
    case 'e':
    case 'd':
      scoreQuality = 'bassa'
      break;
    case 'a':
      scoreQuality = 'molto buona'
      break;
    case 'c':
      scoreQuality = 'nella media'
      break;
    case 'b':
      scoreQuality = 'buona'
      break;
    default:
      break;
  }
  const locationHook = useLocation()
  useEffect(() => {
    setNumeroArticoli(getProducts().numeroArticoli)
  }, [locationHook])
  const promoProdotto = offerte.filter(el => el.prodotti.includes(idProdotto)).map(el => ({ ...el, negozio: negozi.find(shop => shop.id == el.idNegozio)?.denominazioneCommerciale }))
  return (
    <IonPage>
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{nome}</IonTitle>
          <IonButtons slot='end' className="__notifications-badge">
            <IonButton>
              <IonIcon icon={shareOutline} />
            </IonButton>
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={content}>
        <Container>
          <div className="__prodotto">
            <div className="__gallery">
              {/* <IonFab horizontal="start">
              <IonFabButton>
                <IonIcon icon={chevronBack} />
              </IonFabButton>
            </IonFab> */}
              <Image src={img} />
              {/* <IonFab horizontal="end">
              <IonFabButton>
                <IonIcon icon={chevronForward} />
              </IonFabButton>
            </IonFab> */}
            </div>
            <div className="__px-1">
              <IonLabel className="ion-text-wrap">
                <h1>{nome}</h1>
              </IonLabel>
              <IonText>{marca}</IonText>
            </div>
            <div className="__categories __px-1 __pt-05">
              {/* @ts-ignore */}
              {categorieProdotto.map(({ nome, slug, id }) => (
                <IonRouterLink routerLink={'/tabs/prodotti/' + slug} key={id}>
                  <IonChip color={'primary'}>{nome}</IonChip>
                </IonRouterLink>
              ))}
            </div>
            <div className="__action-buttons">
              <div className="__buttons">
                <IonButton mode="ios" color='danger' fill="outline" className="__box-shadow-05 __action-button" onClick={saveProduct}>
                  Preferiti
                  <IonIcon icon={loveIcon} />
                </IonButton>
                <IonButton mode="ios" color='danger' fill="outline" className="__box-shadow-05 __action-button" onClick={aggiungiAlCarrello}>
                  Carrello
                  <IonIcon icon={cartOutline} />
                </IonButton>
              </div>
            </div>
            <div className="__info-card __card __box-shadow-1">
              <div className="__price-button">
                <div className="__price-side">
                  <div className="__price">
                    <span className="__new-price __me-1">€ {formatCost(costo, sconto)}</span>
                    <span className="__discount __me-1">-{sconto}%</span>
                    <span className="__old-price">€ {costo}</span>
                  </div>
                </div>
                <div>
                  <IonLabel>Fino al 20/12</IonLabel>
                </div>
                {/* <IonButtons className="__button">
                <IonButton onClick={aggiungiAlCarrello}>
                  <IonIcon color="danger" icon={cart} />
                </IonButton>
              </IonButtons> */}
              </div>
              {/* @ts-ignore */}
              <IonItem className="__best-price-location" lines="none" routerLink={`/tabs/negozio/${negozio.id}`} detail={false}>
                <IonIcon color="danger" slot="start" className=" __best-price-icon" icon={storefrontOutline} />
                <IonLabel className="ion-text-wrap">
                  {/* @ts-ignore */}
                  <IonText color='danger'><strong>{negozio.denominazioneCommerciale} - {negozio.indirizzo}</strong></IonText>
                </IonLabel>
              </IonItem>
            </div>


            <div className="__product-description __info-card __card __box-shadow-1">
              <div className="__title">
                <h5>Descrizione</h5>
              </div>
              <div className="ion-padding">
                <p>{descrizione}</p>
              </div>
            </div>
            <div className="__product-caratt __info-card __card __box-shadow-1">
              <div className="__title">
                <h5>Caratteristiche</h5>
              </div>
              <div className="__px-1">
                <IonList>
                  <IonItem lines="full">
                    <IonLabel>Lorem:</IonLabel>
                    <IonLabel>Lorem ipsum dolor.</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Ipsum:</IonLabel>
                    <IonLabel>7</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Dolor:</IonLabel>
                    <IonLabel>100g</IonLabel>
                  </IonItem>
                  <IonItem lines="full">
                    <IonLabel>Sit:</IonLabel>
                    <IonLabel>ipsum</IonLabel>
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Amet:</IonLabel>
                    <IonLabel>40/10</IonLabel>
                  </IonItem>
                </IonList>
              </div>
            </div>
            {score &&
              <div className={"__info-card __card __box-shadow-1 __nutriscore " + `__score-${score}`}>
                <img src={`/assets/images/nutriscore/${score}.svg`} alt="" />
                <div className="__score-text">
                  <h5>Nutri-Score <span>{score}</span></h5>
                  <p>Qualità nutrizionale {scoreQuality}</p>
                </div>
              </div>
            }
            {promoProdotto.length > 0 &&
              <div className="__info-card __card __box-shadow-1 __product-promo">
                <div className="__title">
                  <h5>Promozioni</h5>
                </div>
                <div className="__px-1">
                  {promoProdotto.map(({ id, nomeOfferta, negozio, slug }) => (
                    <div key={id} className='__promo-item'>
                      <IonRouterLink routerLink={'/tabs/promozione/' + slug}>
                        <div>
                          <div>
                            <IonLabel className="__promo-title">{nomeOfferta}</IonLabel><br />
                            <IonLabel>{negozio}</IonLabel>
                          </div>
                          <IonIcon icon={arrowForward} />
                        </div>
                      </IonRouterLink>
                    </div>
                  ))}
                </div>
              </div>
            }
            <div className="__info-card __card __box-shadow-1">
              {/* @ts-ignore */}
              <ShopsList idProdotto={idProdotto} negoziOfferta={negoziOfferta} costo={costo} />
            </div>
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
}

export default Prodotto;