import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonListHeader, IonMenuToggle, IonPage, IonRouterLink, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { call, callOutline, cartOutline, chatbubbleEllipsesOutline, globeOutline, heart, heartOutline, locateOutline, location, locationOutline, mailOutline, pinOutline, shareOutline, star, starOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import Header from "../../components/Header/Header";
import { categorie, negozi, posts, prodotti, promo as tuttePromo, utente } from "../../data/data";
import ProductsSection from '../../components/sections/ProductsSection'
import { prodotti as tuttiProdotti } from "../../data/data";
import { offerte as tutteOfferte } from "../../data/data";
import './Negozio.css'
import Section from "../../components/Section/Section";
import ShopProductsList from "../../components/ShopProductsList/ShopProductsList";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import SocialPost from "../../components/SocialPost/SocialPost";
import Promozione from "../../components/Promozione/Promozione";
import ProductsCarousel from "../../components/ProductsCarousel/ProductsCarousel";
import PromoCarousel from "../../components/PromoCarousel/PromoCarousel";
import ProductsList from "../../components/ProductsList/ProductsList";
import { scrollToTop } from "../../utils/scrollToTop";
import { Link } from "react-router-dom";
import { formatCost } from "../../utils/formatCost";
import Container from "../../components/common/Container";
import { getProductsFromShop } from "../../utils/getProductsFromShop";
import Products from "../../components/sections/ProductsSection";
import Image from "../../components/Image/Image";
import CoverImage from "../../components/CoverImage/CoverImage";
import { useDispatch } from "react-redux";
import { setChat } from "../../features/chatSlice";

interface ShopPageProps
  extends RouteComponentProps<{
    id: string;
  }> {
  modal: any
}
const Negozio: React.FC<ShopPageProps> = ({ match, modal }) => {
  const idNegozio = parseInt(match.params.id)
  const negozio = negozi.find(el => el.id == idNegozio)!
  const { img, tipologia, denominazioneCommerciale, avatar, segui, preferito } = negozio
  const [follow, setFollow] = useState(segui)

  const [shopIcon, setShopIcon] = useState(preferito ? heart : heartOutline)
  const saveShop = () => {
    shopIcon == heartOutline ? setShopIcon(heart) : setShopIcon(heartOutline)
  }
  const sezioniNegozio = [
    {
      id: 5,
      nome: 'Promozioni'
    },
    {
      id: 1,
      nome: 'Offerte'
    },
    {
      id: 2,
      nome: 'Social'
    },
    {
      id: 3,
      nome: 'Informazioni'
    },
    {
      id: 4,
      nome: 'Prodotti acquistati'
    }
  ]
  const [categoria, setCategoria] = useState<any>(sezioniNegozio[0])
  // @ts-ignore
  const handleCategoria = (e) => {
    const nomeCategoria = e.detail.value
    let nuovaCategoria: { id: number } = { id: 0 }
    sezioniNegozio.forEach(el => {
      if (el.nome == nomeCategoria) nuovaCategoria = el
    })
    setCategoria(nuovaCategoria)
  }
  const content = useRef(null)
  const segments = useRef(null)
  const tabContent = useRef(null)
  useEffect(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
  }, [categoria])
  useIonViewWillEnter(() => {
    // @ts-ignore
    tabContent.current.style.minHeight = (content.current.getBoundingClientRect().height - segments.current.getBoundingClientRect().height) + 'px'
    scrollToTop(content, 0)
    // setCategoria(sezioniNegozio[0])
  })

  const dispatch = useDispatch()

  const openChat = (type: string, id: number) => {
    dispatch(setChat({ type, id }))
  }
  return (
    <IonPage className="__shop-page">
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{denominazioneCommerciale}</IonTitle>
          <IonButtons slot='end'>
            <IonButton>
              <IonIcon icon={shareOutline} />
            </IonButton>
            <IonButton onClick={saveShop}>
              <IonIcon icon={shopIcon} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className='__shop-page-content' ref={content}>
        <Container>
          <CoverImage img={img} />
          <div className="__shop-heading">
            <div className="__shop-heading-top">
              <div className="__avatar"><Image src={avatar} /></div>
              <IonLabel className="ion-text-wrap __pe-1">
                <h1>{denominazioneCommerciale}</h1>
                <p>{tipologia}</p>
              </IonLabel>
              <div className="__action-buttons">
                <IonMenuToggle menu='chat' autoHide={false} onClick={() => openChat('negozio', idNegozio)}>
                  <IonButton size="small" className="__chat-btn">
                    <IonIcon slot="icon-only" size="small" icon={chatbubbleEllipsesOutline} />
                  </IonButton>
                </IonMenuToggle>
                <IonButton className={"__follow-btn " + (follow ? "__unfollow" : "__follow")} size="small" onClick={() => setFollow(el => !el)}>
                  {follow ? 'Segui già' : 'Segui'}
                </IonButton>
              </div>
            </div>
          </div>
          <IonItemDivider sticky={true} className='__box-shadow-1' ref={segments}>
            <div className="__segments">
              <IonSegment scrollable={true} value={categoria.nome} mode='md' onIonChange={handleCategoria}>
                {sezioniNegozio.map(({ id, nome }) =>
                  <IonSegmentButton className='__segment-button' key={id} value={nome}>
                    <IonLabel className='__uppercase'>{nome}</IonLabel>
                  </IonSegmentButton>
                )}
              </IonSegment>
            </div>
          </IonItemDivider>
          <div className="__pb-1" ref={tabContent}>
            {
              categoria.nome == 'Informazioni' ?
                <InformazioniNegozio negozio={negozio} /> :
                categoria.nome == 'Social' ?
                  <SocialNegozio negozio={negozio} /> :
                  categoria.nome == 'Promozioni' ?
                    <PromozioniNegozio negozio={negozio} /> :
                    categoria.nome == 'Offerte' ?
                      <OfferteNegozio negozio={negozio} /> :
                      categoria.nome == 'Prodotti acquistati' ?
                        <ProdottiAcquistati negozio={negozio} /> :
                        ''
            }
          </div>
          {/* <div className="prova" style={{width: '100%', height, position: 'absolute', background: 'white', zIndex: '100', top, border: '1px solid black'}}></div> */}
        </Container>
      </IonContent>
    </IonPage>
  );
}

export default Negozio;

const InformazioniNegozio: React.FC<any> = ({ negozio }) => {
  const { indirizzo, citta, cap, orari, tel, website, denominazioneCommerciale } = negozio
  // @ts-ignore
  const query = denominazioneCommerciale.replaceAll(' ', '+') + '+' + indirizzo.replaceAll(' ', '+') + '+' + citta
  console.log(query);

  return (
    <div className="__pt-1">
      <div className="__shop-info">
        <div className="__shop-info-heading">
          <h4>Dove si trova</h4>
        </div>
        <div className="__shop-info-element">
          <IonIcon className="__maps-link" color="primary" icon={locationOutline} />
          <a href={'https://www.google.com/maps/search/?api=1&query=' + query} className="__maps-link">{indirizzo}, {citta} - {cap}</a>
        </div>
        <div className="__shop-info-map">
          <img src="./assets/images/other/1.jpeg" alt="" />
        </div>
      </div>
      <div className="__shop-info">
        <div className="__shop-info-heading">
          <h4>Contatti</h4>
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={globeOutline} />
          {website ?
            <a href={website}>Sito Web</a> :
            <span>Nessun sito web</span>
          }
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={callOutline} />
          <a href={tel ? ('tel:' + tel) : ''}>{tel || 'Nessun numero di telefono'}</a>
        </div>
        <div className="__shop-info-element">
          <IonIcon icon={mailOutline} />
          <IonRouterLink>info@supermercato.it</IonRouterLink>
        </div>
      </div>
      <div className="__shop-info">
        <div className="__shop-info-heading">
          <h4>Orari di apertura</h4>
        </div>
        <div className="__shop-info-element __hours">
          <span>Lunedì</span>
          <span>{orari[0]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Martedì</span>
          <span>{orari[1]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Mercoledì</span>
          <span>{orari[2]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Giovedì</span>
          <span>{orari[3]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Venerdì</span>
          <span>{orari[4]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Sabato</span>
          <span>{orari[5]}</span>
        </div>
        <div className="__shop-info-element __hours">
          <span>Domenica</span>
          <span>{orari[6]}</span>
        </div>
      </div>
    </div>
  );
}

const PromozioniNegozio: React.FC<any> = ({ negozio }) => {
  // @ts-ignore
  const offerteNegozio = []
  negozio.sconti.forEach((el: any) => {
    offerteNegozio.push({
      ...prodotti.find(prod => prod.id == el.idProdotto),
      sconto: el.sconto
    })
  })
  const [offerte, setOfferte] = useState(tutteOfferte.filter(el => el.idNegozio == negozio.id))
  const [offerta, setOfferta] = useState(offerte[0] || null)
  const [prodottiOfferta, setProdottiOfferta] = useState(offerta ? getProductsFromShop(offerta.prodotti, negozio.id) : [])
  useEffect(() => {
    setOfferte(() => {
      const nuoveOfferte = tutteOfferte.filter(el => el.idNegozio == negozio.id)
      const nuovaOfferta = nuoveOfferte.length > 0 ? nuoveOfferte[0] : null
      setProdottiOfferta(nuovaOfferta ? getProductsFromShop(nuovaOfferta.prodotti, negozio.id) : [])
      // @ts-ignore
      setOfferta(nuovaOfferta)
      return nuoveOfferte
    })
  }, [negozio])
  const handleOfferta = (id: any) => {
    // @ts-ignore
    const nuovaOfferta = offerte.find(el => el.id == id)
    // @ts-ignore
    setOfferta(nuovaOfferta)
    // @ts-ignore
    setProdottiOfferta(getProductsFromShop(nuovaOfferta.prodotti, negozio.id))
  }
  if (offerte.length > 0) {
    return (
      <div className="__pt-1">
        <PromoCarousel promo={offerte} selected={offerta} handleOfferta={handleOfferta} />
        {/* @ts-ignore */}
        <ProductsSection title={offerta.nomeOfferta} list={prodottiOfferta.slice(0, 4)} page='negozio' idNegozio={negozio.id} />
        <div className="__all-shop-offers">
          {prodottiOfferta.length > 4 &&
            <Section title="Tutti i prodotti">
              {/* @ts-ignore */}
              <ProductsList idNegozio={negozio.id} prodotti={prodottiOfferta.slice(4)} />
            </Section>
          }
        </div>
      </div>
    )
  }
  return (
    <div className="__no-data">
      <p>Questo negozio non ha nessuna offerta in corso.</p>
    </div>
  )
}
// @ts-ignore
const OfferteNegozio: React.FC<any> = ({ negozio }) => {
  const prodotti = getProductsFromShop(tuttiProdotti, negozio.id)

  const prodottiCategoria = (idCategoria: number) => {
    // @ts-ignore
    return prodotti.filter(el => el.categorie.includes(idCategoria))
  }
  return (
    prodotti.length > 0 ?
      // @ts-ignore
      categorie.map(({ id, titolo }) => (
        // checked && 
        <div key={id}>
          <h3 className='__m-0 ion-padding'>{titolo}</h3>
          {/* @ts-ignore */}
          {prodottiCategoria(id).length > 0 ?
            // @ts-ignore
            <Products list={prodottiCategoria(id)} /> :
            <div className="__no-data __pt-0">Non ci sono prodotti in offerta per questa categoria.</div>
          }
        </div>
      ))
      :
      <div className="__no-data">Non ci sono prodotti in offerta per questa categoria.</div>
  )
}

const SocialNegozio: React.FC<any> = ({ negozio }) => {
  const socialPosts = posts.filter(el => el.utente.idNegozio == negozio.id)
  return (
    <div className="__pt-1">
      {socialPosts.length > 0 ? socialPosts.map(post =>
        <SocialPost post={post} key={post.id} />
      ) :
        <div className="__no-data">
          <p>Questo utente non ha ancora pubblicato nessun post.</p>
        </div>
      }
    </div>
  )
}

const ProdottiAcquistati: React.FC<any> = ({ negozio }) => {
  const transazioniNegozio = utente.storico.filter(el => el.idNegozio == negozio.id)
  // @ts-ignore
  const prodottiAcquistati = prodotti.filter(prod => {
    let found = false
    transazioniNegozio.forEach(el => {
      if (el.prodotti.includes(prod.id)) {
        found = true
      }
    })
    return found
  })
  if (prodottiAcquistati.length > 0) {
    return (
      <div className="__pt-1">
        <ProductsList prodotti={prodottiAcquistati} idNegozio={negozio.id} />
      </div>
    )
  }
  return (
    <div className="__pt-1">
      <div className="__no-data">
        <p>Non hai ancora acquistato prodotti in questo punto vendita.</p>
      </div >
    </div>
  )
}