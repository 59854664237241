import { IonCard, IonRouterLink, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { formatCost } from "../../utils/formatCost";
import { negozi } from "../../data/data";
import { motion } from 'framer-motion'
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Image from "../Image/Image";

const ProductCard: React.FC<any> = ({ product, idNegozio }) => {
  let content = { product, idNegozio }
  return (
    <div className="__product-card">
      <Content content={content} />
    </div>
  )
};

const Content: React.FC<any> = ({ content }) => {
  let { product, idNegozio } = content
  const { nome, costo, img, id } = product
  let sconto = 0
  if (idNegozio) {
    // @ts-ignore
    sconto = negozi.find(el => el.id == idNegozio)?.sconti.find(el => el.idProdotto == id)?.sconto
  } else if (product.idNegozio) {
    idNegozio = product.idNegozio
    // @ts-ignore
    sconto = product.sconto
  }
  return (
    <IonRouterLink routerLink={'/tabs/prodotto/' + product.id + (idNegozio ? ('/' + idNegozio) : '')}>
      <div className="__product-card-img">
        <Image src={img} />
      </div>
      <div className="__card-content">
        <h4 className="__card-title">{nome}</h4>
        <div className="__price">
          <span className="__new-price">€ {formatCost(costo, sconto)}</span>
          <div>
            <span className="__old-price">€ {costo.toFixed(2)}</span>
            <span className="__discount">-{product.sconto}%</span>
          </div>
        </div>
      </div>
    </IonRouterLink>
  )
}

export default ProductCard;
