import { negozi, prodotti as tuttiProdotti } from "../data/data"

export const getConvenientProducts = (products: any[]) => {
  const prodotti: any[] = []

  products.forEach((el: any) => {
    let sconto = 0
    let idNegozio = 0
    negozi.forEach(shop => {
      shop.sconti.forEach(item => {
        if ((typeof (el) == 'number' ? item.idProdotto == el : item.idProdotto == el.id) && item.sconto > sconto) {
          sconto = item.sconto
          idNegozio = shop.id
        }
      })
    })
    prodotti.push({
      ...tuttiProdotti.find(prod => typeof (el) == 'number' ? prod.id == el : prod.id == el.id),
      sconto,
      idNegozio
    })
  })

  return prodotti
}