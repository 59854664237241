import { useEffect, useState } from "react";
import { livelli, premi, prodotti, utente } from "../data/data";

const usePoints = () => {
  const [punti, setPunti] = useState(0)
  const [puntiNegozi, setPuntiNegozi] = useState<any>([])
  const [livello, setLivello] = useState<any>({})
  const transazioni = utente.storico
  useEffect(() => {
    let points = 0
    let totale = 0
    // @ts-ignore
    let arr = []
    transazioni.forEach(acquisto => {
      let costo = 0
      acquisto.prodotti.forEach(prod => {
        // @ts-ignore
        costo += prodotti.find(el => el.id == prod)?.costo
      })
      totale += Math.floor(costo - (costo / 100 * acquisto.risparmio))
      // @ts-ignore
      if (arr.find(el => el.id == acquisto.idNegozio)) {
        // @ts-ignore
        arr.find(el => el.id == acquisto.idNegozio).punti += Math.floor(costo - (costo / 100 * acquisto.risparmio))
      } else {
        const puntiNegozio = {
          id: acquisto.idNegozio,
          punti: Math.floor(costo - (costo / 100 * acquisto.risparmio))
        }
        arr.push(puntiNegozio)
      }
    })
    // })
    points += totale
    setPunti(points)
    setLivello(livelli.find(el => (el.puntiMin <= points && points < el.puntiMax)) || livelli[livelli.length - 1])
    // @ts-ignore
    setPuntiNegozi(arr)
  }, [])

  const [premiDisponibili, setPremiDisponibili] = useState([])
  const [premiNonDisponibili, setPremiNonDisponibili] = useState([])

  useEffect(() => {
    // @ts-ignore
    setPremiDisponibili(premi.filter(el => (el.punti <= punti)))
    // @ts-ignore
    setPremiNonDisponibili(premi.filter(el => (el.punti > punti)))
  }, [punti])
  
  const premiNegozio = (pts: number) => {
    return premi.filter(el => (el.punti <= pts))
  }

  return ({
    punti,
    livello,
    premiDisponibili,
    premiNonDisponibili,
    puntiNegozi,
    premiNegozio
  });
}

export default usePoints;