import { Link } from "react-router-dom";
import { negozi } from "../../data/data";
import { formatCost } from "../../utils/formatCost";
import Image from "../Image/Image";
import './CardCarousel.css'

const CardCarousel: React.FC<any> = ({ prodotto }) => {
  const { id, img, nome, costo, sconto, idNegozio } = prodotto
  const negozio = negozi.find(el => el.id == idNegozio)
  return (
    <Link className="__card-carousel __card __box-shadow-2" to={'/tabs/prodotto/' + id + '/' + idNegozio}>
      <div className="__shop-avatar">
        <Image src={negozio!.avatar} />
      </div>
      <div className="__img">
        <Image src={img} />
      </div>
      <div className="__card-info">
        <h4>{nome}</h4>
        <div className="__card-info-bottom">
          <div className="__price">
            € {formatCost(costo, sconto)}
          </div>
          <div className="__right">
            <div className="__old-price">
              € {costo}
            </div>
            <div className="__discount">
              - {sconto}%
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CardCarousel;