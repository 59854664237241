import { IonGrid, IonRow, IonCol, isPlatform } from '@ionic/react';
import { desktop } from 'ionicons/icons';
import { useEffect, useState } from 'react';

function Container({ children }: any) {
  return (
    <div style={{ margin: '0 auto', maxWidth: '680px' }}>
      {children}
    </div>
  );
}
export default Container;