import { IonBackButton, IonBadge, IonButton, IonButtons, IonCheckbox, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonPage, IonTitle, IonToolbar, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { cartOutline, filterOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import Container from '../../components/common/Container';
import ExploreContainer from '../../components/ExploreContainer';
import Header from '../../components/Header/Header';
import { products } from '../../components/ProductCard/data';
import Products from '../../components/sections/ProductsSection';
import { categorie, marchi } from '../../data/data';
import { prodotti as tuttiProdotti, negozi as tuttiNegozi } from '../../data/data'
import { useCart } from '../../hooks/useCart';
import { scrollToTop } from '../../utils/scrollToTop';
import './Prodotti.css'
import { motion } from 'framer-motion'
import { getConvenientProducts } from '../../utils/getConvenientProducts';
import { getProductsFromBrand } from '../../utils/getProductsFromBrand';
import Image from '../../components/Image/Image';

interface ProductsPageProps
  extends RouteComponentProps<{
    slug: string;
  }> {
  modal: any
}

const Prodotti: React.FC<ProductsPageProps> = ({ match, modal }) => {
  const slug = match.params.slug
  
  // @ts-ignore
  // let prodotti = [], negozi
  const [categoria, setCategoria] = useState<any>(categorie.find(el => el.slug == slug) || null)
  const [brand, setBrand] = useState<any>(marchi.find(el => el.nome == slug) || null)
  // if (!categoria) {
  //   // @ts-ignore
  //   negozi = tuttiNegozi.filter(el => el.idMarchio == brand.id)
  //   tuttiProdotti.forEach(el => {
  //     sconti.forEach(sconto => {
  //       // @ts-ignore
  //       if (negozi.find(el => el.id == sconto.idNegozio) && el.id == sconto.idProdotto && !prodotti.find(prod => prod.id == el.id)) {
  //         prodotti.push({
  //           ...el,
  //           sconto: sconto.sconto,
  //           idNegozio: sconto.idNegozio
  //         })
  //       }
  //     })
  //   })
  // } else prodotti = getConvenientProducts(tuttiProdotti.filter(el => el.categorie.includes(categoria.id)))
  // console.log(prodotti);
  
  

  const content = useRef(null)
  useIonViewWillEnter(() => {
    scrollToTop(content, 0)
  })
  const { getProducts } = useCart()
  const [numeroArticoli, setNumeroArticoli] = useState(getProducts().numeroArticoli)

  const locationHook = useLocation()
  const history = useHistory()
  const filterModal = useRef(null)
  const closeModal = () => {
    // @ts-ignore
    filterModal.current.dismiss()
  }
  const closeModalButton = () => {
    closeModal()
    history.go(-1)
  }
  useEffect(() => {
    if (!locationHook.search.includes('modalOpened=true')) {
      // @ts-ignore
      // console.log(filterModal.current.getElement());

      closeModal()
    }
  }, [locationHook]);
  useEffect(() => {
    const nuovaCategoria = categorie.find(el => el.slug == slug) || null
    const nuovoBrand = marchi.find(el => el.nome == slug) || null
    setBrand(nuovoBrand)
    setCategoria(nuovaCategoria)
    setCategorieToShow(() => {
      const arr = categorie.map(el => ({ ...el, checked: true }))
      return arr
    })
    setSottoCategorieToShow(() => {
      const arr = nuovaCategoria?.sottoCategorie.map((el: any) => ({ ...el, checked: true }))
      return arr
  })
    // setSottoCategorieToShow(categoria.sottoCategorie.map((el: any) => ({ ...el, checked: true })))
  }, [slug]);
  const [categorieToShow, setCategorieToShow] = useState(categorie.map(el => ({ ...el, checked: true })))
  const [sottoCategorieToShow, setSottoCategorieToShow] = useState(categoria?.sottoCategorie.map((el: any) => ({ ...el, checked: true })))
  const handleCheck = (id: number) => {
    // @ts-ignore
    setCategorieToShow(arr => {
      return arr?.map(el => ({ ...el, checked: el.id == id ? !el.checked : el.checked }))
    })
  }
  const handleSottoCheck = (id: number) => {
    // @ts-ignore
    setSottoCategorieToShow(arr => {
      return arr?.map((el: any) => ({ ...el, checked: el.id == id ? !el.checked : el.checked }))
    })
  }
  return (
    <IonPage className='__prodotti-page'>
      <IonHeader mode='ios'>
        <IonToolbar className='__toolbar' mode='ios'>
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{categoria?.titolo || 'Prodotti ' + slug}</IonTitle>
          <IonButtons slot='end' className="__notifications-badge">
            <IonButton onClick={() => modal.current?.present()}>
              <IonIcon icon={cartOutline} />
              {numeroArticoli > 0 &&
                <IonBadge>{numeroArticoli}</IonBadge>
              }
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={content}>
        <Container>
          <div style={{ marginBottom: '5rem' }}>
            {categoria?.nome ?
              <>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: .5 }} className="__category-img">
                  <Image src={categoria.img} />
                </motion.div>
                <ProdottiCategoria categoria={categoria} sottoCategorie={sottoCategorieToShow} />
              </> :
              brand?.nome ?
                <>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: .5 }} className="__category-img">
                    <Image src={brand.cover} />
                  </motion.div>
                  <ProdottiBrand brand={brand} categorie={categorieToShow} />
                </> : ''
            }
          </div>
        </Container>
        <IonFab slot='fixed' vertical='bottom' horizontal='end'>
          <IonFabButton mode='ios' id='open-filter-modal'>
            <IonIcon icon={filterOutline} />
          </IonFabButton>
        </IonFab>
        <IonModal onIonModalWillDismiss={ev => ev.detail.role == 'backdrop' && closeModalButton()} onIonModalWillPresent={() => history.push(history.location.pathname + '?modalOpened=true')} id='filter-modal' ref={filterModal} trigger='open-filter-modal'>
          <IonContent>
            <IonToolbar>
              <IonTitle>{brand?.nome ? brand.nome : categoria?.titolo}</IonTitle>
              {/* @ts-ignore */}
              <IonButtons slot='end' onClick={closeModalButton}>
                <IonButton color={'dark'}>
                  Chiudi
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <IonItem lines='none'>
              <IonLabel style={{ fontSize: '1.2rem' }}>Categorie</IonLabel>
            </IonItem>
            {/* @ts-ignore */}
            {categoria?.nome && sottoCategorieToShow?.map(({ id, nome, checked }) => (
              <IonItem lines='none' key={id}>
                <IonCheckbox onIonChange={() => handleSottoCheck(id)} checked={checked} slot="start"></IonCheckbox>
                <IonLabel style={{ textTransform: 'capitalize' }}>{nome}</IonLabel>
              </IonItem>
            ))}
            {brand?.nome && categorieToShow?.map(({ id, nome, checked }) => (
              <IonItem lines='none' key={id}>
                <IonCheckbox onIonChange={() => handleCheck(id)} checked={checked} slot="start"></IonCheckbox>
                <IonLabel style={{ textTransform: 'capitalize' }}>{nome}</IonLabel>
              </IonItem>
            ))}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const ProdottiCategoria: React.FC<any> = ({ categoria, sottoCategorie }) => {
  const prodotti = getConvenientProducts(tuttiProdotti.filter(el => el.categorie.includes(categoria.id)))
  const prodottiSottoCategoria = (idSottoCategoria: number) => {
    // @ts-ignore
    return prodotti.filter(el => el.sottoCategorie.includes(idSottoCategoria))
  }
  return (
    prodotti.length > 0 ?
      // @ts-ignore
      sottoCategorie.map(({ id, nome, checked }) => (
        checked &&
        <div key={id}>
          <h3 className='__m-0 ion-padding'>{nome}</h3>
          {/* @ts-ignore */}
          {prodottiSottoCategoria(id).length > 0 ?
            // @ts-ignore
            <Products list={prodottiSottoCategoria(id)} /> :
            <div className="__no-data __pt-0">Non ci sono prodotti in offerta per questa categoria.</div>
          }
        </div>
      )) :
      <div className="__no-data">Non ci sono prodotti in offerta per questa categoria.</div>
  )
}
// @ts-ignore
const ProdottiBrand: React.FC<any> = ({ brand, categorie }) => {
  const negozi = tuttiNegozi.filter(el => el.idMarchio == brand.id)
  const prodotti = getProductsFromBrand(brand.id)
  
  const prodottiCategoria = (idCategoria: number) => {
    // @ts-ignore
    return prodotti.filter(el => el.categorie.includes(idCategoria))
  }
  return (
    prodotti.length > 0 ?
      // @ts-ignore
      categorie.map(({ id, titolo, checked }) => (
        checked && 
        <div key={id}>
          <h3 className='__m-0 ion-padding'>{titolo}</h3>
          {/* @ts-ignore */}
          {prodottiCategoria(id).length > 0 ?
            // @ts-ignore
            <Products list={prodottiCategoria(id)} /> :
            <div className="__no-data __pt-0">Non ci sono prodotti in offerta per questa categoria.</div>
          }
        </div>
      ))
      :
      <div className="__no-data">Non ci sono prodotti in offerta per questa categoria.</div>
  )
}

export default Prodotti;
