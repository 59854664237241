import { IonRouterLink } from "@ionic/react";
import { Link } from "react-router-dom";
import { negozi, utente } from "../../data/data";
import Image from "../Image/Image";
import './FavoriteShopsCarousel.css'

const FavoriteShopsSection: React.FC = () => {
  const negoziPreferiti = utente.preferiti.negozi.map(shop => negozi.find(el => el.id == shop))
  return (
    <div className='__favorite-shops-carousel' >
      {/* @ts-ignore */}
      {negoziPreferiti.map(({ img, id, denominazioneCommerciale, indirizzo, marchio }) => (
        <IonRouterLink routerLink={`/tabs/negozio/${id}`} className='__shop-carousel-link' key={id}>
          <div className="__favorite-shop-carousel-card">
            <div className="__shop-img">
              <Image src={img} />
            </div>
            <div className="__favorite-shop-text">
              <p className="__favorite-shop-name">{denominazioneCommerciale}</p>
              <p className="__favorite-shop-brand">{marchio}</p>
              <p className="__favorite-shop-address">{indirizzo}</p>
            </div>
          </div>
        </IonRouterLink>
      ))}
    </div>
  );
}

export default FavoriteShopsSection;