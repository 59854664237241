import { IonBackButton, IonBadge, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonMenuToggle, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import React, { useRef } from 'react';
import { notifiche, posts } from '../../data/data';
import { notificationsOutline } from 'ionicons/icons';
import SocialPost from '../../components/SocialPost/SocialPost';
import { scrollToTop } from '../../utils/scrollToTop';
import { RouteComponentProps } from 'react-router';
import Container from '../../components/common/Container';

interface SocialPostPageProps
  extends RouteComponentProps<{
    id: string
  }> { }

const SocialPostPage: React.FC<SocialPostPageProps> = ({ match }) => {
  const idPost = parseInt(match.params.id)
  const post = posts.find(el => el.id == idPost)
  const content = useRef(null)
  useIonViewWillEnter(() => {
    scrollToTop(content, 0)
  })
  const notificheDaLeggere = notifiche.filter(el => !el.letta)
  return (
    <IonPage ref={content}>
      <IonHeader mode='ios' className='__header'>
        <IonToolbar class="__toolbar" mode="ios">
          <IonButtons slot='start'>
            <IonBackButton text='' className='__back-button' />
          </IonButtons>
          <IonTitle>{post?.utente.nome}</IonTitle>
          <IonButtons slot='end' className='__notifications-badge'>
            <IonButton routerLink='/tabs/notifiche'>
              <IonIcon icon={notificationsOutline} />
              <IonBadge>{notificheDaLeggere.length}</IonBadge>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen scrollEvents={true} ref={content}>
        <Container>
          <div className='__py-1'>
            <SocialPost post={post} />
          </div>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default SocialPostPage;
